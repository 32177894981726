:root {
    --BulletsBlock-fontSize : 16px;
}
.BulletsBlock-container {
    width: 100%;
    padding: 0;
    margin: 0;
    .BulletsBlock-bullet {
        font-size: var(--BulletsBlock-fontSize);
        list-style-position: inside;
        border-bottom: 1px dashed #00000037;
        padding-bottom: 10px;
        margin-top: 10px;
        padding: 10px 0 !important;
        &:last-child{
            border-bottom: none;
        }
    }
}