body {
    &[lang='he'] {
    //   direction: rtl;
      .langButtons {
        direction: ltr !important;
      }
  }
}

.language-container {
    width: 100%;
    height: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: #21b7ff;
    padding:0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    .langIllustration {
        width: 290px;
        height: 227px;
        margin-bottom: 20px;
        img {
            width: 290px;
            height: 227px;
            // background-color: rgba(0, 0, 0, 0.029);  
            // border-radius: 100%;
        }
    }
    .langButtons {
        max-width: 450px;
        width: 100%;
        min-width: 200px;
        color: white;
        font-size: 23px;
        margin-bottom: 40px;
        padding: 0 20px;
        .langButton {
            display: flex;
            justify-content: space-between;
            padding: 19px 4px;
            margin-bottom: 2px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.493);
            &:hover {
                cursor:pointer;
            }
            .langButton--V {
                height: 30px;
                width: 30px;
                img {
                    height: 30px;
                    width: 30px;
                }
            }
            .langButton--V__visible {
                background-image: url(/assets/icons/icon-v-circle_40.png);
                background-repeat: no-repeat;
                background-size: contain;
            }
            .langButton--V__hidden {
                background-color: rgba(255, 255, 255, 0.324);
                border-radius: 100%;
            }
            
        }
    }
    .lang-footer {
        margin-bottom: 60px;
        .lang-footer__button {
            min-width: 260px;
        }
    }
}
