.Xbutton {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-self: center;
    padding: 0; 
    margin: 0;
    z-index: 9999999999999;
}

.fixed {
    position: fixed;
    margin: 5px;
}