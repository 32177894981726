.profileImgName-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    .profileImgName-img {
        width: 160px;
        height: 160px;
    }
}