body {
  &[lang="he"] {
    .animals-carousel {
      direction: ltr;
    }
  }
}
.animal-placeholder {
  visibility: hidden;
}
.animals-carousel__left-button, .animals-carousel__right-button {
  width: 20px;
  height: 20px;
  // background: red;
  align-self: center;
  cursor: pointer;
}
.animals-carousel__container {
  display: flex;
  flex-direction: row;
}
// .animals-carousel__left-button {
//   margin-right: 20px
// }
// .animals-carousel__right-button {
//   margin-left: 20px
// }
.animals-carousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width:100vw;
  div {
    &:first-child {
      margin-right: 50px;
    }
    &:last-child {
      margin-left: 50px;
    }
    div {
      border-style: none !important;
      border: 0;
      width: 30vw;
      display: flex;
      justify-content: center;
      
      div {
        div + div {
          display: none;
        }
      }
    }
  }
}
.animals-carousel__item {
  display: flex;
  flex: 0 0 auto; 
}

.swiper-button-disabled {
  opacity: 0!important;
}