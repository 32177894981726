
  .TableUserInfo-container {
    width: 100%;
    overflow-x: auto;
    // margin-bottom: 20px;
    padding-left:12px;
    
    table {
      min-width: 97%;  // Ensures the table takes up at least the container width
      // min-width: auto;  // Ensures the table takes up at least the container width
      border-collapse: collapse;
      // margin-bottom: 16px; 
      margin-right: 40px;
      background-color: blue;
      th, td {
        background-color: #fff;
        padding: 8px;
        text-align: left;
        white-space: normal; /* Allow text to wrap */
        // word-break: break-word; /* Break words if necessary */
        border: 1px solid #648498;
        max-width: 360px;
      }
      .tableBox_S {
        max-width: 120px;
      }
      .tableBox_M {
        max-width: 230px;
      }
      .tableBox_L {
        max-width: 400px;
      }
      .tableBox_bool_true {
        background-color: #d2ffd2;
        text-align: center;
        color: green;
      }
      .tableBox_bool_false {
        background-color: #ffcdcd;
        text-align: center;
        color: #ac0000;
      }
  
      th {
        background-color: #cae0ef;
        font-size: 12px;
      }
      td {
        font-size: 15px;
      }
      
      tr:nth-child(even) {
        background-color: #ecf0f4;
      }
    }
  }
  
  .TableUserInfo-button {
    margin: 0px 12px;
  }