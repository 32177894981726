.summaryCopyNotification-container {
  transition: opacity 200ms ease-in-out;
  position: fixed;
  top: 50px;
  left: 50px;
  .summaryCopyNotification {
    transition: opacity 200ms ease-in-out;

    width: 250px;
    background-color: rgb(0, 169, 6);
    color: white;
    font-size: 20px;
    padding: 14px;
    text-align: center;
    border-radius: 13px;
    opacity: 1;
  }
  .summaryCopyNotificationHidden {
    transition: opacity 200ms ease-in-out;

    opacity: 0;
  }
}

.summary-padding {
  padding: 0 20px;
  @media screen and (max-width: 1024px) {
    padding: 0 12px;
  }
}

