.JourneyListItem__container--list-item {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    align-items: flex-start;
    &:before {
        position: absolute;
        content:"";
        width: 100%;
        height: 100%;
        background-color: var(--mySideColor);
        opacity: 0.16;
        border-radius: 12px;
        transition: opacity 100ms ease-in-out;
    }
    .JourneyListItem__container {
        
        width: 100%;
        height: 100%;
        position: relative;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        transition: background-color 100ms ease-in-out;  
        &:hover {
            &:before {
                opacity: 0.2;
            }
            .JourneyListItem__container--right .JourneyListItem__button {
                filter: brightness(108%);
            }
            
        }
        
        .JourneyListItem__container--left {
            width: 100%;
            padding: 12px 20px;
            flex: 2;
            .JourneyListItem__container--left__date {
                font-size: 14px;
                color: var(--color-TEXT);
                opacity: 0.7;
                margin-top: 5px;
                transition: color 100ms ease-in-out;
                font-family: var(--mainFont);
            }
            .JourneyListItem__container--left__journeyType {
                font-size: 16px;
                color: var(--color-TEXT);
                // color: var(--mySideColor);
                transition: color 100ms ease-in-out;
            }
            .JourneyListItem__container--left__journeyName {
                font-size: 31px;
                color: var(--color-TEXT);
                padding-top: 8px;
                padding-bottom: 5px;
                transition: color 100ms ease-in-out;
                font-family: var(--mainFont);
                line-height: 28px;
                letter-spacing: -0.5px;
                word-spacing: -2px;
            }
            
        }

        .JourneyListItem__container--right {
            padding: 20px;
            padding-top: 0;  
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            flex: 1.4;
            max-width: 56px;
            min-width: 140px;
            .JourneyListItem__button {
                width: 100px;
                height: 40px;
                text-align: center;
                color: white;
                font-weight: 800;
                line-height: 38px;
                font-size: 18px;
                border-radius: 40px;
                background-color: var(--mySideColor);
                z-index: 999;
            }
            .JourneyListItem__container--right__stepName {
                font-size: 16px;
                // color: var(--mySideColor);
                width: 100%;
                color: var(--color-TEXT);
                opacity: 0.5;
                margin-top: 12px;
                text-align: left;
            }
            .JourneyListItem__container--right__progress{
                width: 100%;
            }
        }
    }
    .JourneyListItem__spacer {
        width: 40px;
        height: 100%;
    }
    .JourneyListItem__delete-button {
        max-width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 60px;
        z-index: 99999;
        padding: 10px;
        
        .ButtonIconText__icon {
            background-color: transparent;
            border: none;
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
    }
}
@media screen and (max-width: 600px) {
}