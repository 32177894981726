:root {
    --heightScreen: 100vh;
    --questGame__buttons--height: 300px;
    --questGame__nav--height: 100px;
    --questGame__color--character: #37dadf;
    --questGame__color--object: #129da2;
    --background-color: black;
    --objWidth: 100%;
}

.gameEndScreen {
    position: fixed;
    flex-direction: column;
    background-color: rgba(0,0,0,0.9);
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 999999999999;
    // justify-content: center;
    width: 100%;
    align-items: center;
    padding-top: 100px;
    transition: opacity 400ms ease-in-out;
    h1 {
        color: white;
        align-items: center;
    }
    .stars {
        direction: ltr;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        .star {
            width: 240px;
            height: 240px;
            img {
                width: 240px;
                height: 240px;
            }
        }
    }
    .endButtons {
        transition: opacity 400ms ease-in-out;
        border-radius: 100px;
        // width: 100px;
        height: 100px;
        font-size: 50px;
        background-color: var(--keyboard-key-color);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--questGame__color--object);
        margin-bottom: 10px;
        margin: 10px;
        margin-top: 90px;
        &:active {
            background-color: var(--questGame__color--object);
            color: var(--keyboard-key-color);
        }
        &:after {
            border-radius: 100px;
        
        }
    }
    
}

.questGame__nav {
    position: absolute;
    height: var(--questGame__nav--height);
    
    color: white;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    width: 100%;
    padding: 20px;
    .questGame__nav--section {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        align-items: center;
    }
    .questGame__nav--left {

    }
    .questGame__nav--center {
        justify-content: center!important;
        margin: 0 auto;

    }
    .questGame__nav--right {
        flex-direction: row-reverse;
        justify-content: flex-start;
    }

    .nav-btn {
      &:hover {
        filter: brightness(108%);
        cursor: pointer;
      }  
      
    }
    .questGame__nav--progress {
        max-width: 300px;
        margin: 0 auto;
    }
     .nav-btn__keyboard {
         margin: 30px;
        img{
            height: 40px;
        }
    }
    .questScore {
        direction: ltr;
    }
    .nav-btn__pause {
        
        img{
            height: 60px;
        }
    }
    
}



@keyframes bounce {
    0% { transform: scale(1); }
   25% { transform: scale(0.9); }
   50% { transform: scale(1.3); }
   75% { transform: scale(0.94); }
  100% { transform: scale(1); }
  }
  
  .bounce {
  animation: bounce 400ms forwards;
  }



  @keyframes bounceToLarge {
    0% { transform: scale(1.3); }
   25% { transform: scale(1.1); }
   50% { transform: scale(1.7); }
   75% { transform: scale(1.2); }
  100% { transform: scale(1); }
  }
  
  .bounceToLarge {
  animation: bounceToLarge 400ms forwards;
  }
  
@keyframes bounceDelayed {
    0% { transform: scale(1); }
    70% { transform: scale(1); }
    75% { transform: scale(0.9); }
    80% { transform: scale(1.3); }
    90% { transform: scale(0.94); }
    100% { transform: scale(1); }
}

.bounceDelayed {
    animation: bounceDelayed 1200ms forwards;
}
  
@keyframes wiggle {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(1deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-1deg); }
    100% { transform: rotate(0deg); }
}

.wiggle {
    animation: wiggle 400ms infinite;
}

@keyframes blink {
    0% { background-color: rgba(255,255,255,0); color: var(--questGame__color--object)}
    10%  { background-color: rgba(255,255,255,1); box-shadow: 0 0 24px 22px rgba(255,255,255,0.2); border-color: white; color: var(--questGame__color--object)}
    25%  { background-color: rgba(255,255,255,0.8); box-shadow: 0 0 24px 22px rgba(255,255,255,0.2) ;border-color: white; color: var(--questGame__color--object)}
    100%  { background-color: rgba(255,255,255,0); color: var(--questGame__color--object)}
}

.blink {
    animation: blink 1400ms forwards;
}


  @keyframes trebble {
    0% { margin-left: 0px; }
   20% { margin-left: -20px; }
   80% { margin-left: 20px; }
  100% { margin-left: 0px; }
  }
  

  .trebble {
  animation: trebble 200ms infinite;
  }


  .backgroundGlow {
    animation: backgroundGlow 3400ms infinite;
}



@keyframes backgroundGlow {
    0% { filter: brightness(1)}
    50% { filter: brightness(1.3)}
    100% { filter: brightness(1)}
}

.shadowGlow {
    animation: shadowGlow 3400ms infinite;
}

@keyframes shadowGlow {
    0% { box-shadow: 0 0 15px 1px rgba(255, 255, 255, 0)}
    50% { box-shadow: 0 0 15px 1px rgba(255, 255, 255, 0.9)}
    100% { box-shadow: 0 0 15px 1px rgba(255, 255, 255, 0)}
}


.hovering {
    animation: hovering 2400ms infinite;
}

@keyframes hovering {
    0% { margin-top: 0px}
    50% { margin-top: 10px}
}