.stepItem_VideoStep-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .stepItem_VideoStep-thumbnail {
        &:hover {
            cursor: pointer;
        }
    }
    .watch-btn {
        margin-top: 10px;
    }
    .stepItem_VideoStep-title {
        max-width: 570px;
        padding: 0 12px;
    }
    .stepItem_VideoStep-description{
        max-width: 500px;
        text-align: center;
        margin: 0;
        padding: 0 12px;
        font-size: 22px;
    }
}
