:root {
    --ButtonCircle-size: 70px;
}
body {
    &[lang="he"] {
        .ButtonCircle-back {
            transform: scale(-1);
        }
    }
  }


.ButtonCircle{
    // border: 2px solid white;
    color: white;
    position: relative;
    width: var(--ButtonCircle-size);
    min-width: var(--ButtonCircle-size);
    max-width: var(--ButtonCircle-size);
    height: var(--ButtonCircle-size);
    min-height: var(--ButtonCircle-size);
    max-height: var(--ButtonCircle-size);
    border-radius: 100%;
    
    // border: 2px solid rgba(255,255,255,0.5);
    // background-color: rgba(255,255,255,0);
    transition: border 100ms ease-in-out, background-color 100ms ease-in-out;
    &:before {
        position: absolute;
        top:-1px;
        left:-2px;
        width: var(--ButtonCircle-size);
        min-width: var(--ButtonCircle-size);
        max-width: var(--ButtonCircle-size);
        height: var(--ButtonCircle-size);
        min-height: var(--ButtonCircle-size);
        max-height: var(--ButtonCircle-size);
        content: "";
        border: 2px solid rgba(51, 51, 51, 0.303);
        border-radius: 100%;
        transition: border 100ms ease-in-out;
    }
    &:hover {
        // border: 2px solid rgba(255,255,255,1);
        background-color: rgba(255,255,255,0.1);
        cursor: pointer;
        &:before{
            border: 2px solid #333;
        }
    }
    &:active {
        background-color: rgba(255,255,255,0);
    }
    &:after {
        width: var(--ButtonCircle-size);
        min-width: var(--ButtonCircle-size);
        max-width: var(--ButtonCircle-size);
        height: var(--ButtonCircle-size);
        min-height: var(--ButtonCircle-size);
        max-height: var(--ButtonCircle-size);
        position: absolute;
        background-image: url(/assets/icons/pause-icon-dark.png);
        background-size: cover ;
        content: "";
        top:0;
        left:0;
    }
}

.ButtonCircle-pause {
    margin: 0 2px;
    &:after {
        background-image: url(/assets/icons/pause-icon-dark.png);
    }
}
.ButtonCircle-back {
    &:after {
        background-image: url(/assets/icons/chevron-left-icon-dark.png);
    }
}
.ButtonCircle-settings {
    &:after {
        background-image: url(/assets/icons/settings-icon-dark.png);
    }
}

// .ButtonCircle-print {
//     &:after {
//         background-image: url(/assets/icons/icon-print.svg);
//     }
// }

.ButtonCircle-70px {
    --ButtonCircle-size : 70px;
}

.ButtonCircle-60px {
    --ButtonCircle-size : 60px;
}

.ButtonCircle-56px {
    --ButtonCircle-size : 56px;
}
.ButtonCircle-50px {
    --ButtonCircle-size : 50px;
}