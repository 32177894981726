:root {
    --teamworkCardWidth :360px;
}
$infoCardNumberWidth: 60px;
$imageBorderWidth:12px;
.infoCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: var(--teamworkCardWidth);
    max-width: var(--teamworkCardWidth);
    background-color: white;
    height: fit-content;
    max-height: fit-content;
    @media screen and (max-width: 768px) {min-width: 0}
    .infoCard_image {        
        height: var(--teamworkCardWidth);
        position: relative;
        width: 100%;
        // box-shadow: 0 0 12px rgba(0, 0, 0, 0.33);
        min-width: var(--teamworkCardWidth);
        max-width: var(--teamworkCardWidth);
        @media screen and (max-width: 768px) {min-width: 0; height: fit-content; min-height: fit-content;}
        img {
            width: 100%;
            height: var(--teamworkCardWidth);
            object-fit: cover;
            border: $imageBorderWidth solid white;
            min-width: var(--teamworkCardWidth);
            max-width: var(--teamworkCardWidth);
            min-height: var(--teamworkCardWidth);
            max-height: var(--teamworkCardWidth);
            background-color: white;
            @media screen and (max-width: 768px) {min-width: 0; object-fit: contain; height: fit-content; min-height: fit-content;}
        }
    }
    .infoCard_details {
        background-color: white;
        width: 100%; 
        display: flex;
        flex-direction: row;
        height: fit-content;
        flex: 1;
        align-items: center;
        padding-inline-end: 6px;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.4);
        .infoCard__number {
            // position: absolute;
            // right: 4px;
            min-width: calc($infoCardNumberWidth + $imageBorderWidth);
            min-height: calc($infoCardNumberWidth + $imageBorderWidth);
            height: 100%;
            // max-height: $infoCardNumberWidth;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 35px;
            text-align: center;
            font-weight: 600;
            color: rgb(0, 0, 0);
            background-color: white;
            font-weight: 600;
            padding: 0 27px;
            // border-radius: 100%;
            font-family: var(--mainFont);

        }
        .infoCard_text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: $imageBorderWidth;
            border-inline-start: 1px dotted rgba(0, 0, 0, 0.4);
            width: 100%;
            height: 100%;
            padding: 12px;
            padding-inline-start: 18px;
            .infoCard_title {
                font-size: 22px;
                color: black;
                font-weight: 500;
                text-align: start;
                margin:0;
                &:first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }
    
}

.fade-in {
    opacity: 0;
    transition: opacity 90ms ease-in;
  }
  
  .fade-in.loaded {
    opacity: 1;
  }