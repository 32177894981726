.ActivityCard {
    height: auto;
    width: fit-content;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ActivityCard__compare {
        display: flex;
        flex-direction: row;
        width: fit-content;
        height: fit-content;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 2px solid black;
        box-shadow: 4px 7px 0px rgba(0, 0, 0, 0.1);
        .compare__activityPage {
            border-inline-end: 2px solid black;
        }
        .compare__activityPage, .compare__img{
            width: 130px;
            min-width: 130px;
            max-width: 130px;
            height: 182px;
            min-height: 182px;
            max-height: 182px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            @media screen and (max-width: 768px) {
                width: 100px;
                min-width: 100px;
                max-width: 100px;
                height: 140px;
                min-height: 140px;
                max-height: 140px;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: auto;
                min-height: 100%;
            }
        }
        .compare__arrow {
            position: absolute;
            top:30%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .ActivityCard__details {
        padding-top: 8px;
        width: 100%;
        max-width: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        
        .details__lessonNum {
            text-align: center;
            color: black;
            font-size: 14px;
            font-weight: 400;
        }
        .details__name {
            text-align: center;
            color: black;
            font-size: 22px;
            font-weight: 500;
            padding-bottom: 2px;
        }
        .details__vids {
            color: black;
            display: flex;
            gap: 8px;
            flex-direction: row;
            align-items: center;
            img {
                width: 18px;
                height: 18px;
            }
        }
    }
}