:root {
    --footer_backgroundColor : rgba(0,0,0,0);
}

.ButtonsFooter-container {
    display: flex;
    flex-direction: column;
    height: auto;
    background: var(--footer_backgroundColor);
    padding-bottom: 20px;
    padding-top: 20px;
    .ButtonsFooter {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        height: auto;
        transition: height 200ms ease-in-out, min-height 120ms ease-in-out,max-height 200ms ease-in-out;
        border: none;
        .ButtonsFooter__button {
            max-width: 300px;
        }
    }

    .buttonsOnTop {
        flex-direction: column;
    }
    .CONTENT_belowSubmitContent {
        height: auto;
        
    }

}

.transpBackground {
    background: none;
    @media screen and (max-width: 768px) {
        background: var(--footer_backgroundColor);
    }
}


.ButtonsFooter-twoBtns {
    justify-content: center;
    display: flex;
    justify-content: space-between;
    .ButtonsFooter__button {
        &:first-child {
            margin-right: 10px;
          }
          &:last-child {
            margin-left: 10px;
          }
        
    }
}

.ButtonsFooter-oneBtn {
    justify-content: center;
    // border: 20px solid red !important;
    // background: red;
}


