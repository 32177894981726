
.Room {
    pointer-events: none;
    width: 100%;
    min-width: 100%;
    height: 100%;
    max-height: 900px;
    // min-height:  calc(var(--vh, 1vh) * 100);
    content: "";
    top:0;
    left:0;
    position: absolute; // parent div should be relative!
    background-image: url("./assets/room.png");
    opacity: .2;
    mix-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size:cover;
    background-position: bottom; 
    filter: blur(1.5px);   
    z-index: 0;
}

