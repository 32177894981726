:root {
    --LobbyFooterHeight: 130px;
    --LobbyMaxWidth: 1024px;
    --LobbyTeaserHeight: 500px;
    @media screen and (max-width: 768px) {
        --LobbyFooterHeight: 230px;
    }
}

body {
    &[lang="he"] {
        .lessonLobby-container .lessonLobby-content .lessonLobby-content-b {
            text-align: right;
            // .content-b_lobby-title {text-align: right;}
            // .content-b_lobby-subtitle {text-align: right;}
            .stepRequirements--Lobby-container{text-align: right;}
        }
        .lessonLobby-container .lessonLobby-content .lessonLobby-content-a .teaserThumbnail .teaserThumbnail-instructor {
            
            left: 40px;
            right: auto;
            .Lesson_Instructor--animal {
                transform: scaleX(1);
            }
        }
    }}

.lessonLobby-container {
    height: 100vh;
    // height:calc((var(--vh, 1vh)* 100 )  - var(--LobbyFooterHeight ) );
    // min-height: calc((var(--vh, 1vh)* 100 )  - var(--LobbyFooterHeight ) );
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--page-background-color);
    .lessonLobby-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        min-height: 100%;
        height: auto;
        overflow: scroll;
        padding:30px 40px;
        padding-bottom: 100px;
        .lessonLobby-content-a {
            max-width: var(--LobbyMaxWidth);
            display: flex;
            flex-direction: column;
            width: 100%;
            // align-items: center;
            height: auto;
            .lobby-title {font-size: 22px; font-weight: 500;text-align: center;width: 100%; padding-top: 0px; color: rgba(0, 0, 0, 0.453)}
            .lobby-subtitle {font-size: 23px;text-align: center;width: 100%; padding-bottom: 20px;}
            .teaserThumbnail {
                display: flex;
                // max-height: var(--LobbyTeaserHeight);
                // min-height: var(--LobbyTeaserHeight);
                height: 100%;
                // min-height: 240px;
                width: 100%;
                margin-top: 20px;
                // display: flex;
                justify-content: center;
                align-items: center;
                // flex-direction: column;
                position: relative;
                // background-color: red;
                margin-bottom: 30px;
                &:hover {
                    cursor: pointer;
                    .teaserThumbnail-thumb{transform: scale(1.03);}
                    
                }
                // .teaserThumbnail-instructor {
                //     position: absolute;
                //     bottom: 0px;
                //     right: 40px;
                //     .Lesson_Instructor--animal {
                //     transform: scaleX(-1);
                //     }
                // }
                .teaserThumbnail-btn {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    // max-height: var(--LobbyTeaserHeight);
                    // min-height: var(--LobbyTeaserHeight);
                    height: 100%;
                    width: 100%;
                    gap: 10px;
                    position: absolute;
                    top:0;
                    left: 0;
                    
                    .teaserThumbnail-btn_img {
                        z-index: 2;
                        width: 60px;
                        height: 60px;
                        // transition: transform 200ms ease-in-out;
                        // transform: translate(-50%, -50%) scale(1);
                        img {
                            width: 100%;
                        }
                    }
                    .teaserThumbnail-btn_txt {
                        width: 100%;
                        text-align: center;
                        color: white;
                        font-size: 23px;
                        font-weight: 500;
                        min-width: fit-content;
                        z-index: 999999;
                    }
                }
                .teaserThumbnail-thumb {
                    // max-height: var(--LobbyTeaserHeight);
                    // min-height: var(--LobbyTeaserHeight);
                    height: 200px;
                    width: 340px;
                    // position: absolute;
                    top:0;
                    left: 0;
                    transition: transform 140ms ease-in-out;
                    img {
                        height: 100%;
                        // max-height: var(--LobbyTeaserHeight);
                        object-fit: cover;
                        width: 100%;
                        // border: 10px solid white;
                        border-radius: 20px;
                    }
                }
            }
        }
        .lessonLobby-content-b {
            max-width: var(--LobbyMaxWidth);
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            margin-bottom: 30px;
            .content-b_lobby-title {font-size: 34px; font-weight: 500;text-align: center; width: 100%}
            .content-b_lobby-subtitle {font-size: 20px;text-align: center;width: 100%}
            .stepRequirements--Lobby-container {
                padding-top: 20px;
                flex-direction: row;
                flex-wrap: wrap;
                display: flex;
                gap: 35px;
                width: 100%;
                margin-top: 40px;
                justify-content: center;
                
            }
        }
    }
    .lessonLobby-footer {
        height: auto;
        // height: var(--LobbyFooterHeight);
        // min-height: var(--LobbyFooterHeight);
        width: 100%;
        background-color: var(--page-background-color);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: sticky;
        bottom: 0;
        
        &:before{
            background-color: rgba(255, 255, 255, 0.1504);
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            top:0;
            left: 0;
            z-index: 0;
        }
        .lessonLobby-footer-2buttons {
            height: 100%;
            // height: var(--LobbyFooterHeight);
            // min-height: var(--LobbyFooterHeight);
            // max-width: var(--LobbyMaxWidth);
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 20px;
            z-index: 2;
            padding: 12px;
            @media screen and (max-width: 768px) {
                align-items: space-around;
                flex-wrap: wrap;
                gap: 14px;
                padding: 10px 12px;
            }
            .lessonLobby-button {
                max-height: 90px;
                height: 90px;
                width: 100%;
                min-width: fit-content;
                padding: 0 40px;
                border-radius: 16px;
                font-size: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                @media screen and (max-width: 768px) {
                    min-width: 100%;
                    padding: 0 20px;
                }
            }
            .lessonLobby-button-primary{
            }
            .lessonLobby-button-secondary{

                @media screen and (max-width: 450px) {
                    font-size: 20px;
                }
            }
        }
    }
}

.lessonLobby-container--video {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    background-color: black;
    #video-player {
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        height: 100%!important;
    }
}