$itemWidthDesktop: 134px;
$itemWidthMobile: 70px;

body {
    &[lang='he'] {
      .lesson-step-item {
        .lesson-step-img {
            transform: scaleX(-1);
        }
      }
    }
}

@keyframes lesson-step-item-pointer {
    0%, 90% {
      top: -46px;
    //   animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
      transform: scaleY(1) scaleX(1);
    }
    
    50%{
        top: -36px;
        animation-timing-function: ease-in-out;
        transform: scaleY(1.05) scaleX(1.2);

    }
}

.ArrowSelected {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: -46px;
    animation: lesson-step-item-pointer 3s infinite;

    img {
        width: 100%;
        height: 100%;
    }
    @media (max-width: 768px) {
        display: none;
        transform: rotate(90deg);
        top: 16px;
        inset-inline-start: -44px;
    }
}
.completedGreenV {
    background-color: #08ff9c;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 3px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: -13px;
    inset-inline-start: -13px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.135);
    img {
        width: 100%;
        height: 100%;
    }
    @media (max-width: 768px) {
        inset-inline-start: auto;
        inset-inline-end: -13px;
    }
}

.lesson-step-item {
    border-radius: 20px;
    transition: transform 90ms ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // gap: 10px;
    position: relative;
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
        align-items: flex-start;
    }
    @media (max-width: 768px) {
        flex-direction: row;
        &:hover {
            transform: scale(1);
            align-items: center;
        }
    }
    .lesson-step-imageToShow {
        width: 100%;
        max-width:100%;
        top: 0;
        margin-top: -58px;
        z-index: 1;
    }
    .lesson-step-img {
        margin-top: -78px;
        margin-bottom: -14px;
        transform: scaleX(1);
        img {max-height: 160px;}
        @media (max-width: 768px) {
            margin-bottom: 0px;
            margin-top: -88px;
        }
    }
    .lesson-step-item--container {
        width: 100%;
        display: flex;
        justify-content: center;
        @media (max-width: 768px) {
            max-width: $itemWidthMobile;
            height: 100%;
            align-items: center;
        }
    }
    .lesson-step-item--number {
        // position: absolute;
        font-size: 20px;
        font-family: var(--mainFont);
        font-weight: 500;
        width: 40px;
        max-height: 40px;
        background-color: black;
        border-radius: 100%;
        color: white;
        text-align: center;
        line-height: 40px;
        transition: color 90ms ease-in-out, background-color 90ms ease-in-out;
        height: 100%;
        margin-top: 22px;
        @media (max-width: 768px) {
            margin-top:0;
        }

    }
    .lesson-step-item--name {
        // position: absolute;
        
        width: 100%;
        color: black;
        padding: 0 12px;
        text-align: center;
        font-family: var(--mainFont);
        font-weight: 500;
        margin-top: 10px;
        @media (max-width: 768px) {
            text-align: start;
            margin-top: 0px;
            font-size: 22px;
            padding-inline-start: 0;

        }
    }
}
.lesson-step-item__size-ROW {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: $itemWidthMobile;
    flex-direction: row;
    justify-content: center;
    // justify-content: flex-start;
    .lesson-step-img {
        max-width: 90px;
        padding-bottom: 20px;
    }
    .lesson-step-item--name {
        text-align: start;
        font-size: 23px;
        margin:0;
        padding: 0;
    }
    &:hover {
        text-align: start;
        align-items: center;
    }
}

.lesson-step-item__size-CUSTOM-IMG {
    width: $itemWidthDesktop;
    min-width: $itemWidthDesktop;
    // max-width: $itemWidthDesktop;
    min-height: $itemWidthDesktop;
    height: auto;
    background-color: rgb(234, 114, 134);
    @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: $itemWidthMobile;
        flex-direction: row;
        justify-content: center;
        // justify-content: flex-start;
        img {
            max-width: 90px!important;

        }
        .lesson-step-img {
            max-width: 90px;
            padding-bottom: 20px;
        }
        .lesson-step-item--name {
            text-align: start;
            font-size: 23px;
            margin:0;
            padding: 0;
        }
        &:hover {
            text-align: start;
            align-items: center;
        }
    }
}



.lesson-step-item__size-S {
    width: $itemWidthDesktop;
    min-width: $itemWidthDesktop;
    // max-width: $itemWidthDesktop;
    min-height: $itemWidthDesktop;
    height: auto;
    background-color: rgb(234, 114, 134);
    @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: $itemWidthMobile;
        // height: $itemWidthMobile;
        // max-height: $itemWidthMobile;
    }
}
.lesson-step-item__size-XL{
    width: 100%;
    min-width: $itemWidthDesktop;
    // max-width: 166px;
    min-height: $itemWidthDesktop;
    height: auto;
    padding-bottom: 20px;
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
        align-items: center;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        // height: $itemWidthMobile;
        // max-height: $itemWidthMobile;
        .lesson-step-item--name {
            text-align: center;
            padding-top: 20px;
        }
    }
}


.lesson-step-item-current{  
    pointer-events: none;
    // border: 2px solid black !important;
    position: relative;
    // outline: #ffffffdf solid 5px;
    // filter: brightness(1.3);
    .lesson-step-item--number {
        background-color: white !important;
        outline: 2px solid rgba(0, 0, 0, 0.295) !important;
    }
    &:before {
        width: calc(100% + 14px);
        min-width: calc(100% + 14px);
        height: calc(100% + 14px);
        min-height: calc(100% + 14px);
        content: "";
        border-radius: 24px;
        top: 50%;
        left: 50%;
        outline: #ffffffdf solid 5px;
        position: absolute;
        transform: translate(-50%, -50%);
        animation: breathCurrentOutline 3s infinite;
    }
}
@keyframes breathCurrentOutline {
    0%, 100% {
        width: calc(100% + 10px);
        min-width: calc(100% + 10px);
        height: calc(100% + 10px);
        min-height: calc(100% + 10px);
        // border-radius: 22px;
        outline: #ffffffdf solid 3px;
        opacity: 0.7;
    }
    50% {
        width: calc(100% + 14px);
        min-width: calc(100% + 14px);
        height: calc(100% + 14px);
        min-height: calc(100% + 14px);
        // border-radius: 24px;
        outline: #ffffffdf solid 5px;
        opacity: 1;
    }
}
.lesson-step-item__state-enabled{ 
    background-color: rgb(255, 255, 255); 
    // border: 2px solid rgb(236, 255, 230);
    .lesson-step-item--number {
        color: black;
        outline: 2px solid rgba(0, 0, 0, 0.444);
        background-color: transparent;
    }
}
.lesson-step-item__state-disabled{ 
    background-color: rgba(245, 245, 245, 0.2);
    pointer-events: none;

    .lesson-step-item--number {
        // color: black;
        // outline: 2px solid rgba(245, 245, 245, 0.2) ;
        outline: 2px solid rgba(0, 0, 0, 0.2) ;
        background-color: transparent !important ;
    }
    // border: 2px solid rgba(245, 245, 245, 0.2);
    .lesson-step-item--number {
        // color: rgba(255, 255, 255, 0.782);
        color: rgba(0, 0, 0, 0.4);
    }
    .lesson-step-item--name {
        // color: rgba(255, 255, 255, 0.784);
        color: rgba(0, 0, 0, 0.5) ;
    }
}
.lesson-step-item__state-completed{
    // border: 1px solid white;
    // background: var(--color-complete-gradient);
    border: 2px solid var(--color-completed-dark);
    background: linear-gradient(180deg, #6cf1b1 0%, #7BFBC2 38%);
    .completedGreenV {z-index: 0;}
    // background: #7BFBC2;
    .lesson-step-item--name {
        color: black;
    }
     .lesson-step-item--number {

        color: black;
        background-color: rgba(46, 174, 157, 0.278);
        background-color: transparent;
        outline: 2px solid var(--color-completed-dark);
    }
}
.lesson-step-item__state-celebrate{ 
    background: linear-gradient(45deg, #0dd001, #08ff9c);
    &:before {
        position: absolute;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        top: -8px;
        right: -8px; 
        background-repeat: no-repeat;
        background-image: url("/assets/icons/icon-v-circle_40.png");
        background-size: contain;
        background-color: #0dd001;
        border-radius: 100%;
        content: "";
      }
}

.lesson-step-item__coin--container {
    width: 100%;
    // border-radius: 20px 60px 60px 20px;
    border-radius: 60px;
    border-end-end-radius: 20px; 
    border-start-end-radius: 20px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 14px;
    padding: 14px;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-start;
    background-color: rgb(255, 255, 255);
    max-height: 90px;
    margin-top: 4px;
    margin-bottom: 20px;
    &:before {
        border-radius: 60px;
        border-end-end-radius: 20px; 
        border-start-end-radius: 20px;
    }
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
        align-items: center;
    }
    .lesson-step-item--text_container{
        margin-inline-end: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-content: flex-start;
        align-items: flex-start;
        @media (max-width: 768px) {
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
    .lesson-step-item__coin--img {
        position: relative;
        // background-color: rgb(198, 198, 198);
        width: 100px;
        height: 100px;
        min-width: 100px;
        min-height: 100px;
        border-radius: 100%;
        transition: transform 90ms ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline-start: -27px;
        content: "";
        @media (max-width: 768px) {
            width: 120px;
            height: 120px;
            min-width: 120px;
            min-height: 120px;
            margin-inline-start: -32px;
            // &:hover {
            //     transform: scale(1);
            // }
        }
        img {
            width: 110px;
            height: 110px;
            min-width: 110px;
            min-height: 110px;
            border-radius: 100%;
            box-shadow: 0 3px 0 rgba(0, 0, 0, 0.132) ;

            @media (max-width: 768px) {
                width: 108px;
                height: 108px;
                min-width: 108px;
                min-height: 108px;
            }
        }
        // .completedGreenV {
        //     background-color: #08ff9c;
        //     width: 40px;
        //     min-width: 40px;
        //     height: 40px;
        //     min-height: 40px;
        //     border-radius: 100%;
        //     border: 3px solid white;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     text-align: center;
        //     position: absolute;
        //     top: 5px;
        //     inset-inline-start: 5px;
        //     box-shadow: 0 3px 12px rgba(0, 0, 0, 0.135);
        //     @media (max-width: 768px) {
        //         inset-inline-start: auto;
        //         inset-inline-end: 5px;
        //     }
        //     img {
        //         width: 100%;
        //         min-width: 100%;
        //         height: 100%;
        //         min-height: 100%;
        //     }
        // }
        .ArrowSelected {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: absolute;
            top: -40px;
            img {
                width: 100%;
                min-width: 100%;
                height: 100%;
                min-height: 100%;
            }
            @media (max-width: 768px) {
                top:34px;
                inset-inline-start: -55px;
            }
        }
    }
    
    .lesson-step-item--Skillname {
        font-weight: 500;
        font-size: 22px;
        margin:0;
        padding: 0;
        // margin-top: 8px;
        @media (max-width: 768px) {
            margin-top: 0px;
        }
    }

    
}

.lesson-step-item__coin__state-disabled {
    background-color: rgba(245, 245, 245, 0.2);
    pointer-events: none;
    .lesson-step-item__coin--img {
        // outline: 3px solid black;
    }
    .lesson-step-item--name {
        color: black;
    }
}
.lesson-step-item__coin__state-enabled {
    .lesson-step-item__coin--img {
        // outline: 3px solid rgb(255, 255, 255);
    }
    .lesson-step-item--name {
        // color: blue;
    }
}

.lesson-step-item__coin__state-completed {
    border: 2px solid var(--color-completed-dark);
    background: linear-gradient(180deg, #6cf1b1 0%, #7BFBC2 38%);

    .lesson-step-item__coin--img {
        outline: 3px solid yellow, 12px solid red;
    }
    .lesson-step-item--name {
        // color: yellow;
    }
}

.lesson-step-item__coin-current {
    pointer-events: none;
    .lesson-step-item--Skillname {color: white}
    .lesson-step-item--smallText {color: rgba(255, 255, 255, 0.857)}
    .lesson-step-item__coin--img {
        // border: 16px solid black;
        // outline: 5px solid white;
        // box-shadow: 34px 4px 6px red;

        position: relative;
        &:before {
            width: calc(100% + 14px);
            min-width: calc(100% + 14px);
            height: calc(100% + 14px);
            min-height: calc(100% + 14px);
            content: "";
            border-radius: 50%;
            top: 50%;
            left: 50%;
            outline: #ffffffdf solid 5px;
            position: absolute;
            transform: translate(-50%, -50%);
            animation: breathCurrentCoinOutline 3s infinite;
        }


        img {
            // animation: breathCurrentOutline 3s infinite;
        }

    }
    .lesson-step-item--name {
        color: white;
    }
}

@keyframes breathCurrentCoinOutline {
    0%, 100% {
        width: calc(100% + 8px);
        min-width: calc(100% + 8px);
        height: calc(100% + 8px);
        min-height: calc(100% + 8px);
        outline: #ffffffdf solid 8px;
        opacity: 1;
    }
    50% {
        width: calc(100% + -5px);
        min-width: calc(100% + -5px);
        height: calc(100% + -5px);
        min-height: calc(100% + -5px);
        outline: #ffffffdf solid 3px;
        opacity: 1;
    }
}


.lesson-step-item--smallText {
    font-size: 16px;
    font-weight: 400;
    margin:0;
    padding: 0;
    color: rgba(0, 0, 0, 0.424);
}

