:root {
    --loadingBackground : white; 
}

.Loading {
    // width: 100vw;
    // height: 100vh;
    // min-width: 100vw;
    // min-height: 100vh;
    z-index: 99999999;
    // background-color: rgb(13, 255, 0);
    // background-color: #2991ff;
    // content: "";
    // height: 100vh;

    .Loading--container {
        // background-color: rgba(255, 255, 255, 0.5);
        background-color: var(--loadingBackground);
        transform: translate(-50%, -50%);
        position: absolute;
        position: fixed;
        left: 50%;
        top: 50%;
        width: 100vw;
        height: 100vh;
        min-width: 100vw;
        min-height: 100vh;
        // height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 999999;
        // margin-bottom: 17vh;
        // .Loading__img--logo {
        //     width: 140px;
        //     height: auto;
        // }
        .Loading__img--preloader {
            width: 100px;
            height: auto;
            margin: 0 auto;
            margin-top: -16vh;
            
            // mix-blend-mode: multiply;
            // filter: hue-rotate(220deg);
        }
    }
}