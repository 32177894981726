

.myTime-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    max-width:100%;
    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        height: 100%;
    }
}

.schedules-list{
    height: 100%;
    max-width: 30% !important;
    // max-width: 700px;
    background-color: white;
    width: 100%;
    // height: 100%;
    padding: 0px;
    // margin: 0;
    transition: width 300ms ease-in-out, opacity 300ms ease-in-out, padding 300ms ease-in-out;
    overflow-y: scroll;
    // &:first-child , &:last-child {
    //     // border: red 2px solid;
    //     margin-top: 36px;
    // }
    @media screen and (max-width: 1024px) {
        overflow-y: visible;
        max-width: 100% !important;
        min-width: 375px;
    }
}

.schedules-list__Closed {
    width: 0 !important;
    opacity: 0 !important;
    padding: 0 !important;
}

.scheduleXL__container {
    z-index: 999;
    width: 100vw;
    height: 100vh;
    max-width:100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-bottom: 14%;
    transition: background-color 400ms ease-in-out;
    position:relative;
    // background-color: red !important;
    .myTime__NAV {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center; 
        min-height: 40px;
        height: 70px;
        position:absolute;
        top: 0;
        .myTime__NAV--button {
            padding: 10px;
            cursor: pointer;
            width: 100px;
            text-align: center;
            min-height: 40px;
            background-color: rgba(255,255,255,0.1);
        }
    }
    .scheduleXL__content {
        // padding-top: 70px;
        display: flex;
        flex-direction :column;
        // justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        .scheduleXL__content--item {
            width: 100%;
        }
        .scheduleXL__content--time {
            font-size: 80px;
            font-weight: 100;
            z-index:9999;
        }
        
    }
}