body {
    &[lang="he"] {
        // .userinfo-container .userinfo-container__main .userinfo-container__field .input-feedback .input-feedback__label{
        .userinfo-container {
            direction: ltr;
        }
    }      
}

.userinfo-container {
    background-color: #fff;
    justify-content: flex-start;
    width: 100%;
    height: 100%; 
    min-height: calc(var(--vh, 1vh) * 100 ); 
    display: flex;
    flex-direction: column;
    // gap:30px;
    
    margin-bottom: 0;
    // padding-bottom: 100px;
    .userinfo-top {
        padding-top: 12px;
        position: fixed;
        z-index: 999999;
        // height: 176px;
        background-color: rgba(255, 255, 255, 0.914);
        padding-bottom: 12px;
        // background-color: red;
        border-bottom: 1px solid #dddfe0;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.054);
        .userinfo-container__email {
            max-width: 800px;
            width: 100%;
            // height: 160px;
            display: flex;
            text-align: left;
            padding: 0 12px;
            // background-color: red;
            justify-content: flex-start;
            align-items: flex-end;
            gap: 7px;
            margin-bottom: 20px;
            .userinfo-container__field {
                width: 100%;
                max-width: 400px;
                .input-feedback {
                    // padding: 0;
                    width: 100%;
                    max-width: 100%;
                    padding: 0;
                    margin: 0;
                    
                    .input-feedback__label{
                        direction: ltr;
                        text-align: left;
                        padding: 0;
                        margin: 0;
                        font-size: 13px;
                    }
                    .input-feedback__field{
                        // background-color: rebeccapurple;
                        // height: 100px;
                        min-height: 30px;
                        height: 40px;
                        resize:none;
                        padding: 9px 13px;
                        margin-bottom: 0;
                        font-family: 'Varela Round', Helvetica, Arial, sans-serif;
                        letter-spacing: 0;
                        color:#273b47;
                        font-size: 18px;
                        border-radius: 4px;
                        border: 1px solid #648498;
                    } 
                }
            }
            
        }   
        .userinfo-container__tableUserInfo {
            // border-top: 2px solid rgba(0, 0, 0, 0.138);
            // height: 200px;
            width: 100%;
            color: black;
            // padding: 20px 0;
            // margin: 0 12px;
            
        }
        
    }
    .userinfo-container__RelationshipUserInfo {
        // border-top: 2px solid rgba(0, 0, 0, 0.138);
        // height: auto;
        padding-top: 188px;
        width: 100%;
        color: black;
        // padding: 20px 0;
        // margin-top: 40px;
        
    }
}

.userinfo_button {
    background: var(--color-skyblue);
    // border: 1px solid #648498;
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 9px;
    height: 40px;
    min-width: 100px;
    text-align: center;
    border-radius: 4px;
    font-size: 22px;
    &:hover {
        opacity: 0.9; 
        cursor:pointer;  
    }
    &:active {
        background: #98c9e6;
        cursor:pointer;  
    }
}

.userinfo_button__s {
    height: 38px;
    padding: 10px;
    width: 140px;
    font-size: 14px;
}