:root {
    --sideBarPaneNavHeight : 80px;
}

body {
    &[lang="he"] {
        .SideBarPanelNav--container {
            direction: rtl;
            .SideBarPanelNav--left{ 
                text-align: right;
            }
            .SideBarPanelNav--right{ 
                text-align: left;
            }
      }
    }
  }

.SideBarPanelNav--container {
    width:100%;
    min-height: var(--sideBarPaneNavHeight);
    // padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 12px;
    border-bottom: 1px solid #e2e2e2;
    align-items: center;
    .SideBarPanelNav--left{
        text-align: left;
        // max-width: 140px;
        // min-width: 140px;
        align-items: center;
        width:100%;
        flex:1;
        display: flex;
        justify-content: flex-start;
    }
    
    .SideBarPanelNav--center{
        text-align: center;
        font-size: 26px;
        flex: 4;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .SideBarPanelNav--center__title{
            font-size: 18px;
            text-align: center;
        }
        .SideBarPanelNav--center__title-top-padding {
            padding-top: 8px;
        }
        .SideBarPanelNav--center__subTitle{
            font-size: 16px;
            text-align: center;
            opacity: 0.5;
            margin-bottom: 2px;
        }
    }
    .SideBarPanelNav--right{
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex:1;
        width:100%;
        height: 100%;
    }
    .flex-2 {
        flex:2;
    }
}

.SideBarPanelNav--fixed {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.98);
    z-index: 999;
}