.stepRequirements--item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
    width: 90px;
    text-align: center;
    .RadioButton {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 10px;
        top: 10px;
        background-color: rgba(0, 0, 0, 0.236);
        border-radius: 100%;
        content: " ";
    }
    .RadioButtonON {
        background-color: white;
        background-image: url("/assets/icons/v-green-32.png");
        background-size: contain;
        border: 2px solid white;
    }
    .stepRequirements--a {
        .stepRequirements--img{
            width: 90px;
            height: 90px;
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .img_sizeL {
            width: 300px;
            height: 300px;
            img {
                border-radius: 20px;
    
            }
        }
        .img_sizeXL {
            width: 400px;
            height: 400px;
            img {
                border-radius: 20px;
    
            }
        }
    }
    .stepRequirements--b {
        .stepRequirements--name{
            // color: rgba(255, 255, 255, 0.745);
            color: rgba(0, 0, 0, 0.746);
            font-weight: 600;
        }
        .stepRequirements--description {
            font-size: 15px;
            text-align: center;
        }
        .whiteText {
            color:white;
        }
        .name_L {
            font-size: 30px;
            font-weight: 600;
        }
        .description_L {
            font-size: 22px;
        }
    }
}

.stepRequirements--item-L {
    width: 300px;
}
.stepRequirements--item-XL {
    width: 400px;
}

.stepRequirements--item-M {
    width: 180px;
    margin-bottom: 0px;
    .stepRequirements--a .stepRequirements--img {
        width: 100%;
        height: auto;
        img {
            width: 180px;
            max-height: 180px;
            object-fit: contain;
            padding: 10px;
        }
    }
    .stepRequirements--b .stepRequirements--name{
        // color: black;
        font-size: 20px;
        font-weight: 500 ;
    }
}

.stepRequirements--hideBackground {
    .stepRequirements--img{
        background-color: transparent;
    }
}

.stepRequirements--directionRow {
    flex-direction: row;
    width: auto;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    .stepRequirements--b {
        padding-top: 10px;
    }
}
.req-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .req-container_miniButton {
        // background-color: transparent;
        border: 1px solid black;
        font-size: 16px;
        color: black;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 6px 12px;
        height: auto;
        min-height: auto;
        // border-radius: 4px;
        margin-top: 10px;
        img {
            height: 22px;
        }
    }
}