.toggleSwitch_check-box {
	// transform: scale(2);
}

.toggleSwitch_input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 60px;
    height: 32px;
    background: #ccc;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 120ms ease-in-out;
    border: 1px solid rgba(0, 0, 0, 0.311);
}

.toggleSwitch_input:checked[type="checkbox"] {
    background: var(--color-primary);
}

.toggleSwitch_input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 32px;
    height: 32px;
    top: 0;
    left: 0;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 120ms ease-in-out;
    background-image: url("/assets/icons/icon_music-note-off.png");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center; 
    
}

.toggleSwitch_input:checked[type="checkbox"]::after {
    background-image: url("/assets/icons/icon_music-note.png");
    left: 50%;
}
