:root {
    --obj-min-width : 230px;
    --objJustifyContent : flex-start;
}

.questGame__container {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    .questGame__columns{
        // margin-top: 80px;
        // background-color: blue;
        // height: calc(100vh - 100px);
        height: var(--heightScreen);
        // height: 100%;
        display: flex;
        flex-direction: row;
        .questGame__column--A {
            color: var(--questGame__color--character);
            width: 50%;
            padding-right: 40px;
            // padding-top: 20px;
            h1 {
                font-family: 'Secular One', sans-serif;
                font-weight: 400;
                font-size: 120px;
                line-height: 180px;
                word-spacing: 20px;  
            }
            span{
                font-family: 'Secular One', sans-serif;
            }
            .characterName {
                color: var( --questGame__color--character);
            }
            .objectName {
                color: var( --questGame__color--object);
            }
            .questGame__answer {
                border: 4px dashed var(--questGame__color--object);
                padding: 0 30px;
                border-radius: 20px;
                min-width: 220px;
                text-align: center;
                min-height: 170px;
                line-height: 130px;
                display: inline-block;
                padding-top: 17px;
                color: var(--questGame__color--object);

            }
        }
        .questGame__column--B {
            width: 50%;
            display: flex;
            .questGame__stage {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                padding-top: 100px;
                .questGame__stage--objects {
                    z-index: 10;
                    width: 100%;
                    display: flex;
                    justify-content: var(--objJustifyContent);
                    height: 40%;
                    flex-wrap: wrap;
                    align-items: center;
                    padding-left: 30px;
                    img {
                        width: var(--objWidth);
                        min-width: var( --obj-min-width);                        
                        height: 40%;
                        object-fit: scale-down;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .questGame__stage--character{
                    z-index: 5;
                    width: 100%;
                    height: 60%;
                    position: relative;
                    // background: red;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: scale-down;
                        position: absolute;
                        bottom: 0;
                        left: -140px;
                        // background-color: purple;
                        // border: 3px solid red;
                    }
                }
            }
        }
    }
}

.overlay {
    font-size: 120px;
    position: absolute;
    // top: 20px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    align-items: space-between;
    justify-content: space-between;
    z-index: 99999999;
    .heart {
        padding: 20px;
    }
    .sticker {
        // width: 100%;
        // background: red;
        margin: 0 auto;
    }
}