.somethingWrong_container{
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    min-width: 100%;
    padding-top: 140px;
    background-color: var(--page-background-color);
    padding: 20px;
    padding-top:60px;
    img {
        width: 100%;
        max-width: 400px;
    }
}
.somethingWrong_header{
    font-size: 48px;
    color: white;
    font-weight: 600;
    margin:0;
    margin-top: 30px;
    padding: 0;
    max-width: 600px;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 34px;
    }
}
.somethingWrong_message{
    max-width: 600px;
    text-align: center;
    font-size: 22px;
    margin:0;
    margin-top: 10px;
    padding: 0;
    margin-bottom: 40px;
    color: white;
}