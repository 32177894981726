:root {
  --lesson-item-width: 300px; /* Adjust the width as needed */
  --scrollIndex: 0;
}

body {
    &[lang="he"] {
        .course--container .Lessons__list {
        direction: rtl;
        // padding-left: 0;
        // padding-right: calc(var(--scrollIndex) * var(--lesson-item-width));
        // flex-direction: row-reverse;
        margin-left: 0;
        // margin-right: 200px;
      }
    }
  }
  
  .course--container {
    background-color: #e084e4;
    background-color: var(--page-background-color);
    padding: 0;
    margin: 0;
    padding-top: 60px;
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: calc(var(--vh, 1vh) * 100); 
    min-width: 100%;
    position: relative;
    @media (max-width: 768px) {
      padding-top: 180px;
    }
  }
  .SideBarPanelNav--container {
    border-bottom: 1px black solid;
  }
  .Lessons--thumbnail {
    bottom: 0;
    width: 100%;
    margin: 30px auto;
    max-width: 800px;
    border-radius: 20px;
    img {
        width: 100%;
        height: auto;
        max-height: 220px;
      }
  }
  
  .Lessons__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: margin-right 400ms ease-in-out;
    width: fit-content;
    gap: 130px;
    padding: 0 100px;
    margin-top: -80px;
    a {
      width: 100%
    }
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 0px;
      padding: 0 10px;
      width: 100%;
      padding-bottom: 200px;
      gap: 90px;
    }
  }
  .Lessons--thumbnail-names {
    margin: 0 auto;
    text-align: center;
  }


