

.Popup {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 2147483647;
    justify-content: center;
    align-items: center;
    padding: 25px;
    @media (max-width: 768px) {
        padding: 5px;
    }
    &:before {
        background-color: var(--page-background-color);
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        content: "";
        position: absolute;
        opacity: 0.7;
        z-index: 999999999999;
    }
    .Popup__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 999999999999;
        width: 100%;
        max-width: 630px;
        max-height: 90vh; // Limits the popup to 90% of the viewport height
        background-color: white;
        box-shadow: 0 12px 40px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        overflow: hidden;
        .Popup__header {
            display: flex;
            height: auto;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #e2e2e2;
            padding-inline-end: 10px;
            .Popup__x {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 14px;
                    height: 14px;
                }
            }
        }
        .Popup__content {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex-grow: 1;
            overflow-y: auto;
            text-align: start;
        }
        .Popup__footer {
            width: 100%;
            height: 100%;
            padding: 20px;
            padding-top: 0px;
            .PopupBtnFooter {
                width: fit-content;
                min-width: fit-content;
                font-size: 22px;
                padding:16px 30px;
                box-shadow: none;
                min-height: 0;
                @media (max-width: 768px) {
                    width: 100%;
                    max-width: 100%!important;
                }
            }
        }
    }
}

.Popup-txt {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.Popup__title {
    font-size: 20px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.Popup-subtitle {
    font-size: 15px;
    padding: 0;
    margin: 0;
}

.PopupSentMsg {
    padding:20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    margin-inline-start: -20px;
}