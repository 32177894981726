.lang-img {
    width: 100%;
    height: auto;
    max-width: 400px;
    padding-bottom: 40px;
    img {
        width: 100%;
        height: auto;
        max-width: 400px;

    }
}