:root {
  --Background-color: rgba(0, 0, 0, 0§2);
  --Modal-text-alignment: center;
  --Modal-width: 0px;
  --Modal-height: auto;
  --Modal_background-color: white;
  --Modal_text-color: #333;
  --Modal_border-radius: 8px;
  --Modal_border-radius-bottom: 0;
  --Modal_margin-bottom: 30vh;
  --Modal_top: auto;
  --Modal_bottom: auto;
  --Modal_right: auto;
  --Modal_left: 50%;
  --Modal-FadeSpeed: 500ms;
  --Modal-translateY: 0;
  --Modal-translateX: 0;
  --tip-left: 50%;
  --tip-top: 50%;
  --padding-top: 14px;
  --imgContainerBg: rgba(0, 0, 0, 0);
  --imgHeight: auto;
  --imgWidth: 100%;
  --Modal_padding-top: 0px;
  --Modal_padding-bottom: 0px;
  --Modal_margin-top: 0px;
  --Modal_margin-bottom: 0px;
  --img_padding-top: 0;
  --img_padding-bottom: 0;
  --img_margin-top: 0;
  --img_margin-bottom: 0;
}

.Modal-container {
  // background-color: red;
  width: 100%;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  // border: 20px solid blue;
  z-index: 9999999;
  max-width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  // max-height: calc(var(--vh, 1vh) * 100);
  align-items: center;
  justify-content: center;
  color: white;
  top: 0;
}
.Modal-background {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  z-index: 6000;
  align-items: center;
  justify-content: center;
  background-color: var(--Background-color);
  top: 0;
  left: 0;
}

.Modal {
  // transform: translate(var(--Modal-translateX), var(--Modal-translateY));
  position: absolute;
  // top: var(--Modal_top);
  // bottom: var(--Modal_bottom);
  // left: var(--Modal_left);
  // right: var(--Modal_right);
  display: flex;
  flex-direction: column;
  z-index: 9999999;
  // background: var(--Modal_background-color);
  width: var(--Modal-width);
  max-width: var(--Modal-width);
  // max-height: 100%;
  height: var(--Modal-height);
  max-height: calc(var(--vh, 1vh) * 100);
  border-radius: var(--Modal_border-radius);
  // box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  color: var(--Modal_text-color);
  // margin-top: var(--Modal_margin-top);
  // margin-bottom: var(--Modal_margin-bottom);
  

  
  @media screen and (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    margin: 0;
    position: fixed;
    // top:auto;
    bottom: 0;
    padding: 0;
    margin: 0;
    max-height: calc(var(--vh, 1vh) * 100);
    height: calc(var(--vh, 1vh) * 100);
    // left: 0;
    border-radius: 0px;
  }
  .Modal__x {
    color: #626262;
    position: absolute;
    right: 10px;
    top:8px;
    font-size: 30px;
    transform: rotate(45deg);
    transition: background-color 100ms ease-in-out;
    -webkit-font-smoothing: antialiased;
    border: 1px solid #d1d1d1;
    z-index: 99999;
    background-color: rgba(244, 244, 244, 0.881);
    width: 36px;
    height: 36px;
    text-align: center;
    border-radius: 100%;
    padding: 0;
    line-height: 36px;
    margin:0;
    &:hover {
      background-color: white;
      cursor: pointer;
    }
  }
  .Modal__nav {
    .img-container {
      width: 100%;
      height: var(--imgHeight);
      background-color: var(--imgContainerBg);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: var(--img_margin-top);
      margin-bottom: var(--img_margin-bottom);
      .Modal__form--img {
        margin: 0 auto;
        display: block;
        padding-bottom: 10px;
        border: none !important;
        width: var(--imgWidth);
        max-width: var(--imgWidth);
        padding-top: var(--img_padding-top);
        padding-bottom: var(--img_padding-bottom);
      }
    }
    .img-container_topRoundedCorners {
      border-radius: var(--Modal_border-radius) var(--Modal_border-radius) 0 0;
    }
    .Modal__title {
      text-align: center;
      text-align: var(--Modal-text-alignment);
      margin-top: 26px;
      margin-bottom: 6px;
      -webkit-font-smoothing: antialiased;
      color: var(--Modal_text-color);
      padding: 0 20px;
    }

    .Modal__subtitle {
      text-align: var(--Modal-text-alignment);
      margin: 0;
      padding: 0;
      -webkit-font-smoothing: antialiased;
      color: var(--Modal_text-color);
      opacity: 0.6;
      padding: 0 20px;
      padding-bottom: 20px;
    }
  }
  .Modal__form {
    display: flex;
    flex-direction: column;
    // height: calc(var(--vh, 1vh) * 100);
    // max-height: calc(var(--vh, 1vh) * 100);
    position: relative;
    
    p {
      padding-top: var(--Modal_padding-top);
      padding-bottom: var(--Modal_padding-bottom);
    }

    -webkit-font-smoothing: antialiased;
    text-align: var(--Modal-text-alignment);
    color: var(--Modal_text-color);
    overflow-y: scroll;
    background: var(--Modal_background-color);
    max-height: calc(var(--vh, 1vh) * 100);
    border-radius: var(--Modal_border-radius) var(--Modal_border-radius) var(--Modal_border-radius-bottom) var(--Modal_border-radius-bottom);
    padding-bottom: 40px;
    
    .Modal-p {
      padding: 0 20px;
      font-size: 20px;
      margin: 0 auto;
    }
    .img-container {
      width: 100%;
      height: var(--imgHeight);
      background-color: var(--imgContainerBg);
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: var(--img_margin-top);
      margin-bottom: var(--img_margin-bottom);
      .Modal__form--img {
        margin: 0 auto;
        display: block;
        padding-bottom: 10px;
        border: none !important;
        width: var(--imgWidth);
        max-width: var(--imgWidth);
        padding-top: var(--img_padding-top);
        padding-bottom: var(--img_padding-bottom);
      }
    }
    .img-container_topRoundedCorners {
      border-radius: var(--Modal_border-radius) var(--Modal_border-radius) 0 0;
    }
    .form-label {
      font-size: 15px;
      padding: 0;
    }
    .Modal__form--error-msg {
      text-align: center;
      color: #f24462;
      margin-bottom: 20px;
    }
    .Modal__form--sumbit-button {
      width: 100%;
      border-radius: var(--Modal_border-radius);
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .preloaderGif {
      width: 100%;
      height: 100%;
      &:after {
        width: 30px;
        height: 30px;
        // position: absolute;
        content: '';
        z-index: 99999;
        background-image: url(/assets/preloader-w.gif);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .Modal__below-submit {
    text-align: center;
    margin-bottom: 20px;;
    -webkit-font-smoothing: antialiased;
    border: red solid 20px;

    background-color: red !important;
    background: red!important;
    width: 100%;
    height: 100%;
    
    //0-----

    margin-top: 20px;
    color: #666666;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    .register_cta__skip {  
      text-align: center;
      color: white;
      width: auto;
      border-radius: 100px;
      // background-color: rgba(255, 255, 255, 0.092);
      padding: 12px;
      padding: 12px 40px;
      text-decoration: underline;
      transition: background-color 120ms ease-in-out;
      font-size: 18px;
      &:hover {
        // background-color: rgba(255, 255, 255, 0.209);
        text-decoration: underline;
      }
      }
    a {
      text-decoration: underline;
      cursor: pointer;
    }

    .Modal__below-submit--micro-text {
      font-size: 13px;
      margin-top: 20px;
      opacity: 0.6;
      line-height: 1.45;
      letter-spacing: 0.01em;
    }
  }
}

.Modal-background__dark {
  background-color: rgba(0, 0, 0, 0.2);
}

.ButtonsFooter {
  // border-top: 1px solid #e2e2e2;
}
.ButtonsFooter-container {
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
  @media screen and (max-width: 768px) {
    border-end-start-radius: 0px;
    border-end-end-radius: 0px;
  }
}