@import url('https://fonts.googleapis.com/css?family=Varela+Round:400,700');
@import url('https://fonts.googleapis.com/css?family=Gaegu:400,700');
@import url('https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mali:wght@200;300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

// @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap");



* {
  font-family: var(--mainFont);
  // font-family: 'Comfortaa', sans-serif;
  box-sizing: border-box;
}
html,
body {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  &[lang='he'] {
    direction: rtl;
    .text-align_left {
      text-align: right;
    }
    .chevron-right {
      transform:scaleX(-1)
    }
    .list__item--button {
      .timeAgo {
        text-align: left !important;
      }
      &:after {
        right: auto;
        left: -7px;
      }
    }
    .flip-he {
      transform: scaleX(-1);
      // position: absolute;
    }
    .tinyPrint {
      text-align: right;
      opacity: 0.5;
      margin-top: 0px;
      font-size: 15px;
    }
    .bullets-container {
      text-align: right;
      div {
        margin-bottom: 4px;
      }
    }
  }
}

:root {
  --mySideColor: black;
  --partnerColor: black;
  --viewHeight: calc(var(--vh, 1vh) * 100);
  --mainFont: "Fredoka", sans-serif;
  
  //opacity
  .opacity-0 {
    opacity: 0;
  }
  .opacity-10 {
    opacity: 0.1;
  }
  .opacity-25 {
    opacity: 0.25;
  }
  .opacity-50 {
    opacity: 0.5;
  }
  .opacity-75 {
    opacity: 0.75;
  }
  .opacity-100 {
    opacity: 1;
  }
  // fonts

  .fontSize-14 {
    font-size: 14px;
  }
  .fontSize-18 {
    font-size: 18px;
  }
  .fontSize-20 {
    font-size: 20px;
  }
  .fontSize-24 {
    font-size: 24px;
  }
  .fontSize-26 {
    font-size: 26px;
  }
  .fontSize-36 {
    font-size: 36px;
  }
  .fontSize-40 {
    font-size: 40px;
  }
  .fontSize-50 {
    font-size: 50px;
  }

  @media screen and (max-width: 768px) {
    .fontSize-36 {
      font-size: 26px;
    }
    .fontSize-40 {
      font-size: 30px;
    }
    .fontSize-50 {
      font-size: 35px;
    }
  }

  .antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a:link, a:visited, a:hover, a:active {
    color: your-desired-color; /* Replace with the color you want */
  }

  a {
    color: inherit; /* Inherits the color from its parent element */
    text-decoration: none; /* Removes underline if needed */
    user-select: none;           /* Prevent text selection */
    -webkit-user-drag: none;      /* Prevent drag in WebKit-based browsers */
    -moz-user-select: none;       /* Additional support for Firefox */
  }
  //catbears-landing-page
  --mainFont: "Fredoka", sans-serif;
  --pagePaddingDesktop: 30px;
  --pagePaddingMobile: 12px;
  --pageMaxWidth: 1300px;
  --color-mainYellow: #f2dd3d; 
  --color-mainYellow-brighter: #fcf400;
  --color-mainSkyblue: #d9eeff;
  --color-craftMat: #2ba752;
  --shadow-card: 0 0px 22px rgba(0, 0, 0, 0.1);
  --shadow-button: 0 2px 12px rgba(0, 0, 0, 0.25);
  //
  --page-background-color:  #00abfd;
  --color-primary: #19b51f !important;
  --color-completed: #08ff9c!important;
  --color-completed-dark: #019459!important;
  --color-complete-gradient: linear-gradient(45deg, #08ff9c, #37ffcd);

  // felt colors:
  --color-red: #f54d4d;
  // --color-orange: #FF7931;
  // --color-orange: rgb(255, 153, 0);
  --color-orange: #fa7d15;
  --color-green: #66c76f;
  --color-skyblue: #00abfd;
  --color-skyblue-transp: rgba(59, 153, 235, 0.85);
  --color-darkblue: #164570;
  // --color-darkblue: #9a81b5;
  --color-yellow: #f5b227;
  --color-brown: #9c714d;
  --color-light-brown: #ddbfa1;
  --color-purple: #662d91;
  --color-pink: #ff93f8;
  --color-magenta: #fa7a9a;
  --color-bej: #ffdfce;
  --color-feltKit: #f6e7d8;
  --color-gold: #FFCA22;
  --color-gold-dark: rgb(135, 105, 6);
  --color-TEXT: #292929;
  --color-green-success: #78c93c;
  --color-black: rgb(28, 28, 28);
  // --color-light-gray: #d2d2d2;
  --color-light-gray: #e6e6e6;
  --color-gray: #c2c2c2;
  --color-paper: #fffff5;
  --color-paper-border : #ccc8ac;
}

@supports (color: color(display-p3 1 1 1)) {
  :root {
    --color-mainSkyblue: color(display-p3 0.8531 0.9354 1.0000);
    --color-mainYellow: color(display-p3 0.9490 0.8706 0.2392);
  }
}

.mySideColor {
  color: var(--mySideColor);
}

.partnerColor {
  color: var(--partnerColor);
}
form {
  h1 {
    margin-bottom: 12px;
    // padding-bottom: 0;
  }
}

h4 {
  font-size: 22px;
  margin: 20px 0 10px 0;
  margin-top: 10px;
  color: var(--color-TEXT);
  font-weight: 300;
}

.tinyPrint {
  text-align: left;
  padding: 0;
  margin: 0;
  color: #777;
  font-size: 14px;
}
.form-label {
  font-size: 17px;
  padding-bottom: 3px;
  color: var(--color-TEXT);
}

.form-textarea {
  height: auto;
}

input[type='text'].form-input,
.form-textarea,
.input, .input::placeholder {
  // background: #ffffff00;
  border: 1px solid #cccccc;
  border-radius: 8px;
  min-height: 60px;
  color: var(--color-TEXT);
  outline: none;
  width: 100%;
  font-size: 19px !important;
  padding: 17px;
  margin-bottom: 20px;
  margin-top: 5px;
  position: relative;
  &:focus-within {
  border: 1px solid var(--mySideColor);
  font-size: 19px !important;
  }
}


.cantTouchThis {
  pointer-events: none;
}

.LAYOUT-SIMPLE {
  padding: 0 20px;
  @media screen and (max-width: 1024px) {
    padding: 0 12px;
  }
}

.LAYOUT-fullscreen {
  min-height: 100%;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.LAYOUT-pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .LAYOUT-list {
    max-width: 700px;
    width: 100%;
    height: 100%;
    padding: 10px 14px;
    margin: 0;
    a {
      text-decoration: none;
      color: var(--color-TEXT);
      color: var(--mySideColor);
    }
    .list__item {
      margin-bottom: 40px;

      .mini-text {
        font-size: 14px;
      }
    }
    .list__item--button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      font-weight: 600;
      border-radius: 10px;
      height: auto;
      min-height: 70px;
      border: 1px solid #ccc;
      border: 1px solid var(--mySideColor);
      color: var(--mySideColor);
      width: 100%;
      list-style: none;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 14px;
      &:before {
        background-color: var(--mySideColor);
        width: 100%;
        border-radius: 9px;
        opacity: 0.14;
        height: 100%;
        content: '';
        position: absolute;
        z-index: -1;
      }
      // &:after {
      //   position: absolute;
      //   width: 70px;
      //   height: 100%;
      //   content: "›";
      //   font-size: 50px;
      //   font-weight: 100;
      //   background: rgba(0,0,0,0.4);
      //   color: var(--mySideColor);
      //   right: -7px;
      //   bottom: 0;
      //   text-align: center;
      //   line-height: 62px;
      //   transition: color 90ms ease-in-out;
      // }
      transition: color 90ms ease-in-out, background-color 90ms ease-in-out,
        border-color 90ms ease-in-out;
      &:hover {
        color: #fff;
        background-color: var(--mySideColor);
        border-color: var(--mySideColor);
        cursor: pointer;
        a {
          color: #fff;
        }
        &:after {
          color: white;
        }
      }
      div {
        padding: 15px 20px;
        margin: 0;
        span {
          margin-right: 8px;
        }
      }
      .timeAgo {
        font-size: 14px;
        line-height: 20px;
        min-width: 160px;
        // margin: 0 10px;
        // text-align: center;
        text-align: right;
        div {
          margin: 0;
          padding: 0;
        }
        .timeAgo__title {
          font-size: 13px;
          opacity: 0.65;
          // text-transform: uppercase;
        }
      }
      h2 {
        font-size: 20px;
      }
      span {
        text-transform: capitalize;
      }
      &.disabled {
        color: #444;
        background-color: #e2e2e2;
        border-color: #e2e2e2;
        opacity: 0.4;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
        &:hover {
          background: #fff;
          border-color: #999;
          // cursor: not-allowed;
          background-color: #e2e2e2;
        }
      }
    }
    .list__item--edit {
      background-color: white;
      border: none;
    }
  }
  .thumbnailContainer {
    width: 100%;
    height: 230px !important;
    margin-bottom: 60px;
    position: relative;

    .felt-thumbnail {
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 40px;
      border-radius: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      // overflow-y: hidden;
      // border: 1px solid red;

      &:after {
        background-image: url(w-bg.png);
        content: '';
        background-size: cover;
        // width: 1936px;
        width: 100%;
        height: 85px;
        // opacity: 0.5;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0px;
        // left: 0;
        z-index: 1;
      }
      .side {
        z-index: 2;
      }
      // .felt-thumbnail__name--mySide {
      //   color: var(--mySideColor);
      // }
      // .felt-thumbnail__name--partnerSide {
      //   color: var(--partnerColor);
      // }
      @media screen and (max-width: 768px) {
        background: linear-gradient(
          132deg,
          var(--mySideColor) 59%,
          var(--partnerColor) 50%
        ) !important;
      }
      img {
        margin-top: 80px;
        width: 100%;
        max-width: 188px;
        height: auto;
        
      }
      // .felt-thumbnail__name {
      //   padding-bottom: 30px;
      // }
    }
  }
}
.NAV-title {
  max-width: 400px !important;
}
.LAYOUT-form {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  justify-content: space-between;
  //  height: -webkit-fill-available;
  //  margin-bottom: 50px;
  .LAYOUT-form-body {
    overflow: scroll;
    // overflow: hidden;
    // height: calc(100vh - 110px)!important;
    -webkit-overflow-scrolling: touch;
  }
  .LAYOUT-formStickyfooter {
    display: flex;
    position: relative;
    flex-direction: column;
    // align-items: center;
    padding-top: 14px;
    justify-content: center;
    min-height: 110px;
    //height: 110px;
    //max-height: 110px;
    //border-top: 1px solid #e2e2e2;
    margin: 0;
    box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    z-index: 3;
    .LAYOUT-formStickyfooter__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      max-height: 60px;
      .LAYOUT-footer__button {
        // background-color: var(--mySideColor);
        background-color: white !important;
        width: 100%;
        //max-width: 300px;
        // &:first-child{
        //   margin-left: 10px;
        // }
        // &:last-child {
        //   margin-right: 10px;

        // }
        // min-width: 300px!important;
      }
      .LAYOUT-footer__button--save {
        max-width: 200px;
        color: var(--mySideColor);
        border-color: var(--mySideColor);
      }
    }
  }
  .LAYOUT-notification {
    background-color: #fff;
    box-shadow: 2px 0 12px 1px rgba(0, 0, 0, 0.2);
    // border-radius : 10px;
    width: 100%;
    // max-width: 350px;
    height: 80px;
    z-index: 9999999;
    position: fixed !important;
    top: 90px;
    margin: 0 auto;
    padding: 20px;
    transition: opacity 500ms ease-in-out, top 500ms ease-in-out;
    top: 0;
    text-align: center;
    color: #019c15;
    line-height: 40px;
    font-size: 24px;
    font-weight: 600;
    // left: 30px;
  }
  .LAYOUT-notification__on {
    opacity: 1;
    top: 0;
    // left: 30px;
  }
  .LAYOUT-notification__off {
    opacity: 0;
    top: -80px;
  }
}
.LAYOUT-TopPadding {
  // margin-top: 62px;
}

.LAYOUT-BottomMargin {
  margin-bottom: 60px;
}
.LAYOUT-pageStatickNav {
  display: flex;
  width: 100%;
  height: 80px;
  position: relative;
  .LAYOUT-pageTitle__button {
    background-color: white !important;
    color: var(--color-TEXT);
    width: 100%;
    max-width: 140px;
  }
  .LAYOUT-pageTitle {
    text-align: center;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    padding-top: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 90px;
    padding-right: 90px;
    h4 {
      color: white;
    }
  }
}

.LAYOUT-LeftRightPadding {
  padding-left: 14px;
  padding-right: 14px;
}

input[type='text'] {
  background: transparent;
  border: 1px solid white;
  border-radius: 3px;
  height: 25px;
  color: white;
  outline: none;
  width: 300px;
  font-size: 16px;
  padding: 10px;
  &::placeholder {
    color: #ccc;
    font-size: 16px;
  }
}
label {
  display: flex;
  font-size: 13px;
  padding-bottom: 3px;
}

.radio-3-horizontal {
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 1);
  min-height: 40px;
  color: white;
  max-width: 300px;
  width: 300px;
  min-width: 300px;
  border-radius: 4px;
  > div {
    border: none !important;
    margin-right: 0 !important;

    &:hover {
      // background-color: rgba(255,255,255,0.25);
    }
    &:nth-child(2) {
      border-right: 1px solid rgba(255, 255, 255, 0.75) !important;
      border-left: 1px solid rgba(255, 255, 255, 0.75) !important;
    }
    div {
      div {
        box-sizing: content-box;
        display: flex;
        width: 100%;
        color: white;
        div {
          background-color: orange !important;
        }
        &:last-child {
          min-width: 10px;
        }
      }
    }
  }
}

.micro-text {
  font-size: 14px;
  color: #777;
  margin: 0;
  margin-bottom: 20px;
}

.micro-text__white {
  color: white;
}
.dropdown-container {
  .Dropdown-control {
    line-height: 55px;
    font-size: 22px;
  }
}
.text-field,
.dropdown-container {
  max-width: 300px;
  width: 300px;
  min-width: 300px;
  margin: 0 auto;
  label {
    color: white;
    font-size: 18px;
    margin-bottom: 8px;
  }
  input,
  .Dropdown-control {
    height: 40px;
    min-height: 40px;
    text-align: left;
    // line-height: 56px;
    text-align: center;
    border-radius: 0;
    padding: 0 13px !important;
    width: 100% !important;
    // font-size: 18px !important;
    color: white;
    // border: none;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.75) !important;
    font-weight: 100 !important;
    transition: background-color 90ms ease-in-out, color 90ms ease-in-out,
      border-color 90ms ease-in-out;

    &::placeholder {
      // color: rgba(255, 255, 255, 0.55) !important;
      font-size: 18px;
      color: white;
    }
  }
}
.text-field {
  margin-top: 12px;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 18px;
  line-height: 23px;
  padding-top: 4px;
  input {
    border: none;
    font-size: 18px;
    line-height: 23px;
  }
  &:hover {
    // border: 1px solid rgba(255, 255, 255, 1)!important;
    background-color: rgba(255, 255, 255, 0.2) !important;
  }
  &:focus {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }
}
.Dropdown-control {
  border-radius: 12px !important;
  height: 60px;
  min-height: 60px !important;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 255, 255, 0.75) !important;
  .Dropdown-arrow {
    margin-top: 12px;
    margin-right: 10px;
    transform: scale(1.5);
    border-color: white transparent transparent;
  }
  &:focus {
    .Dropdown-arrow {
      border-color: white transparent transparent;
    }
  }
}

.texture {
  background-image: url('/assets/backgrounds/texture.png') !important;
  background-repeat: repeat !important;
  width: 100%;
  height: 100%;
  background-blend-mode: overlay;
  background-size: contain;
}

.gallery-button {
  width: 40px;
  height: 40px;
  // background: red;
  align-self: center;
  cursor: pointer;
  // missing asset!
  // background-image: url('/assets/chooseFlow/button-gallery-dis.png');
  &:hover {
    // missing asset!
    // background-image: url('/assets/chooseFlow/button-gallery.png');
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.gallery-button__right {
  margin-left: 20px;
  transform: scale(-1);
}

.gallery-button__left {
  margin-right: 20px;
}

.footer {
  border-top: 1px solid #e2e2e2;
  padding-top: 50px;
  margin-top: 40px;
}

.displayNone {
  display: none !important;
}

.notVisable {
  visibility: hidden;
}

.chevron-right {
  background-color: rgb(77, 77, 77);
  height: 30px;
  width: 26px;
  margin:0;
  padding: 0;
  // -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 60% 100%, 86% 50%, 60% 0);
  // clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 60% 100%, 86% 50%, 60% 0);
  -webkit-clip-path: polygon(60% 4%, 95% 40%, 100% 50%, 95% 60%, 60% 96%, 50% 100%, 48% 90%, 86% 50%, 48% 10%, 50% 0);
  clip-path: polygon(60% 4%, 95% 40%, 100% 50%, 95% 60%, 60% 96%, 50% 100%, 48% 90%, 86% 50%, 48% 10%, 50% 0);
}

.popup {
  color: white;
  padding-bottom: 100px;
  margin: 0 auto;
  max-width: 360px;
  
  img {
    width: 100%;
  }
}

.popup_center {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.popup-dark-txt {
  color: black;
}

.txtCenter {
  text-align: center;
}



// --- CSS animations

@keyframes hoverAnimation {
  0% {
    margin-bottom: 0;
  }
  50% {
    margin-bottom: 20px;
  }
}
.hoverAnimation {
  animation-name: hoverAnimation !important;
  animation-duration: 1000ms !important;
  animation-iteration-count: infinite;
  // animation-timing-function: ease-in-out;
  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
}

@keyframes slide-right-to-center {
  from {
    opacity: 0;
    margin-right: -200px;
  }
  to {
    opacity: 1;
    margin-right: 0;
  }
}
@keyframes slide-center-to-right {
  from {
    opacity: 1;
    margin-left: 0px;
  }
  to {
    opacity: 0;
    margin-left: 200px;
  }
}
@keyframes slide-left-to-center {
  0% {
    opacity: 0;
    margin-left: -200px;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}
@keyframes slide-center-to-left {
  0% {
    opacity: 1;
    margin-left: 0;
  }
  100% {
    opacity: 0;
    margin-left: -200px;
  }
}
@keyframes slide-top-to-center {
  0% {
    opacity: 0;
    margin-top: -200px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes slide-center-to-top {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: -200px;
  }
}
@keyframes slide-center-to-bottom {
  0% {
    opacity: 1;
    margin-bottom: 0;
  }
  100% {
    opacity: 0;
    margin-bottom: -200px;
  }
}
@keyframes slide-bottom-to-center {
  0% {
    opacity: 0;
    margin-bottom: -200px;
  }
  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}

.slide-left-to-center {
  animation-name: slide-left-to-center !important;
  animation-duration: var(--Modal-AnimationSpeed) !important;
  animation-fill-mode: forwards;
}
.slide-center-to-left {
  animation-name: slide-center-to-left !important;
  animation-duration: var(--Modal-AnimationSpeed) !important;
  animation-fill-mode: forwards;
}
.slide-right-to-center {
  animation-name: slide-right-to-center !important;
  animation-duration: var(--Modal-AnimationSpeed) !important;
  animation-fill-mode: forwards;
}
.slide-center-to-right {
  animation-name: slide-center-to-right !important;
  animation-duration: var(--Modal-AnimationSpeed) !important;
  animation-fill-mode: forwards;
}

.slide-top-to-center {
  animation-name: slide-top-to-center !important;
  animation-duration: var(--Modal-AnimationSpeed) !important;
  animation-fill-mode: forwards;
}

.slide-center-to-top {
  animation-name: slide-center-to-top !important;
  animation-duration: var(--Modal-AnimationSpeed) !important;
  animation-fill-mode: forwards;
}

.slide-bottom-to-center {
  animation-name: slide-bottom-to-center !important;
  animation-duration: var(--Modal-AnimationSpeed) !important;
  animation-fill-mode: forwards;
}

.slide-center-to-bottom {
  animation-name: slide-center-to-bottom !important;
  animation-duration: var(--Modal-AnimationSpeed) !important;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    // transform: scale(1.03);
    // box-shadow: 0 3px 10px rgba(0,0,0,0);
  }
  10% {
    opacity: 0;
    //   transform: scale(1.03);
    // box-shadow: 0 3px 10px rgba(0,0,0,0);
  }
  100% {
    opacity: 1;
    //   transform: scale(1);
    // box-shadow: 0 3px 10px rgba(0,0,0,0.2);
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
    //   transform: scale(1);
    // box-shadow: 0 3px 10px rgba(0,0,0,0.2);
  }
  90% {
    opacity: 0;
    //   transform: scale(1.03);
    // box-shadow: 0 3px 10px rgba(0,0,0,0);
  }
  100% {
    opacity: 0;
    // transform: scale(1.03);
    // box-shadow: 0 3px 10px rgba(0,0,0,0);
  }
}

.fade-in {
  animation-name: fade-in;
  animation-duration: var(--Modal-AnimationSpeed);
  animation-iteration-count: 1;
  // animation-timing-function: ease-in-out;
  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);

  // animation: fade-in 2s infinite;
}

.fade-out {
  animation-name: fade-out !important;
  animation-duration: var(--Modal-AnimationSpeed) !important;
  animation-iteration-count: 1;
  // animation-timing-function: ease-in-out;
  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
}

// ---------

.opacity100 {
  opacity: 1;
}
.opacity0 {
  opacity: 0;
}
.hidden{
  opacity: 0;
  font-size: 1px;
}
.add-bounce-animation {
  // -webkit-animation: add-bounce-animation 0.8s cubic-bezier(.81,1.95,.75,.54) 1;
  // animation: add-bounce-animation 0.8s cubic-bezier(.81,1.95,.75,.54) 1;
  animation-name: add-bounce-animation !important;
  animation-duration: 200ms !important;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.breathAnimation {
  animation-name: breathAnimation !important;
  animation-duration: 1400ms !important;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
// @keyframes add-bounce-animation {
//   0% {
//     transform: scale(1);
//     opacity: 0;
//   }
//   40% {
//     opacity: 1;
//   }
//   60% {
//     transform: scale(1.03);
//   }
//   70% {
//     transform: scale(0.999);
//     // opacity: 1;
//   }
//   80% {
//     transform: scale(1.02);
//     // opacity: 1;
//   }
//   100% {
//     transform: scale(1);
//     // opacity: 1;
//   }
// }
@keyframes add-bounce-animation {
  0% {
    transform: scale(0.9);
    // opacity: 0;
  }
  100% {
    transform: scale(1);
    // opacity: 1;
  }
}
@keyframes breathAnimation {
  0% {
    opacity: 0.6;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
.fade-in_slow-1 {
  -webkit-animation: fade-in-slow 0.5s 1;
  animation: fade-in-slow 0.5s 1;
}

.fade-in_slow-2 {
  -webkit-animation: fade-in-slow 1s 1;
  animation: fade-in-slow 1s 1;
}

.fade-in_slow-3 {
  -webkit-animation: fade-in-slow 2.4s 1;
  animation: fade-in-slow 2.4s 1;
}

@keyframes fade-in-slow {
  0% {
    opacity: 0;
    margin-top: -20px;
  }
  80% {
    opacity: 0;
    margin-top: 0px;
  }
  100% {
    opacity: 1;
  }
}

// ------- colors


img {
  -webkit-user-drag: none; /* Safari and older browsers */
  user-drag: none;         /* Standard browsers */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.alwaysLTR {
  direction: ltr !important;
}

// LOGIN AWS
.Form__formContainer___1GA3x {
  margin: 0 !important;
}
.Section__container___3YYTG {
  // background-color: red;
  // background-image : url("https://catbears.com/img/felt/about/felt-about_img01.png");
  background-image: url(/assets/felt-footer.jpg);
  background-size: contain;
  min-height: 100vh !important;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #21b7ff;
  padding: 50px 20px 0;
  @media screen and (max-width: 768px) {
    background-size: 300%;
    padding: 0;
    // background-position: right bottom;
  }
}

.Toast__toast___XXr3v {
  background-color: #fa53ce !important;
  position: fixed !important;
  display: flex;
  align-items: center;
  height: 80px;
  opacity: 0.83;
  span {
    &:before {
      content: '🙈 ';
    }
    font-size: 20px;
  }
  .Toast__toastClose___18lU4 {
    &:after,
    &:before {
      transition: opacity 90ms ease-in-out;

      background-color: white;
      opacity: 1;
    }
    &:hover {
      &:after,
      &:before {
        background-color: white;
        opacity: 0.6;
      }
    }
  }
}
.Section__sectionHeader___2djyg span {
  // padding-bottom: 30px!important;
  display: flex;
  flex-direction: column;
  &::before {
    content: 'FELT\a' !important;
    white-space: pre;
    font-size: 52px;
    font-family: 'Chelsea Market', 'avenier', 'helvetica', 'ariel';
    color: rgb(255, 153, 0);
    padding-bottom: 15px !important;
  }
}

.Hint__hint___2XngB {
  font-size: 14px !important;
}

.Button__signInButton___3bUH-,
.Strike__strike___1XV1b {
  display: none;
}
//*[@id="root"]/div/div/div/form/div[2]
.Button__button___vS7Mv {
  font-size: 18px !important;
}

.Section__sectionFooter___1T54C {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  :first-child {
    //button
    width: 100% !important;
    margin-bottom: 20px;
  }
  :nth-child(2) {
    // background-color: red;
    border-top: 1px solid #e2e2e2;
    padding-top: 20px;
    width: 100% !important;
    height: 40px;
    font-size: 18px;
    color: #152939;
    // min-width: 500px !important;
    a {
      @media screen and (min-width: 340px) {
        background-color: #21b7ff !important;
        color: white;
        padding: 10px 26px;
        border-radius: 30px;
        font-size: 16px !important;
      }

      &:hover {
        text-decoration: none;
        filter: brightness(108%);
      }
    }
  }
}

.modal-body {
}

// ----- colors

.color-white {
  color: white;
}
.color-red {
  color: var(--color-red);
}
.color-orange {
  color: var(--color-orange);
}
.color-green {
  color: var(--color-green);
}
.color-skyblue {
  color: var(--color-skyblue);
}
.color-darkblue {
  color: var(--color-darkblue);
}
.color-yellow {
  color: var(--color-yellow);
}
.color-brown {
  color: var(--color-brown);
}
.color-light-brown {
  color: var(--color-light-brown);
}

.color-purple {
  color: var(--color-purple);
}
.color-pink {
  color: var(--color-pink);
}
.color-magenta {
  color: var(--color-magenta);
}
.color-bej {
  color: var(--color-bej);
}
.color-light-gray {
  color: var(--color-light-gray);
}
.color-black {
  color: var(--color-black);
}
.color-feltKit {
  color: var(--color-feltKit);
}
.color-gold {
  color: var(--color-gold)
}
// backgrouns

.backgorundColor-primary {
  background-color: var(--color-primary);
}

.background-white {
  background-color: white;
}
.background-red {
  background-color: var(--color-red);
}
.background-orange {
  background-color: var(--color-orange);
}
.background-green {
  background-color: var(--color-green);
}
.background-skyblue {
  background-color: var(--color-skyblue);
}
.background-darkblue {
  background-color: var(--color-darkblue);
}
.background-yellow {
  background-color: var(--color-yellow);
}
.background-brown {
  background-color: var(--color-brown);
}
.background-light-brown {
  background-color: var(--color-light-brown);
}

.background-purple {
  background-color: var(--color-purple);
}
.background-pink {
  background-color: var(--color-pink);
}
.background-magenta {
  background-color: var(--color-magenta);
}
.background-bej {
  background-color: var(--color-bej);
}
.background-light-gray {
  background-color: var(--color-light-gray);
}
.background-black {
  background-color: var(--color-black);
}
.background-feltKit {
  background-color: var(--color-feltKit);
}
.background-gold {
  background-color: var(--color-gold);
}

// ------

.bullets-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  .bullets_title {
    margin-bottom: 10px;
    font-weight: 800;
  }
}

// &[lang='he'] {
//   .CHEATS {
//   direction: ltr;
//   }
// }
.cursorPointer {
  cursor: pointer;
}

.CHEATS {
  direction: ltr !important;
  display: flex;
  position: fixed;
  font-size: 20px;
  color: #3b3b3b;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.706);
  // border: 4px solid red;
  z-index: 99999999999;
  justify-content: center;
  align-items: center;
  width:100%;
  .text-bold {
    margin: 0 auto;
    font-weight: 900;
    font-size: 26px;
    color: red;
    line-height: 60px;
    width: 200px;
    height: 60px;
    background-color: rgb(228, 228, 228);
  }
.CHEATS-flex-row {
  display: flex;
  gap: 5px;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.CHEATS-flex-column {
  display: flex;
  gap: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cheats-progress {
  gap: 20px;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  .cheats-progress-lesson {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  }
}
}
.CHEATS-FIXED{
  position: fixed !important;
  background-color: rgba(255, 255, 255, 0.814);
}
.CHEATS-miniButton {
  padding: 4px;
  width: fit-content;
  min-width: 30px;
  height: 20px;
  border: 1px solid #939393;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f3f3f3;
  &:hover{
    cursor: pointer;
  }
  &:active {
    background-color: #d9d9d9;
  }
}
.CHEATS-miniBox {
  text-align: center;
  padding: 4px;
  width: 30px;
  height: 30px;
  border: 1px solid #939393;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CHEATS-textarea {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  height: 30px;
  padding: 0;
  margin:0;
  width: 120px;
}
.CHEATS-left {
  text-align: left;
  align-content: flex-start;
  justify-content: flex-start;
  margin: 0;
  width: 400px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  button {
    max-width: 300px;
  }
  }
  .CHEATS-fixed {
    position: relative !important;
    background-color: rgba(255, 0, 0, 0.703)!important;
    top: 0;
    left: 0;
    z-index: 99999999999999999999999;
    color: white;
    font-weight: 800;
  }

  .CHEATS-absolute {
    position: absolute !important;
  }
  .CHEATS-relative {
    position: relative !important;
  }

  .CHEATS-underline {
    text-decoration: underline !important;
  }

  .disabledClick {
    pointer-events: none;
  }



  .buttonCatbears {
    background-color: black;
    font-size: 30px;
    padding: 20px 40px;
    width: fit-content;
    min-width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--mainFont);
    font-weight: 500;
    gap: 10px;
    border-radius: 16px;
    color: white;
    cursor: pointer;
    border: none;
    transition: background-color 110ms ease-in-out, color 110ms ease-in-out;
    
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
      supported by Chrome, Edge, Opera and Firefox */
    @media screen and (max-width: 768px) {
      font-size: 22px;
      padding: 14px 20px;
      box-shadow: none;
      border-radius: 12px;
    }
    @media screen and (max-width: 530px) {
      width: 100%;
    }
    &:hover {
      background-color: rgb(32, 32, 32);
    }
    .text , a{
      font-family: var(--mainFont);
      font-weight: 500;
      color: white;
      text-decoration: none;
    }
    img {

      height: 38px;
    }
    .icon {
      img {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .buttonCatbears_CTA {
    color: black;
    // border: 2px solid black;
    background-color: var(--color-mainYellow);
    border: 2px solid #ffcf0f;

    font-weight: 600;
    // box-shadow: 2px 6px 0px rgba(0, 0, 0, 0.2);
    &:hover {
      background-color: var(--color-mainYellow-brighter);
    }
  }
  .buttonCatbears_XL {
    font-size: 38px;
    padding: 17px 80px;
    padding-bottom: 24px;
    border-radius: 20px;
    height: fit-content;
    @media screen and (max-width: 768px) {
      font-size: 28px;
      padding: 17px 40px;
      padding-bottom: 22px;
      border-radius: 16px;
    }
  }
  .buttonCatbears_Medium {
    font-size: 26px;
    padding: 16px 26px;
    padding-bottom: 20px;
    box-shadow: none;
    border-radius: 12px;
  }
  .buttonCatbears_Small {
    font-size: 18px;
    padding: 10px 20px;
    box-shadow: none;
    border-radius: 12px;
    box-shadow: var(--shadow-buttonCatbears);
  }
  .buttonCatbears_White {
    color: black;
    background-color: white;
    &:hover {
      background-color: var(--color-mainYellow)
    }
  }
  .buttonCatbears_Bounce {
    &:hover {
      transform: scale(1.1);
    }
  }
  .buttonCatbears_FitContent {
    max-width: fit-content;
    max-height: fit-content;
  }
  
  
  .preloaderGif {
    width: 100%;
    height: 100%;
    &:after {
      width: 30px;
      height: 30px;
      // position: absolute;
      content: '';
      z-index: 99999;
      background-image: url("/assets/preloader-w.gif");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .blackOverlay {
    position: fixed;
    background-color: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 99999999999999;
  }

  .catbearsLink {
    font-size: 22px;
    color: white !important;
    text-decoration: underline !important;
  }

 
  .golden-text {
    font-weight: bold;
    font-size: 2em;
    background: linear-gradient(90deg, #ffd700, #ffea00, #ffc107) !important;
    background-size: 200%;
    background-clip: text!important;
    -webkit-background-clip: text!important;
    color: transparent!important;
    animation: flicker 1.5s infinite alternate;
  }
  
  @keyframes flicker {
    0% {
      opacity: 1;
      filter: brightness(1.2);
    }
    50% {
      opacity: 0.9;
      filter: brightness(1.5);
    }
    100% {
      opacity: 1;
      filter: brightness(1);
    }
  }
  
  .celebration-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    opacity: 0.2;
    mix-blend-mode: overlay;
    filter: blur(1.5px);
    width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
  }

.hideScrollbar {
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  
  /* Hides scrollbar in Chrome, Safari, and Edge */
  &::-webkit-scrollbar {
      display: none;
  }
}
