body {
    &[lang="he"] {
        .homepage {
        direction: rtl;
        .homepage-content {
          .homepage-content--bottom {
            .felt--container {
              .felt--container--side-a {
                .felt--container--text{
                  h1, p {
                    // color: red;
                    direction: rtl;
                    text-align: right;
      
                  }
    
              }
              }
            }
          }
        }
      }
    }
  }
  
  .homepage {
    display: flex;
    flex-direction: column;
    //max-width: 748px;
    margin: 0 auto;
    // min-height: var(--viewHeight); // add for Bottom felt
    height: 100vh;  // remove for Bottom felt
  
    // @media screen and (max-width: 550px) {
    //   padding-top: 60px;
    // }
    background-color: var(--color-skyblue);
    .homepage-content {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      // justify-content: center;
      align-items: space-between;
      // gap: 40px;
      .homepage-content--top {
        display: flex;
        width: 100%;
        height: 100%;
        // background-color: beige;
        // padding: 12px;
        align-items: center;
        flex-direction: column;
      }
      .homepage-content--bottom {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          padding: 20px;
          padding-top: 14px;
          
          // background-color: wheat;
          // background-color: var(--color-darkblue);
          // background-color: #4e372d;
          background-color: #0b5baa;
        .felt--container {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          gap: 20px;
  
          .felt--container--side-a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 12px;
            width: 100%;
            .felt--container--text {
              display: flex;
              flex-direction: column; 
              width: 100%;  
              max-width: 800px; 
              padding-bottom: 24px;
              // padding :12px;
              h1 {
                font-size: 30px;
                line-height: 34px;
                text-align: left;
                color: var(--color-TEXT);
                margin: 0;
                padding: 0;
                padding-bottom: 10px;
                color: white;
                font-family: var(--mainFont);
                font-weight: 500;
                text-shadow: -2px 3px black, 0 2px black, 2px 0 black, 0 -2px black;
  
              }
            
              p {
                // margin-top: 10px;
                font-size: 18px;
                line-height: 24px;
                // margin-bottom: 40px;
                text-align: left;
                margin: 0;
                padding: 0;
                color: white;
              }
            
              // a {
              //   text-decoration: none;
              // }
            }
          }
          .felt--container--side-b {
            padding: 0 50px;
            margin-top: -30px;
            .catbearsAngry {
              width: 260px;
              min-width: 220px;
              margin: 0 auto;
            }
          }
          
        }
        
        
        .felt__list {
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          list-style-type: none;
          width: 100%;
          margin: 0 auto;
          align-items: center;
          // justify-content: center;
          justify-content: flex-start;
          gap: 0 20px;
          a {
            padding: 0;
            margin: 0;
  
          }
          .add-button {
            order: -1;
            @media screen and (max-width: 550px) {
              margin-top: 40px;
              width: 100%;
              button {
                height: 100px;
              }
            }
          }
        
          @media screen and (max-width: 550px) {
            justify-content: center;
            align-items: center;
          }
        
          a {
            text-decoration: none;
            // color: var(--color-TEXT);
            // color: var(--color-skyblue);
            p {
              color: white;
              opacity:0.7;
            }
          }
        }
        
        .felt__list-item {
          display: flex;
          flex-direction: column;
          width: 220px;
          // width: 100%;
          height: 160px;
          font-weight: 100;
          text-align: center;
          @media screen and (max-width: 550px) {
            width: 100%;
            height: auto;
          }
        
          
          .felt-thumbnail {
            width: 100%;
            height: 100px;
            border-radius: 14px;
            // box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0);
            transition: transform 90ms ease-in-out, box-shadow 90ms ease-in-out;
            box-shadow: inset 0 -6px 0px 0px rgba(1, 1, 104, 0.271);
  
            img {
              width: 100%;
              height: auto;
              padding-bottom: 20px;
            }
          }
        
          p {
            padding-top: 10px;
            margin: 0;
          }
        
          span {
            text-transform: capitalize;
          }
          &:hover {
            p {
              color: white;
              opacity: 1;
            }
        
            .felt-thumbnail {
              transform: scale(1.04);
  
              // box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
            }
        
            cursor: pointer;
            @media screen and (max-width: 550px) {
              .felt-thumbnail {
                transform: scale(1);
              }
            }
          }
        }
        
        .felt__list-item--create {
          button {
            font-size: 40px;
            width: 220px;
            min-height: 100px;
            // width: 100%;
            border: none;
            border-radius: 14px;
            background-color: #159ff4;
            color: white;
            font-size: 80px;
            margin: 0 auto;
            line-height: 10px;
            transition: transform 90ms ease-in-out, box-shadow 90ms ease-in-out,
            color 90ms ease-in-out, background-color 90ms ease-in-out;
            box-shadow: inset 0 -6px 0px 0px rgba(1, 1, 104, 0.271);
  
            @media screen and (max-width: 550px) {
              width: 100%;
              // max-width: 380px;
              min-height: 60px;
              font-size: 40px;
            }
          }
        
          p {
            text-align: center;
            // padding: 15px 10px;
            width: 100%;
          }
          &:hover {
            button {
              background-color: #159ff4;
              color: white;
              transform: scale(1.04);
            }
          }
        }
        .felt__list-item--placeholder--container{
          width: 220px;
          min-height: 100px;
          .felt__list-item--placeholder{
            width: 220px;
            min-height: 100px;
            background-color: rgba(0, 0, 0, 0.262);
            border-radius: 14px;
            box-shadow: inset 0 4px 6px 0 rgba(0, 0, 0, 0.12);
          }
        }
      }
    
    }
  }
  
  
  .removeScroll {
    overflow-y: hidden;
  }
  
  .addScroll {
    overflow-y: scroll;
  }
  
  
  .explainTerm {
    margin: 0 auto;
    margin-bottom: 40px;
    color: rgb(85, 85, 85);
    padding: 0 30px;
  }