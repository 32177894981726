.img-hor-vert {
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

body {
  &[lang='he'] {
    .animals-carousel-container__step1, .animals-carousel-container__step2 {
      img {
          transform: scaleX(-1);
        }
    }
    .animals-carousel-container__step2 {
      img {
            transform: scaleX(1) !important;
          }
    }
    .step-3-animals {
      transform: scaleX(-1);
      img {
        transform: scaleX(-1);
      }
      .img-hor-vert {
        transform: scaleX(1);
      }
    }
  }
}

.relationship-wizard-page {
  display: flex;
  transition: background-color 0.2s ease;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  height: 100%;
  .dropdown-container {
    width: 300px;
    align-self: center;
    margin-top: 5px;

    span {        
      display: flex;
      margin-bottom: 5px;
    }
    .Dropdown-control {
      cursor: pointer;
      background: transparent;
      border-radius: 3px;
      color: white;
      background-color: rgba(255,255,255,0.1);
    }
  }
  img {
    @media screen and (max-width: 500px) {
      width: 50vw;
    }
  }
  .animals-carousel__side-b img {
    transform: scaleX(-1);
  }
  .step-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    padding-bottom: 100px;
    height: 100%;
    min-height: 100vh;
    .step-3-animals__button {
      max-width: 300px;
      min-width: 300px;
      border-radius: 100px;
      margin: 0 auto;
      height: 80px;

    }
    // .back-button {
    //   position: absolute;
    //   left: 10px;
    //   width: 60px;
    //   min-width: 60px;
    //   max-width: 60px;
    //   min-height: 60px;
    //   max-height: 60px;
    //   height: 60px;
    //   top: 5px;
    //   border: none;
    //   z-index: 999;
    //   background-color: rgba(0,0,0,0.1);
    // }
    .back-button {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
      font-size: 18px !important; 
      padding-bottom: 5px;
      text-decoration: none !important; ;
      color: #603B17;
      display: flex;
      align-items: center;
      background-color: white;
      width: 53px;
      max-width: 53px;
      min-width: 53px;
      height: 53px;
      max-height: 53px;
      min-height: 53px;
      border-radius: 53px;
      justify-content: center;
      border: 1px solid rgba(0,0,0,0.11);
      z-index: 99999;
      .left-chevron {
        line-height: 20px;
        font-size: 40px !important;
        font-weight: 100 !important; 
      }
    }
    .title { 
      padding: 0 20px;
      margin: 0 auto;
      margin-top: 10px;
      max-width: 580px;
      text-align: center;
      h1 {
        margin: 10px;
        font-size: 26px;
        text-align: center;
      }
      h2 {
        margin: 0;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 100;
        text-align: center;
      }
    }
    .animals-carousel-container {
      width: 100%;
      margin: 0 auto;
      cursor: pointer;
      
      .swiper-wrapper{
        
        img {
          padding-bottom: 20px;
          padding-top: 20px;
        }
      } 
      .swiper-slide-active {
        border-radius: 20px;
        // background-color: rgba(255,255,255,0.08)
        background-color: #fff;

      }
      
      .slick-arrow {
        position: fixed;
        z-index: 2;
        top: 250px;
      }
      .slick-prev {
        left: 5%;
      }
      .slick-next {
        right: 5%;
      }
    }
    .details {
      margin: 0 auto;
      padding: 0 20px;
      max-width: 336px;
      min-height: 250px;
      input {
        margin-bottom: 10px;
      }
      label {
        margin-top: 20px;
      }
      button {
        margin-top: 30px;
        height: 80px;
        border-radius: 100px;
        width: 100%;
        &:active {
          // background-color : red !important;
        }
      }
      .radio-3-horizontal {
      }
      .step-3-animals {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      
    }
  }
}

