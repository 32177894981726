:root {
    --keyboard-key-color: rgb(24, 24, 24);;
}

.questGame__buttons {
    border-top: 1px solid rgba(255,255,255,0.2);
    color: white;
    height: var(--questGame__buttons--height);
    display: flex;
    flex-direction: row-reverse;
    // flex-wrap: wrap;
    // align-items: center;
    // justify-content: center;
    padding: 0 40px;
    .questGame__buttons--numbers {
        width: 100%;
        // background-color: yellow;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .questGame__buttons--row {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
        }
        .questGame__buttons--row-A{
        }
        .questGame__buttons--row-B {
        }

    }
    .questGame__buttons--actions {
        width: 100%;
        // background-color: yellowgreen;
        max-width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .buttonKey {
        border-radius: 10px;
        min-width: 0;
        width: 100px;
        height: 100px;
        font-size: 50px;
        background-color: var(--keyboard-key-color);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--questGame__color--object);
        margin-bottom: 10px;
        margin: 10px;
        &:active {
            background-color: var(--questGame__color--object);
            color: var(--keyboard-key-color);
        }
        &:after {
            border-radius: 10px;
        
        }
    }
    .buttonKey__XL {
        width: 150px;
        height: 150px;
        padding-top: 30px;
        font-size: 80px;
    }
}
