.Catbears_Register {
    width: 100%;
    max-width: 350px;
    z-index: 9999;
    margin-top: 40px;
    .register-label {
        font-size: 20px;
        color: white;
    }
    .error {
        color:rgb(211, 31, 31);
        background-color: rgba(255, 219, 219, 0.575);
        padding: 10px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 500;
    }
    .register-btn {
        width: 100%;
        min-width: 100%;
        margin-top: 10px;
        margin-bottom: 40px;

    }
    .below-register-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        .register-skip-btn {
            font-size: 22px;
            color: white;
            text-align: center;
            text-decoration: underline;
        }
        .register_already-has-account {
            font-size: 22px;
            color: white;
            text-align: center;
            a {
                text-decoration: underline;
            }
        }
        
    }
}

