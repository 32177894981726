.rating-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 450px;
    margin: 0 auto;
  }
  
  .rating-title {
    font-size: 42px;
    text-align: center;
    padding-bottom: 60px;
  }
  
  .rating-container__buttons {
    display: flex;
    gap: 10px;
    .rating-button {
      width: 80px;
      height: 80px;
    }
  }

  .rating-container__texts {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    width: 100%;
    text-transform: capitalize;
    font-size: 23px;
    .rating-text {

    }
  }

  @media screen and (max-width: 768px) {
    .rating-container {
      max-width: 375px;

    }
    .rating-container__buttons {
      display: flex;
      gap: 8px;
      .rating-button {
        width: 60px;
        height: 60px;
      }
    }
  }