:root {
    --ContentWrapperBlock-maxWidth : 100%;
    --ContentWrapperBlock-padding: 0;
    --ContentWrapperBlock-paddingTop: 0;

}


.ContentWrapperBlock-container {
    width: 100%;
    height: 100vh;
    // min-height:100%;
    padding: var(--ContentWrapperBlock-padding);
    display: flex;
    flex-direction: column;
    max-width: var(--ContentWrapperBlock-maxWidth);
    // min-height: calc(var(--vh, 1vh) * 100); 
    padding-top: var(--ContentWrapperBlock-paddingTop);
    .ContentWrapperBlock-flex-row {
        display: flex;
        flex-direction: row;
        gap:40px;
        width: 100%;
        min-height:100%;
        @media screen and (max-width: 930px) {
            flex-direction: column;
        }
        div {
            width: 100%;
        }
    }
    .ContentWrapperBlock-flex-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap:40px;
        width: 100%;
        min-height:100%;
        div {
            width: 100%;
        }
    }
}

.ContentWrapperBlock-container_scroll {
    overflow-y: scroll;
    background-color: blue;
    max-height: calc(var(--vh, 1vh) * 100); 
    min-height: auto;
}

.ContentWrapperBlock-container_verticalCenter {
    align-items: center;
    justify-content: center;
}
.ContentWrapperBlock-video-container {
    height: 100%;
    width: 100%;
    max-width: 850px;
    min-width: 100%;
    min-height: 100%;
    position: relative;
    @media screen and (max-height: 700px) {
        max-width: 650px;
    }
    #teamwork-player {
        height: 100% !important;
        overflow: hidden;
        width: 100%!important;
        max-width:100%;
        border-radius: 30px;
        min-width: 100%;
        min-height: 100%;
        video {
            object-fit: cover;
        }
    }
    img{
        width: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 30px;
    }

}