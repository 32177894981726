body {
    &[lang="he"] {
        .FeltLogo__symbol--container {
            // direction: ltr;
            flex-direction: row-reverse;
            justify-content: flex-end;
          }
        }
  }
.FeltLogo__symbol--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .FeltLogo__symbol {
        width: 50px;
        height: 50px;
        margin-right: 8px;
    }
    .top-navbar--logo {
        font-size: 28px;
        font-family: 'Chelsea Market', 'avenier', 'helvetica', 'ariel', san-serif;
        // color: rgb(255, 153, 0);
        padding: 0;
        margin: 0;
    }
    
}
.large-logo {
    font-size: 60px;
}

.logo-top-left_s {
    position: absolute;
    top: 10px;
    left: 10px;
    .FeltLogo__symbol {
        width:40px;
        height: 40px;
    }
    .top-navbar--logo {
        font-size: 26px;
    }
    
}
