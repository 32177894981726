.courseCard {
    padding: 30px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    max-width: 350px;
    min-width: auto;
    width: 100%;
    position: relative;
    .card-start {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap:20px;
      height: 100%;
      justify-content: space-between;
      .card-start-text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h1 {
          text-align: center;
          font-size: 30px;
          font-weight:500;
          margin:0;
          padding:0;
        }
        .lessonsCount {
          margin-top: -6px;
          text-align: center;
          font-weight: 500;
          margin-bottom: 10px;
          font-size: 19px;

        }
        h2 {
          font-size: 17px;
          font-weight: 400;
          margin:0;
          padding:0;
        }
      }
      .course_button {
        box-shadow: none;
        width: 100%;
      }
      .courseButtonDisabled {
        background-color: rgba(0, 0, 0, 0.178);
        color: rgba(0, 0, 0, 0.359);
      }
    }
    .card-end {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      .courseImg {
        width: 300px;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
  .courseCardDisabled {
    background-color : rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.7);
  }