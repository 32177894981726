:root {
  --thumb_mySideColor : black;
  --thumb_partnerColor : black;
}


body {
  &[lang="he"] {
    .felt-thumbnail {
      direction: rtl;
      img{
        transform: scaleX(-1);
      }
      div + div {
        img {
          transform: scaleX(1) !important;
        }
      }
    }
  }
}
.felt-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(
    132deg, 
    var(--thumb_mySideColor) 58%, 
    var(--thumb_partnerColor) 50%);
  img {
    width: 30px;
    height: 30px;
  }
  div + div {
    img {
      transform: scaleX(-1);
    }
  }
  .felt-thumbnail__name {
    display: flex;
    flex-direction: column;
    align-items: center;
    // color: white;
    color: white;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 20px;
  }
  .felt-thumbnail__name--relation {
    font-size: 13px;
    opacity: 0.65;
  }
  
}
