body {
  &[lang="he"] {
    .cardGallery {
      direction: ltr;
    }
    .card__target-and-perfix {
      direction: rtl;
    }
  }
}
.cardGallery__container {
  display: flex;
  flex-direction: row;
  direction: ltr;
}

.cardGallery {
  display: flex;
  position: relative;
  flex-direction: row;
  overflow-x : scroll;
  padding: 30px;
  justify-content: left;
  width: 535px; 
  margin-top: 10px;
  padding-top: 10px;
  // transform: scale(1.2);
  @media screen and (max-width: 590px) {
    justify-content: left;
    width: 100vw;
  }
  // .card {
  //   &:last-child {
  //     // margin-right:0px;
  //     margin-right:auto;
  //     position: relative;
  //     animation: fade-in 500ms ease-in-out !important;

  //     &:after {
  //       content: "a";
  //       font-size: 1px;
  //       color: rgba(0,0,0,0);
  //       width: 100px;
  //       margin-right: -60px;
  //     }
  //   }
  // }
}
// .card {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   min-width: 100px;
//   max-width: 100px;
//   width: 100px;
//   height: 136px;
//   max-height: 136px;
//   min-height: 136px;
//   font-weight: 700;
//   // text-transform: uppercase;
//   background: white;
//   margin-right: 10px;
//   color: black;
//   border-radius: 12px;
//   font-size: 12px;
//   // box-shadow: 0px 1px 1px rgba(0,0,0,0.25);
//   box-shadow: inset 0px -4px 0px rgba(0,0,0,0.09), 0px 1px 4px rgba(0,0,0,0.1);
//   border: 1px solid #6C6C6C;
//   cursor: pointer;

//   &__emoji {
//     font-size: 40px;
//   }
//   .card__not , .card__a-little{ 
//     position: absolute;
//     font-size: 27px;
//     font-weight: 100;
//     right: 10px;
//     bottom: 50px;
//     transform: rotate(8deg);
//     font-weight: 900;
//     // font-family: david;
//     color:white;
//   }
//   .card__not--stroke , .card__a-little--stroke{ 
//     position: absolute;
//     font-size: 27px;
//     font-weight: 100;
//     right: 10px;
//     bottom: 50px;
//     transform: rotate(8deg);
//     font-weight: 900;
//     // font-family: david;
//     color: white;
//     text-shadow: 0px 2px 3px rgba(0,0,0,0.25);
//     // -webkit-text-fill-color: rgb(205, 19, 19); /* Will override color (regardless of order) */
//     -webkit-text-stroke-width: 3px;
//     -webkit-text-stroke-color: rgb(0, 0, 0);
//   }

//   .card__a-little, .card__a-little--stroke{
//     font-size: 20px;
//     bottom: 55px;
//   }
//   .card__a-little--stroke {
//     -webkit-text-stroke-width: 3px;
//   }

//   .card__target-and-perfix {
//     display: flex;
//     justify-content: space-between;
//   }

//   transition: transform 90ms ease-in-out, box-shadow 90ms ease-in-out;
//   @media screen and (min-width: 768px) {
//     &:hover {    
//       transform: scale(1.08);
//       box-shadow: inset 0px -4px 0px rgba(0,0,0,0.09), 0px 5px 12px rgba(0,0,0,0.2);
//     }
//   }
// }
// .card-placeholder {
//   background-color: rgba(0,0,0,0.05);
//   color: rgba(0,0,0,0.18);
//   box-shadow: none;
//   font-size: 40px;
//   font-weight: 100;
//   transition: background-color 90ms ease-in-out, color 90ms ease-in-out;
//   border: 1px solid rgba(0,0,0,0.14);
//   &:hover {
//     transform: scale(1);
//     background-color: rgba(0,0,0,0.1);
//     // color: rgba(255,255,255,0.95);
//     box-shadow: 0px 1px 1px rgba(0,0,0,0);
//   }
// }
// .card__source-name {
//   font-size: 12px;
//   font-weight: 100;
//   padding-bottom: 5px;
//   color: #888;
// }
// .card__emoji {
//   font-size: 54px;
//   // margin-top:-5px;
// }
// .card__title  {
//   font-size: 13px;
//   font-weight: 600;
//   color: #111;
//   margin-bottom: 2px;
//   text-align: center;
//   max-width: 86px;
// }
// .card__target-name, .card__prefix {
//   font-size: 12px;
//   font-weight: 100;
//   padding-top: 0px;
//   color: #888;
// }

.swiper-button-next , .swiper-button-prev {
  transform: scale(0.7);
  background-color: #ffffffeb;
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
