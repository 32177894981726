.welcome-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    .welcome-top {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            font-size: 24px;
            text-align: center;
        }
    }
    .welcome-center {

    }
    .welcome-bottom {
        opacity: 0.6;
    }
}
