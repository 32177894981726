body {
  &[lang="he"] {
    .card-keyboard .card-keyboard__filters .card-keyboard__filters--tabs {
      div {

        &:first-child {
          
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;   
        }
        &:nth-last-child(2) { 
          border-left: 1px solid #575656;
          border-right: 1px solid #575656 !important;
          border-right: none;
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px; 
        }
        &:last-child {
          padding-right: 20px;
          padding-left: 0px;
        }
      }
    }
  }
}

.card-keyboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  &:after {
    background-color: rgba(0,0,0,0.05);
    // width: 500px;
    height: 100%;
    content: " ";
    position: fixed;
    z-index: -1;
  }
  // display: flex;
  .card-keyboard__filters {
    // position: sticky;
    top: 0;
    z-index: 14;
    width: 100%;
    padding: 10px 0;
    background-color: rgba(0,0,0,0.3);
    .card-keyboard__filters--tabs {
      max-width: 700px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      line-height: 10px;
      padding: 0 20px;
      color: white;
      div {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        background-color: #353535d9;
        font-size: 14px;
        text-align: center;
        display: flex;
        width: 100%;
        cursor: pointer;
        max-width: 200px;
        height: 100%;
        min-height: 50px;
        border-right: 1px solid #575656;
        border-top: 1px solid #575656;
        border-bottom: 1px solid #575656;
        &:first-child {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
        &:nth-last-child(2) { 
          border-right: 1px solid #575656;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
        &:last-child {
          max-width: 40px;
          padding-left: 20px;
          border: none;
          background-color: rgba(0, 0, 0, 0);
          font-size: 20px;
          opacity: 0.8;
          &:hover {
            background-color: rgba(255, 255, 255, 0);
            opacity: 1;
          }
        }
        &:hover {
          background-color: #4f4f4fd9;
          
        }
      }
      .selected {
        color: black;
        background-color : white;
        &:hover {
          background-color : white;
        }
      }
    }
  } 
  .card-keyboard__title {
    text-align: center;
    color: white;
  }
  .card-keyboard__cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 660px;
    padding: 0 2px;
    padding-top: 10px;
    margin: 0 auto;
    padding-bottom: 90px;
    overflow-y: scroll;
    gap: 12px;
    .card {
      border-radius: 10px;
      margin-right: 1px;
      margin-left: 1px;
      margin-bottom: 12px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      justify-self: left;
      &__emoji {
        font-size: 40px;
      }
      &:hover {
        background-color: white;
        // transform: scale(1.07);
      }
    }
    

    
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.card-keyboard::-webkit-scrollbar {
  display: none !important;
}


.MuiDrawer-root, .MuiDrawer-modal , .MuiPaper-root ,.MuiPaper-elevation16 ,.MuiDrawer-paper ,.MuiDrawer-paperAnchorBottom{
  z-index: 50 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.MuiDrawer-paperAnchorBottom {
  height: 56vh !important;
  outline: 1px solid #ccc;
  background: rgba(0, 0, 0, 0.7) !important;
  right: none !important;
  bottom: -10px !important;
}
.MuiBackdrop-root {
  background: none !important;
}