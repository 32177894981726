
body {
    &[lang="he"] {
        .teamwork .Lesson_Instructor--component{
            left: -80px;
            right: auto;
            .Lesson_Instructor--animal {
                transform: scaleX(1);
            }

      }
    }
  }


.teamwork .Lesson_Instructor--component{
    right: -80px;
    left: auto;
    .Lesson_Instructor--animal {
        transform: scaleX(-1);
    }
}
.teamwork-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
    .teamwork-notes {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: auto;
        flex-wrap: wrap;
        width: 100%;
        gap: 30px;
        
    }
    .info-cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: auto;
        height: 100%;
        gap:10px;
        justify-content: center;
        align-items: stretch;
        @media screen and (max-width: 768px) {
            gap: 20px;
        }
    }
}
