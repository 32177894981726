.teamworkSkillCard{
    width: fit-content;
    padding: 20px;
    margin-bottom: 22px;
    padding-inline-end: 46px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    max-width: 900px;
    @media screen and (max-width: 768px) {padding: 10px; padding-inline-end: 24px; width: 100%;margin-bottom: 5px;    max-width: var(--teamworkCardWidth);
    }
    .teamworkSkillCard_start{
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100px;
            @media screen and (max-width: 768px) {max-width: 80px;}
        }
    }
    .teamworkSkillCard_end {
        padding-top: 8px;
        display: flex;
        gap: 4px;
        flex-direction: column;
        .teamworkSkillCard_name {
            font-size: 38px;
            font-weight: 600;
            @media screen and (max-width: 768px) {font-size: 24px;}
        }
        .teamworkSkillCard_todo {
            font-size: 26px;
            font-weight: 300;
            padding-bottom: 10px;
            @media screen and (max-width: 768px) {font-size: 18px;}
        }
    }

    
}