.homepage {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: auto;
  background-color: var(--color-skyblue);
  .homepage-content {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding:100px 50px;
    gap: 60px;
  }
}