.Popup__content--feedback {
    padding : 20px;
    gap: 20px;
        .input-form {
            gap:12px;
            width: 100%;
            display: flex;
            flex-direction: row;
            // padding: 20px;
            justify-content: center;
            align-items: center;
            height: 100%;
            display: flex;
            flex-direction: column;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            .input-container {
                width: 100%;
                position: relative;
               
                .PopupInput {
                    width: 100%;
                    height: 54px;
                    margin:0!important;
                    color: black;
                    background-color: #fffdeb;
                    border: 2px solid #ffcf0f !important; /* Ensure the border is always applied */
                    outline: none;
                    border-radius: 10px !important;
                    font-size: 25px; /* Set font size */
                    box-sizing: border-box; /* Include padding/border in width/height */
                    height: 100%;
                    min-height: 240px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
            .todoPopupInput:focus {
                outline: none;
                border: 3px solid black; /* Ensure no style changes on focus */
            }
        }
}
.Popup__footer--feedback {
}
