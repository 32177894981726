body {
  &[lang="he"] {
    .summary__page {
      direction: rtl;
      .summary_card {
        margin-left: 10px;
        margin-right: 0px!important;
      }
    }
  }
}
.feltImg-container {
  margin: 20px 0;
  .html2canvas {
    position: fixed;
    top: -4000px;
    width: 100%;
    // background: blueviolet;
    max-width: 376px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
}

.feltImg{
  width: 100%;
  max-width: 376px;
  display: inline-block;
  max-height: 220px;
  border-radius: 20px;
  margin: 0 auto;
  img{
    width: 100%;
    max-width: 376px;
    height: auto;
  }
}

.summary--thumbnail {
  bottom: 0;
  width: 100%;
  max-width: 376px;
  border-radius: 20px;
  img {
    width: 100%;
    height: auto;
    max-height: 220px;
  }
}

.feltImgTemp {
  background: #e2e2e2;
  border-radius: 20px;
  width: 100%;
  height: 200px;
  max-width: 376px;
  display: inline-block;
}

.summary--thumbnail-names {
  margin: 0 auto;
  text-align: center;
}



.summary-section {
  border: 1px solid #e2e2e2;
  padding-top: 20px;
  margin: 40px auto;
  padding-bottom: 50px;
  // box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
}

.summary {
  width: 100%;
  .summary-container {
    max-width: 620px;
    margin: 0 auto;
    padding-top: 30px;
    margin-bottom: 100px;
    .summary__page {

      margin: 40px auto;

      .summary__header {
        text-align: center;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e2e2e2;
        .summary__title--names {
          margin:0;
        }
        .summary__title--mirrorName {
          margin:0;
        }
        .summary__title--date {
          margin:0;
          padding-top: 10px;
        }

      }
      .summary__journeyName {
        font-size: 27px;
        margin: 0;
        padding-top: 14px;
        padding-bottom: 10px;
        // border-top: 12px solid #e2e2e2;
        &:first-child {
          border: none;
        }
      }
      .summary__textArea {
        // border-right: 2px solid #e2e2e2;
        // border-left: 2px solid #e2e2e2;
        // padding-right: 10px;
        // padding-left: 10px;
      }
      .summary__step {
        padding-top: 10px;
        padding-bottom: 12px;
        border-bottom: 1px solid #e2e2e2;
        font-size: 20px;

        &:nth-child(odd){
          background-color: #f9f9f9;
        }
        &:first-child {
          border: none;
        }
        .summary__stepName {
          font-weight: 600;
          // color: #999;
          color: black;
          padding: 0;
          margin: 0;
          padding-top: 8px;
          padding-bottom: 10px;
          font-size: 16px;
          color: #535353;
        }
      }
      .summary-cards {
        width: auto;
        .summary_card {
          overflow-wrap: break-word;
          word-wrap: break-word;
          overflow: auto;
          // display: inline-block;
          padding: 1px 8px;
          // margin-right: 10px;
          margin-bottom: 8px;
          padding-bottom: 4px;
          border-radius: 8px;
          box-shadow: inset 0px -3px 0px rgba(0,0,0,0.07);
        }
        .card--cell {
          padding: 0 2px;
        }
        .card__emoji {
          font-size: 26px;
        }
        .card__modifier {
          // color: #999;
          // font-style: italic;
          // font-size: 15px;
        }
        .summary_card--feel {
          border: 1px solid #e0a9ff;
          background-color: #f5e3ff;
          box-shadow: inset 0px -3px 0px #ebc2ff;
        }
        .summary_card--need {
          border: 1px solid #e5b002;
          background-color: #fffde2;
          box-shadow: inset 0px -3px 0px #f9e69d;
        }
        .summary_card--happened {
          background-color: #e4ffdd;
          border: 1px solid #25bb2c;
          box-shadow: inset 0px -3px 0px #bef0b4;
        }
      }
      .summary-footer {
        text-align: center;
        p, a {
          font-size: 14px;
          padding:0;
          margin: 0;
          color: #818181;
          pointer-events: none;
        }
        p {
          // margin-bottom: 10px;
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

.copytoClipboard {
  display: flex;
  // width: 200px;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  button {
    margin: 0 10px;
    width: 60px;
    min-width: 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 30px;
    }
  }
}

