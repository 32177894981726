.thankyou-message {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .thankyou-message__img {
        width: 100%;
        max-width: 300px;
        padding-right: 40px;
    }
    .thankyou-message__text {
        font-size: 18px;
        width: 100%;
        max-width: 270px;
        text-align: center;
        margin: 0 auto;
        line-height: 24px;
        color: rgb(78, 78, 78);
    }
}