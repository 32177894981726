.input-feedback {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width:100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 20px;
    max-width: 600px;
    .input-feedback__label {
       margin-bottom: 0px;
    }
    .input-feedback__label-below {
        // text-align: center;
        font-size: 15px;
        color:rgb(125, 125, 125);
    }
    .labelBelow__label {
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
        font-size: 13px;
    }
    .labelBelow__answer {
        padding: 0;
        margin: 0;
        padding-bottom: 3px;
        padding-top: 3px;
        color: black;
        font-size: 13px;
    }
    .input-feedback__field {
        width: 100%;
        padding: 17px;
        margin-bottom: 20px;
        margin-top: 5px;
        position: relative;
        font-size: 30px;
        border-radius: 13px;
        font-family: 'Gaegu', 'Varela Round', sans-serif;
        letter-spacing: -1.7px;
        height: 30px;
        min-height: 200px;
        resize: vertical;
        color: black;
        background-color: white;
        border: 2px solid var(--color-light-gray);
        transition: background-color 200ms ease-in-out, border 200ms ease-in-out;
        outline: none;
    }
    
    .input-feedback__field:focus {
        background-color: var(--color-paper);
        border: 2px solid var(--color-paper-border);
    }
      
}


//   .textarea-container{
//     min-width: 100%;
//     max-width: 100%;
//     margin-bottom: 20px;
//     textarea {
//         transition: background-color 200ms ease-in-out, border 200ms ease-in-out;
//         background-color: var(--color-light-gray);
//         background-color: white;
//         border: 2px solid var(--color-light-gray);
//         font-size: 30px;
//         max-width: 100%;
//         min-width: 100%;
//         font-family: 'Gaegu', 'Varela Round', sans-serif;
//         letter-spacing: -1.7px;

//     }
//     textarea:focus {
//         background-color: var(--color-paper);
//         border: 2px solid var(--color-paper-border);
//         // border: 2px solid var(--color-light-gray);
//     }
// }
 