.side-selector {
  display: flex;
  transition: background-color 0.2s ease;
  justify-content: space-around;
  height: 70px;
}
.side-selector__pane {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  font-size: 13px;
  color: white;
  cursor: pointer;
  &:last-child {
    margin-left: -3%;
  }
  .name{
    margin-top: 6px;
  }  
  img {
    height: 100%;
    z-index: 2;
  }
  span{
    width: 100%;
    height: 100%;
    position: absolute;
    transform: perspective(195px) rotateX(10deg);  
    z-index: 1;
  }
  &.selected {
    z-index: 2;
  }
  &:first-child span{
    border-top-right-radius: 10px;
    transform-origin: bottom left;
    
  }
  &:last-child {
    img {
      transform: scaleX(-1)
    }
    span {
      border-top-left-radius: 10px;
      transform-origin: bottom right;
    }
  }
}
