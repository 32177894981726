.MeterDot {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10px 10px;
    // border: 1px solid #e2e2e2;
    padding: 10px;
    border-radius: 20px;
    max-width: 100px;
    width: 100px;
    min-height: 160px;
    transition: opacity 200ms ease-in-out;
    .MeterDot__emoji {
        transition: transform 300ms cubic-bezier(.81,1.95,.75,.54);
        font-size: 50px;
    }
    &:hover{
        cursor: pointer;
        opacity: 1;
        .MeterDot__emoji {
            
            transform: scale(1.3);
        }   
    }
    &:active{
        .MeterDot__emoji { 
            transition: transform 100ms cubic-bezier(.81,1.95,.75,.54);
            transform: scale(1.15);
        }  
    }
    .MeterDot__title {
        font-size: 16px;
    }
}

.MeterDot__selected {
    .MeterDot__emoji {
        transform: scale(1.3);
    }   
    
}

.MeterDot__faded {
    opacity: 0.4;
    
}