body {
    &[lang="he"] {
        .Step {
        h1 {
            direction: rtl;
            text-align: right;
        }
      }
    }
  }
  

.Step {
    text-align: left;
    min-width: 100%;
    height: auto;
    overflow: hidden;
    padding-bottom: 20px;
    padding-top: 20px;
}
   .stepNav-testing {
       display: flex;
       align-items: left;
       display: flex;
       flex-direction: column;
       position:fixed;
       top: 24px;
       right: 0;
       background-color: white;
       padding: 30px;
       margin: 20px;
       box-shadow: 0 4px 12px 1px rgba(0,0,0,0.3);
       p {
           margin: 0;
           padding: 0;
           margin-bottom: 3px;
       }
       .isTrue {
           color: rgb(42, 206, 9);
           font-weight: 900;
       } 
   }
   .buttons {
       margin-top: 50px;
       display: flex;
       flex-direction: column;
       button {
           margin-bottom: 20px;
       }
   }
.stepCompleted {
    // border: 2px solid rgb(183, 210, 183);
    // background-color: rgb(232, 255, 232);
}

.InviteEmail {
    .InviteEmail_title {
        // padding-top: 20px;
        padding-bottom: 30px;
        h2, h3 {
            text-align: center;
            max-width: 470px;
            margin: 0 auto;
        } 
        h2 {
            padding-bottom: 10px;
        }
    }
    .message-preview {
        border: 1px solid #ece6c7;
        
        background-color: #fefcf1;
        border-radius: 10px;
        margin: 0 16px;
        // margin: 0 auto;
        .message-preview_subject {
            border-bottom: 1px solid #ece6c7;
            margin: 0;
            padding: 20px;
            font-weight: 800;
        }
        .message-preview_message {
            margin: 0;
            padding: 0 20px;
        }
    }
    .privacy-reminder{
        font-weight: 800;
        text-align: center;
    }
}