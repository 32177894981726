
.ConflictSimulatorKeyboard--container {
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    padding-bottom: 120px;
    background-color: rgba(0, 0, 0, 0.346);
    z-index: 999999;
    .ConflictSimulatorKeyboard {
        z-index: 0;
        background-color: rgba(0, 0, 0, 0.653);
        border-radius: 20px;
        height: auto;
        
        max-width: 520px;
        width: auto;
        // max-width: 720px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: margin-bottom 150ms ease-in-out;
        margin-bottom: 0;
        .ConflictSimulatorKeyboard_card-keyboard__nav {
            border-radius: 20px 20px 0 0 ;
            height: 45px;
            min-height: 45px;
            background-color: black;
            width: 100%;
            display: flex;
            justify-content: space-around;
            .ConflictSimulatorKeyboard_card-keyboard__nav-center {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 19px;
                color: yellow;
            }
            .ConflictSimulatorKeyboard_card-keyboard__spacer {
                width: 100%;
                max-width: 45px;
            }
            .ConflictSimulatorKeyboard_card-keyboard__icon{
                width: 100%;
                max-width: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 30px;
                color: rgb(230, 230, 230);
                .icon__x {
                    transform: rotate(45deg);
                }
                &:hover {
                    cursor:pointer;
                    color: white;
                }
            }
        }
        .ConflictSimulatorKeyboard_card-keyboard__cards {
            max-width: 700px;
            height: auto;
            max-height: 400px;
            padding: 30px 42px;
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            overflow-y: scroll;
            justify-content: center;
            align-items: center;
            max-height: calc(var(--vh, 1vh) * 45);
        }
    }
    .ConflictSimulatorKeyboard-defaultPosition {
        margin-bottom: -270px;
        opacity: 0;
        display: none;
    }
}

.ConflictSimulatorNeeds__cards {
    padding: 22px 0;
    width: 100%;
    display: flex;
    gap: 12px;
}



@keyframes ANIMATION-slideFromBottom {
    0% { margin-bottom: -70px; opacity: 0; display: none; z-index: 0;}
    100% { margin-bottom: 0; opacity: 1; display: inline-block; z-index: 99999;}
  }
  
.ANIMATION-slideFromBottom {
    animation: ANIMATION-slideFromBottom 300ms forwards;
}

@keyframes ANIMATION-slideToBottom {
    0% { margin-bottom: 0; opacity: 1; display: inline-block;z-index: 99999;}
    100% { margin-bottom: -70px; opacity: 0; display: none;z-index: 0;}
  }
.ANIMATION-slideToBottom {
    animation: ANIMATION-slideToBottom 300ms forwards;
}

