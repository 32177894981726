:root {
    --circleScale : 0.8;
}

.circleProgress-container{
    // width: 100%;
    width: 240px;
    // margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    
    .circleProgress{
        position: relative;
        transform: scale(var(--circleScale));
        transition: transform 400ms cubic-bezier(.147,1.64,.41,.8);
        
        .CircleProgress__counter {
            position: absolute;
            width: 66px;
            height: 66px;
            z-index: 999;
            border-radius: 100%;
            color: white; 
            font-size: 18px;
            line-height: 52px;
            bottom: 10px;
            font-weight: 800;
            border: 6px white solid;
            text-shadow: 0px 2.5px rgba(0,0,0,0.14);
            // background-color: red;
        }
        .CircleProgress__counter__mySide{
            background-color: var(--mySideColor);
        }
        .CircleProgress__counter__partnerSide{
            background-color: var(--partnerColor);
        }
        .CircleProgress__counter__complete{
            background-color: var(--color-gold);
        }
        .CircleProgress__insideCircle{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 80px;
            z-index: 99;
            display: flex;
            min-width: 130px;
            min-height: 130px;
            // position: relative;
            img{
                min-width: 190px;
                min-height: 190px;
            }
            &::before{
                position: absolute;
                // z-index: 999999;
                width: 162px;
                height: 162px;
                border-radius: 100%;
                content: "";
                mix-blend-mode: color;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                box-shadow: inset 0 7px 1px 0 rgba(0,0,0,0.05);
                // background-color: red;
            }
        }
        .CircleProgress__insideCircle__mySide {
            &::before{
                background-color: var(--mySideColor);
            }
        }
        .CircleProgress__insideCircle__partnerSide {
            &::before{
                background-color: var(--partnerColor);
            }
        }
        
        // .CircleProgress__insideCircle__complete {
        //     &::before{
        //         background-color: var(--color-gold);
        //     }
        // }

        .svg-container {
            margin: 0 auto;
            position: relative;
            width: 200px;
            height: 200px;
            border-radius: 100%;
            // transform: scale(0.90);
            svg {
                position: absolute;
                width: 200px;
                height: 200px;
                position: absolute;
                left: 50%;
                margin-left: -100px;
                top: 50%;
                margin-top: -100px;
                
                .inner-Circle {
                    
                    // fill: var(--color-feltKit);
                    // fill: var(--backgroundColor);
                    fill: white;
                    // opacity:0;
                }
                .CircleProgressBar {
                    fill: black;       
                }
                .CircleProgressBar__mySide {
                    fill: var(--mySideColor);
                }
                .CircleProgressBar__partnerSide {
                    fill: var(--partnerColor)
                }
                .CircleProgressBarBG {
                    stroke: rgba(0,0,0,0.2);
                    // stroke: var(--color-feltKit);
                }
                .progress100 {
                    fill: var(--color-gold);
                }
            }
            
            #range {
                display: block;
            }
        }
        .svg-container__selected {
            box-shadow: 0 0 6px 12px white;
            animation: current 2400ms infinite;
            
            svg .inner-Circle {
                fill: white;
            } 
        }
        
    } 
    
    .circleProgress__textBelowProgressCircle {
        padding: 0 10px;
        color: black;
        margin-top: 10px;
        font-size: 20px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        z-index: 2;
    } 
    &:hover{
        .circleProgress{
            // transform: scale(calc(var(--circleScale) + 0.1));
            transform: scale(calc((var(--circleScale) + 0.2)));

        }
    } 
}
.circleProgressSelected {
    .circleProgress{
        transform: scale(0.93);
    }
    // &:hover {
        //     .circleProgress{
            //         .svg-container__selected {
                //             box-shadow: 0 0 6px 12px white;
                //             animation: none;
                //         }
                // }
            }
.circleProgressDisabled {
    pointer-events: none;
    .circleProgress{
        .CircleProgress__insideCircle__mySide , .CircleProgress__insideCircle__partnerSide {
            &::before{
                background-color: #e2e2e2 !important;
                box-shadow: none;
            }
        }
        .CircleProgress__counter {
            display: none;
            // background-color: #e2e2e2;
            // text-shadow: none;
        }
    }
}



@keyframes current {
    0% {
        
        transform: scale(1);
        box-shadow: 0 0 6px 12px rgba(255,255,255,0);
    }
    50% {
        transform: scale(1.07);
        box-shadow: 0 0 6px 12px rgba(255,255,255,0.6);
        
    }
    100% {
        transform: scale(1);
        // transform: scale(var(--circleScale));
        box-shadow: 0 0 6px 12px rgba(255,255,255,0);
    }
  }
