:root {
    --progressPrecentage: 0;
    --progressBar--progress-color: var(--color-green-success);
    --progressBar--empty-color: blue;
}

body {
    &[lang="he"] {
        .progressBar__container{
            .progressBar {
                .progressBar__numbers{
                    padding-left: 0px;
                    padding-right: 10px;
                }

            }
        }
  
    }
  }

.progressBar__container {
    width: 100%;
    display:flex;
    .progressBar{
        width: 100%;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        .progressBar__bar {
            // background-color: white;
            background-color: var(--progressBar--empty-color);
            // border: 1px solid #a2a2a2;
            width: 100%;
            height: 100%;
            border-radius: 50px;
            .progressBar__bar--progress {
                background-color: var(--progressBar--progress-color);
                width: var(--progressPrecentage);
                height: 100%;
                border-radius: 50px;
                // border: 2px solid white;
                transition: width 600ms ease-in-out;
                // box-shadow: inset 0px -2px 0px #01a201;
            }
            .progressBar__bar--progress__mySideColor {
                background-color: var(--mySideColor)
            }
            .progressBar__bar--progress__partnerColor {
                background-color: var(--partnerColor)
            }
        }
        .progressBar__numbers{
            width: auto;
            padding-top: 8px;
            padding-left: 10px;
            color: var(--color-TEXT);
            height: 100%;
            line-height: 2px;
            text-align: left;
        }
    }
}

@media screen and (max-width: 768px) {
    .progressBar{
        // width: 230px;
    }

}