.relationship-user-info__title {
    padding:0;
    margin:0;
    padding-left: 18px;
    border-bottom: 1px solid rgb(202, 202, 202);
    width: 100%;
    padding-bottom: 20px;
    font-size: 24px;
}

.relationship-user-info-container {
    display: flex;
    flex-direction: row;
    align-items: start;
    // padding-bottom: 100px;
    // background-color: red;
    //     min-height: 100vh;
    //     height: 100vh;
    @media screen and (max-width: 768px){
        flex-direction: column;
    }
    .relationships-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-right: 1px solid rgb(208, 204, 204);   
        padding-top: 20px; 
        border-bottom: 1px solid rgb(202, 202, 202);
        
        align-items: start;
        // padding-right: 8px;
        @media screen and (max-width: 768px){
            overflow-y: visible;
        }
        .relationships-list {
            
            display: flex;
            flex-direction: column-reverse;
            overflow-y: scroll;
            max-height: 100vh;
            height: auto;
            width: 100%;
            
            // @media screen and (max-width: 768px){
            //     overflow-y: visible;
            // }
            .relationship-item {
                display: flex;
                flex-direction: column;
                align-items: left;
                // margin: 10px;
                height: auto;
                padding: 20px;
                border-bottom: 1px solid #a2a2a2;
                width: 100%;
                min-width: 400px;
                // box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.066);
                .relationship-item__title {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    gap:10px;
                    .relationship-item__title--num {
                        max-width: auto;
                    }
                    .relationship-item__title--text {
                        width: 100%;
                        font-size: 20px;
                    }
                }
                .relationship_sides {
                    background-color: rgb(243, 243, 243);
                    display: flex;
                    // flex-direction: row;
                    width: 100%;
                    justify-content: center;
                    padding-bottom: 10px;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    margin-top: 10px;
                    .relationship_sides--animal {
                        width: 130px;
                    }
                    .relationship_sides__side2--animal {
                        transform: scaleX(-1);
                    }
                    .relationship_sides--name {
                        text-align: center;
                    }
                }
                p {
                  font-size: 12px;
                  margin: 5px 0;
                  padding: 0;
                }
                .conflict-list {
                    list-style: none;
                    padding: 0;
                    width: 100%;
                    .conflict-item {
                        display: flex;
                        flex-direction: row;
                        gap: 5px;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 5px; 
                        padding: 8px;
                        padding: 8px 12px;
                        margin: 0;
                        width: 100%;
                        border: 1px solid #e2e2e2;
                        border-radius: 10px;
                        margin: 10px 0;
                        max-width: 100%;
                        padding-bottom: 10px;
                        background-color: rgb(251, 251, 255);
                        transition: color 70ms ease-in-out, background-color 70ms ease-in-out, border 70ms ease-in-out;
                        span {
                            padding: 0;
                            margin: 0;
                        }            
                        .arrow-button {
                            width: 25px;
                            min-width: 25px;
                            height: 25px;
                            min-height: 25px;
                            margin-left: 10px;
                            background-color: transparent;
                            border: none;
                            cursor: pointer;
                            // background: #ade1ff;
                            border: 1px solid #a3b2bb;
                            color: #a3b2bb;
                            border-radius: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;                    
                        }
                        .conflict-item__num {
                            text-align: left;
                            padding: 0px 5px;
                        }
                        &:hover {
                            color: var(--color-skyblue);
                            cursor:pointer;
                            background-color: #f1f9ff;
                            .arrow-button {
                                color: var(--color-skyblue);
                                border: 1px solid var(--color-skyblue);
                            }
                            border: 1px solid var(--color-skyblue);
                        }
                        &:active { 
                            color: rgb(0, 0, 172);
                        }
                        &.selected {
                            background-color: var(--color-skyblue);
                            color: white;
                            .arrow-button {
                                color: white;
                                border: 1px solid white;
                            }
                        }
                      }
                }
              }
        }
    }
    .conflict-container {
        width: 100%;
        padding: 0 20px;
        padding-top: 20px;
        max-height: 100vh;
        overflow-y: scroll;
        // margin-bottom: 100px;
        // background-color: red;
        // height: auto;
        // min-height: 100%;
        @media screen and (max-width: 768px){
            overflow-y: visible;
        }
        .conflict__title {
            padding:0;
            margin:0;
            width: 100%;
            padding-bottom: 10px;
            font-size: 24px;
        }
        .summary__page {
            padding-top: 0;
            margin-top: 10px;
            
            border-radius: 8px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.066);
          }
        }
   
  }


  
 
  
 
  
 
  

 