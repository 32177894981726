

.LogoCatbears-container {
  position: fixed;
  z-index: 999;
  top: 0;
  inset-inline-end: 0;
  display: flex;
  padding: 4px;
  padding-bottom: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  &[lang='he'] {
    right: 0;
    left: auto;
    flex-direction: row-reverse;  
  }
  .toggle-switch {
    padding: 4px;
    z-index: 999;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .catbears-logo {
    width: 140px;
    height: auto;
    display: block;
    object-fit: contain; /* Ensure the image scales properly */
    @media screen and (max-width: 768px) {
      // display: none;
      width: 70px;
    }
  }
}

.LogoCatbears-container__centered {
    justify-content: center;
    display: flex;
    inset-inline-end: 50%;
    inset-inline-start: 50%;
    transform: translate(50%);

}

.hideLogoOnMobile {
  @media screen and (max-width: 768px) {
    display: none;
  }
}