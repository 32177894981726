:root {
    --progressPrecentage: 0;
}

body {
    &[lang="he"] {
        .progressDots__container{
            .progressDots {
                .progressDots__numbers{
                    padding-left: 0px;
                    padding-right: 10px;
                }

            }
        }
  
    }
  }

.progressDots__container {
    width: 100%;
    width: auto;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;

}