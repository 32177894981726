.map-relationship-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  // transition: background-color 0.2s ease;
  img.partnerSide {
    transform: scaleX(-1);
  }
  h4 {
    margin-bottom: 5px;
    font-weight: 100;
  }
}
body {
  &[lang="he"] {
    .side-selector {
      direction: ltr;
    }
  }
}

.map-relation__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin: 0 auto;
  &:last-child {
    height: 80vh;
  }
  h4 {
    font-size: 22px;
    font-weight: 300;
    color: white;
    margin-bottom: 0;
  }
}
