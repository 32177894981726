
.list-container {
    width: 100%;
    gap: 340px;
    max-width: 500px;
    margin: 0 auto;
    .list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 20px;
        padding: 16px;
        margin-bottom: 10px;
        min-width: 100%;
        &:hover {
            transform:scale(1);
            .list-item__icon {
                &:after{
                    background: rgba(0, 0, 0, 0.151);
                }
            }
        }
        &.selected {
            .list-item__icon {
                border: 1px solid var(--color-primary);
                &:after{
                    background: var(--color-primary);
                }
            }
        }
        .list-item__icon {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            border: 1px solid gray;
            border-radius: 100%;
            margin-left: 10px;
            transition: background-color 0.2s ease-in-out;
            justify-content: center;
            align-items: center;
            position: relative;
            display: flex;
            &:after{
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                content: "";
                background: none;
            }
        }
        
    }
}



.list-container__icon-v {
    .list-item {
        .list-item__icon{
            border: none;
            width: 32px;
            height: 32px;
            background-color: rgb(230, 230, 230);
            box-shadow: inset 0px 3px 0 0 rgba(0, 0, 0, 0.105);
            &:after{
                display: none;
            }
        }
        &.selected {
            .list-item__icon {
                border: none;
                background-image: url("/assets/icons/v-green-32.png");
                background-size:cover;
                background-color: #fff;
                box-shadow: none;
            }
        }  
    }
}