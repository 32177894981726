:root {
    --LobbyFooterHeight: 130px;
    --LobbyMaxWidth: 1024px;
    --LobbyTeaserHeight: 500px;
    @media screen and (max-width: 768px) {
        --LobbyFooterHeight: 230px;
    }
}

body {
    &[lang="he"] {
        .Onboarding-button {
            flex-direction: row-reverse;
            img {
                transform: scaleX(-1);
                height: 30px;
            }
      }
    }
  }

.Onboarding-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.Onboarding-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--page-background-color);
    height: 100%;
    width: 100%;
    overflow: scroll;
    padding:20px 40px;
    height: 100vh;
    // max-height: 100vh;
    @media (max-width: 768px) {
        padding:30px 15px
    }
    // height:calc((var(--vh, 1vh)* 100 )  - var(--LobbyFooterHeight ) );
    // min-height: calc((var(--vh, 1vh)* 100 )  - var(--LobbyFooterHeight ) );
    .Onboarding-text-container {
        display: flex;
        flex-direction: column;
        max-width: 530px;
        padding-bottom: 30px;
        @media (max-width: 768px) { padding-bottom: 10px;padding-top: 30px;gap:0;}
    }
    .Onboarding-titleXL {
        z-index: 2;
        font-size: 65px; font-weight: 600;text-align: center;width: 100%;padding-top: 0px; color: rgb(255, 255, 255);
        @media (max-width: 768px) {font-size: 40px;padding-bottom: 10px;}
    }
    .Onboarding-title {
        z-index: 2;
        font-size: 42px; font-weight: 500;text-align: center;width: 100%;padding-top: 0px; color: rgb(255, 255, 255);
        @media (max-width: 768px) {font-size: 30px;padding-bottom: 10px;}
    }
    .Onboarding-subtitle { 
        z-index: 2;
        font-weight:500;white-space: pre-line;padding-top:3px;max-width:600px;font-size: 26px;text-align: center;width: 100%; padding-bottom: 20px;color: rgb(255, 255, 255);
        @media (max-width: 768px) {font-size: 18px; padding-bottom: 10px;}
    };
    .Onboarding-description { 
        z-index: 2;
        font-weight:400;white-space: pre-line;max-width:600px;font-size: 20px;text-align: center;width: 100%; padding-bottom: 20px;color: rgba(255, 255, 255, 0.765);
        @media (max-width: 768px) {font-size: 18px; font-weight: 500;}
    };
    p, li {font-size: 22px; font-weight: 400;width: 100%; padding-top: 0px; color: rgb(255, 255, 255)}
    ul {
        list-style-position: inside;
        padding-left: 0;
      }
      
      li {
        margin: 0px; /* Adjust as needed */
        padding: 0;
      }
    .content-ab {
        justify-content: center;
        // align-items: center;
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 1300px;
        z-index: 9;
        gap: 30px;
        flex-wrap: wrap;
        padding-top:20px;
        .content-a {
            width: 100%;
        }
        .content-b {
            width: 100%;
        }
    }
    .content-ab_big-gap {gap: 60px;}
    .content-column {
        align-items: center;
        flex-direction: column;
    }
    .onboarding-img {
        width: 100%;
        max-width: 400px;
        border-radius: 20px;
    }
    .teaserThumbnail {
        display: flex;
        height: fit-content;
        width: fit-content;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 100px;
        max-width: 800px;
        
        @media screen and (max-width: 768px) {
            margin-top: 30px;
        }
        &:hover {
            cursor: pointer;
            .teaserThumbnail-thumb{transform: scale(1.03);}
            
        }
        .teaserThumbnail-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            gap: 10px;
            position: absolute;
            top:0;
            left: 0;
            
            .teaserThumbnail-btn_img {
                z-index: 2;
                width: 60px;
                height: 60px;
                img {
                    width: 100%;
                }
            }
            .teaserThumbnail-btn_txt {
                width: 100%;
                text-align: center;
                color: white;
                font-size: 23px;
                font-weight: 500;
                min-width: fit-content;
                z-index: 999999;
            }
        }
        .teaserThumbnail-thumb {
            height: auto;
            width: 100%;
            top:0;
            left: 0;
            transition: transform 140ms ease-in-out;
            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
                border-radius: 20px;
            }
        }
    }
    .max-width-900 {
        max-width: 900px;
      }
}

.Onboarding-content-no-padding {
    padding: 0;
} 
.Onboarding-content-no-footer {
    height:calc(var(--vh, 1vh)* 100);
    min-height: calc(var(--vh, 1vh)* 100);
}

.Onboarding-videoPlayer {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    background-color: black;
    #video-player {
        width: 100% !important;
        min-width: 100%!important;
        min-height: 100%!important;
        height: 100vh!important;
    }
}

.Onboarding-footer {
    // height: var(--LobbyFooterHeight);
    // min-height: var(--LobbyFooterHeight);
    width: 100%;
    background-color: var(--page-background-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom:0;
    gap: 20px;
    padding: 10px 12px;
    z-index: 500;
    border-top: 1px solid black;
    @media screen and (max-width: 768px) {
        flex-direction: row;
        align-items: space-around;
        flex-wrap: wrap;
        gap: 10px;
    }
    &::before{
        background-color: rgba(255, 255, 255, 0.125);
        content: "";
        // height: var(--LobbyFooterHeight);
        // min-height: var(--LobbyFooterHeight);
        height: 100%;
        width: 100%;
        position: absolute;
        top:0;
        left: 0;
        z-index: -1;
    }
        .Onboarding-button {
            max-height: 90px;
            height: auto;
            width: fit-content;
            min-width: 220px;
            padding: 6px 40px;
            border-radius: 16px;
            font-size: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            z-index: 99999999999999;
            @media screen and (max-width: 768px) {
                min-width: 100%;
                padding: 0 20px;
                font-size: 22px;
            }
        }
        .Onboarding-button-primary{
        }
        .Onboarding-button-secondary{

            @media screen and (max-width: 450px) {
            }
        }
}

.Onboarding_Lesson_intro__container {
    // justify-content: center;
}
.Onboarding--card {
    display: flex;
    position: relative;
    padding: 0 10px;
    margin-top: 10px;
    .Onboarding--card__img {
        width: 100%;
        max-width: 400px; 
        border-radius: 20px; 
        z-index: 1;
        @media (max-width: 768px) {
            max-width: 240px
        }
        
    }
    .Onboarding--card__img--border {
        border: 7px solid white; 
    }
    .Onboarding--card__img--maxWidth600 {
        max-width: 600px; 
    }
}