
.AdminStuff {
    background: yellow;
    // display: none;
}


.questionAI{
    background-color: var(--mySideColor);
    cursor: pointer;
    color: white;
    width:fit-content;
    // min-width: fit-content;
    // max-width: fit-content;
    padding: 14px 18px 14px 18px;
    border-radius: 20px 20px 20px 4px;
    max-width: 440px;
    margin: 0;
    margin-bottom: 8px;
    font-size: 20px;
    &:hover{
        filter:brightness(106%);
    }
}

.refreshAI-container{
    display: flex;
    width: 100% ;
    justify-content: center;
    .refreshAI {
        background-color: white;
        margin-bottom: 10px;
        width: 100px;
        font-size: 19px;
        margin: 20px 0;
        border: 2px solid var(--color-skyblue);
        color: var(--color-skyblue);
    }
}

.answerAI{
    background-color: var(--partnerColor);
    // background-color: #8F59D2;
    // font-family: schoolbell;
    cursor: pointer;
    color: white;
    width:fit-content;
    // min-width: fit-content;
    // max-width: fit-content;
    max-width: 440px;
    padding: 20px;
    border-radius: 20px 20px 4px 20px;
    margin-left: auto;
    position: relative;
    margin-bottom: 8px;
    font-size: 20px;
    // font-size: 20px;
    &:hover{
        filter:brightness(106%);
    }
    // &:after {
    //     width: 70px;
    //     height: 70px;
    //     content: "";
    //     // background-color: red;
    //     display: block;
    //     position: absolute;
    //     bottom: -42px;
    //     right: 0;
    //     background-image: url(../../components/Animal/assets/bummer@2x.png);
    //     background-size:cover;
    //     transform: scaleX(-1)
    // }
}
.StatusAI {
    // background-color: red;
    text-align: center;
    font-size: 25px;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    padding-top: 40px;
    margin-top: 40px;
    border-top: 2px solid #e2e2e2;
}

.StatusAI-Good {
    color: green;
}

.StatusAI-Same {
    color: orange;
}

.StatusAI-Worse {
    color: red;
}
.ChangeAI {
    // background: yellow;
    // padding: 32px;

    text-align: center;
    // border-radius: 8px;
    // max-width: 500px;
    padding: 0 20px;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 20px;

}




.entireChat {
    // border: 4px solid red;
    // .linesWrapper {
    //     border: 4px solid blue;
    //     margin-bottom: 20px;
    
    // }
}

.LIVE {
    background-color: orange;
}



  .typing {
    align-items: center;
    display: flex;
    height: 17px;
  }
  .typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #fff ; //rgba(20,105,69,.7);
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
  }
  .typing .dot:nth-child(1) {
    animation-delay: 200ms;
  }
  .typing .dot:nth-child(2) {
    animation-delay: 300ms;
  }
  .typing .dot:nth-child(3) {
    animation-delay: 400ms;
  }
  .typing .dot:last-child {
    margin-right: 0;
  }
  
  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
      background-color:#fff; // rgba(20,105,69,.7);
    }
    28% {
      transform: translateY(-7px);
      background-color:#fff; //rgba(20,105,69,.4);
    }
    44% {
      transform: translateY(0px);
      background-color: #fff; //rgba(20,105,69,.2);
    }
  }