:root {
  --STAGE-background-angle : 110deg;
  
  // --STAGE-background-partnerColor: var(--partnerColor);
  // --STAGE-background-mySideColor: var(--mySideColor);

  --STAGE-background-partnerColor: rgba(0,0,0,0.065);
  --STAGE-background-mySideColor: rgba(0,0,0,0.03);;


  --STAGE-background-partnerColor-precentage : 60%;
  --STAGE-background-mySideColor-precentage : 50%;
  
  --STAGE-background : linear-gradient(
    var(--STAGE-background-angle),
    var(--STAGE-background-partnerColor) var(--STAGE-background-partnerColor-precentage),
    var(--STAGE-background-mySideColor) var(--STAGE-background-mySideColor-precentage)
  ) !important;
}

body {
    &[lang="he"] {
      .stage--container {
        .stage {
          direction: ltr !important;
          flex-direction: row-reverse!important;
          .partnerSide{
            left: var(--partnerSide_distance) !important;
            right: auto;
            // right: var(--mySide_distance);
            // background-color: red;
          }
          
          .mySide{
            right: var(--mySide_distance)!important;;
            left:auto;
            // left: var(--partnerSide_distance);
            // background-color: purple;
  
          }
          div {
            img {
              transform: scaleX(-1);
            }
          }
          div + div {
            img {
              transform: scaleX(1);
            }
          }    
        }
      }
      .stageBackground {
        background: linear-gradient(
              140deg,
              var(--partnerColor) 50%,
              var(--mySideColor) 50%
            );
        }

      }
  }
  .stage--container {
    position: relative;
    width: 100%;    
    height: 100%;
    display: flex;
    bottom: 0;
    // min-height: 375px;
    // border: 2px solid orange;
    
    .stage {     
      display: flex;
      flex-direction: row;
      // justify-content: space-around;
      // justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      min-height: 400px;
      // z-index: 5;
      position: absolute;
      margin-top: 0;     
      bottom: 70px; 
      .side {
        // z-index: 2;
        position: absolute;
        transition: left 400ms ease-in-out, right 400ms ease-in-out, bottom 110ms ease-in-out;
      }
      .partnerSide{
        right: var(--partnerSide_distance)
        // right: 16%;
      }
      
      .mySide{
        left: var(--mySide_distance);
        // left: 16%;
      }
      img {
        width: 240px;
        margin-bottom: -10px;
      } 
      div + div {
        img {
          transform: scaleX(-1);
        }
      }
      .stage__name {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        // text-shadow: 0 3px rgba(0,0,0,0.1);
        font-size: 34px;
        // font-weight: 700;
        // z-index: 5;
        font-family: 'Gaegu', 'Varela Round', sans-serif;
      }
      .stage__name--relation {
        font-size: 18px;
        font-family:'Varela Round', sans-serif;
        opacity: 0.65;
      }
    }
  //   .stageBackground {
  //     bottom:0;
  //     position: absolute;
  //     width: 100%;
  //     height: auto;
      
  //     .stageBackground--surface {       
  //         content: "";
  //         background-size: cover;
  //         background-position: center;
  //         width: 100%;     
  //         background-repeat: no-repeat;
  //         position: absolute;
  //         bottom: 185px;
  //         z-index: 1;
  //     } 
  //     .stageBackground--hills {
  //       // background-image: url(w-bg.png);
  //       height: 85px;
  //     } 
  //     .stageBackground--filler {
  //         content: "";
  //         width: 100%;
  //         height: 185px;
  //         background-color: white;
  //         bottom: 0;
  //     }
  // }
  }

// .stageBackground {
//   transition: background 110ms ease-in-out;
//   background: var(--STAGE-background);
//   background: linear-gradient(
//         140deg,
//         var(--mySideColor) 50%,
//         var(--partnerColor) 50%
//       );
//   }


@media screen and (max-width: 1140px) {
  .stage--container {
    .stage{
      img{
        width: 170px;
      }
    }
    // .stage{
    //   img {
    //     // margin-top: 80px;
    //     width: 100%;
    //     max-width: 100px;
    //     height: auto;  
    //   }
    //   .stage__name {
    //     font-size: 18px;
    //   }
    //   .stage__name--relation {
    //     font-size: 16px;
    //   }
    //   .side {
    //     bottom: 24px;
    //   }
    // }
    
    // .stageBackground {
    //   background: linear-gradient(
    //     132deg,
    //     var(--mySideColor) 59%,
    //     var(--partnerColor) 50%
    //   ) !important;
      
    //   .stageBackground--surface {
    //     bottom: 65px;
    //     // background-size: contain;
    //   }  
    //   .stageBackground--filler {
    //       content: "";
    //       width: 100%;
    //       height: 95px;
    //       background-color: white;
    //       bottom: 0;
          
    //   }

    // }
  }


}