:root {
    --leftContentMaxWidth: 100%;
}

.PageTwoColumns-container{
    padding-top:80px;
    padding-bottom:80px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    img{
        margin: 0 auto;
        width: 100%;
        padding: 0; 
    }
    .heroImg {
    }
    .sticker_s {
        max-width: 140px;
    }
  
    .PageTwoColumns-container__content {
        text-align: left;
        padding: 0 20px;
        padding-top: 20px;
        height: auto;
        width: 100%;
        p { padding: 0; margin: 0;line-height: 28px;}
        h3 {font-size: 25px; padding: 0; margin: 0;line-height: 33px;}
        padding-bottom: 70px;
        a {
            text-decoration: underline; color: blue;
            &:visited{
              color: blue  
            }
        }

        .ContentTwoColumns-container {
            width: 100%;
            display: flex;
            max-width: 1024px;
            margin: 0 auto;
            margin-top: 20px;
            padding-bottom: 60px;
            font-size: 22px;
            // background-color: yellow;
            @media screen and (max-width: 1024px){
                flex-direction: column;
            }
            .ContentTwoColumns-left {
                margin: 0 auto;
                max-width: 700px;
                width: 100%;
                h1 {text-align: left;}
                p { font-size: 20px;}    
                // background-color: rgb(255, 204, 0);
                margin-right: 10px;
                @media screen and (max-width: 1024px){
                    margin: 0 auto;
                    margin-bottom: 10px;
                    max-width: 700px !important;
                }
            }
            .leftContentMaxWidth_10 { 
                max-width: 10%;
            }
            .leftContentMaxWidth_20 { 
                max-width: 20%;
            }
            .leftContentMaxWidth_25 { 
                max-width: 25%;
            }
            .leftContentMaxWidth_30 { 
                max-width: 30%;
            }
            .leftContentMaxWidth_40 { 
                max-width: 40%;
            }
            .leftContentMaxWidth_50 { 
                max-width: 50%;
            }
            .ContentTwoColumns-right {
                width: 100%;
                max-width: 700px;
                height: auto;
                margin: 0 auto;
                margin-left: 10px;
                @media screen and (max-width: 1024px){
                    margin: 0 auto;
                    margin-top: 10px;
                    max-width: 700px;
                }
            }
            .rightContentMaxWidth_10 { 
                max-width: 10%;
            }
            .rightContentMaxWidth_20 { 
                max-width: 20%;
            }
            .rightContentMaxWidth_25 { 
                max-width: 25%;
            }
            .rightContentMaxWidth_30 { 
                max-width: 30%;
            }
            .rightContentMaxWidth_40 { 
                max-width: 40%;
            }
            .rightContentMaxWidth_50 { 
                max-width: 50%;
            }
        }
    }
}


