body {
    &[lang="he"] {
        .v-container {
        direction: rtl;
        left: 0;
        right: 7px;
      }
    }
  }

.questContent {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    .questNav-test {
        position:fixed;
        top: 0;
        right: 0;
        background-color: white;
        
        // margin: 20px;
        box-shadow: 0 4px 12px 1px rgba(0,0,0,0.3);
    }
    .questForm {
        text-align: left;
        min-width: 100%;
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        height:100%;
        // padding-bottom: 300px;
        a {
            font-size: 22px;
        }
    }

    .questForm::-webkit-scrollbar {
        display: none;
    }

    .questButtons__container {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        position: relative;
        .questButtons-gradient {
            // &::before {
                position: absolute;
                top: -30px;
                content: '';
                width: 100%;
                height: 30px;
                background: linear-gradient(rgba(255,255,255,0), #fff);
                z-index: 9999999;
            }
        .questButtons {
            flex:13;
            // background-color: white;
            // background-color: orange;
            padding-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            padding-bottom: 8px;
            margin-bottom: 20px;
            button {
                margin: 0 10px;
            }
            
            @media screen and (max-width: 500px) {
                paddi\ng-bottom: 100px;
            }
        }    
    }    
    
}

.exitOverlay {
    z-index: 99999999;
    top: 0;
    left: 0;
    padding: 20px;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.77);
    min-width: 100vw;
    min-height: 100vh;
    text-align: center;
    margin: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    .overlayBox {
        z-index: 99999999;
        margin-top: -10%;
        // border: 2px solid red;
        h1 {
            z-index: 99999999;
            font-size: 150px;
            padding: 0;
            margin: 0;
        }
        h2 {
            z-index: 99999999;
            font-size: 60px;
            color: white;
            padding: 0;
            margin: 0;
            @media screen and (max-width: 768px) {
                font-size: 36px;
            }
        }
        h3 {
            z-index: 99999999;
            font-size: 30px;
            color: var(--color-gold);
            padding: 0;
            margin: 0;
            @media screen and (max-width: 768px) {
                font-size: 26px;
            }
        }
        .exitOverlay-topTitle {
            margin-bottom: 30px;
            @media screen and (max-width: 768px) {
                margin-bottom: 10px;
            }
        }
        .questCompletedImg {
            width: 200px;
            height: 200px;
            position: relative;
            margin: 0 auto;
            margin-bottom: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 99999999;
            .v-container {
                z-index: 99999999;
                width: 60px;
                height: 60px;
                background-color: var(--color-gold);
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: -12px;
                left: 7px;
                .v-icon {
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                    z-index: 3;
                }
                
            }
            .img{
                z-index: 99999999;
                z-index: 2;
                width: 90%;
                height: 90%;
            }
            .circle {
                z-index: 99999999;
                background-color: var(--mySideColor);
                width: 100%;
                height: 100%;
                border-radius: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                box-shadow: inset 0px 0px 0px 6px #ffffff2b;
                // border: 5px ​solid #ffffff1;
            }
        }
        .exitOverlay-button {
            z-index: 99999999;
            margin-top: 50px;
            width: auto;
            min-width: 300px;
        }
    }

}
