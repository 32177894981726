body {
    &[lang="he"] {
      .animalsForground {
        direction: ltr;
      }
    }
  }
  .animalsForground--container {
    position: absolute;
    width: 100%;    
    height: auto;
    bottom:0px;
    
    .animalsForground {
      display: flex;
      justify-content: space-around;
      width: 100%;
      min-height: 100%;
      z-index: 5;
      position: relative;
      padding-bottom:30px;
      .side {
        z-index: 2;
        position: absolute;
        bottom: 50px;
        transition: left 400ms ease-in-out, right 400ms ease-in-out;
      }
      .mySide{
        // left: 10%;
        left: var(--mySide_distance)
      }
    
      .partnerSide{
        // right: 10%; 
        right: var(--partnerSide_distance)
      }
      img {
        // width: 30px;
        // height: 30px;
      } 
      div + div {
        img {
          transform: scaleX(-1);
        }
      }
      .animalsForground__name {
        display: flex;
        flex-direction: column;
        align-items: center;
        // color: white;
        color: #333;
        font-size: 24px;
        font-weight: 700;
        z-index: 5;
      }
      .animalsForground__name--relation {
        font-size: 18px;
        opacity: 0.65;
      }
    }
    .animalsBackground {
      bottom:0;
      position: absolute;
      width: 100%;
      height: auto;
      
      .animalsBackground--surface {       
          content: "";
          background-size: cover;
          background-position: center;
          width: 100%;     
          background-repeat: no-repeat;
          position: absolute;
          bottom: 185px;
          z-index: 1;
      } 
      .animalsBackground--hills {
        background-image: url(w-bg.png);
        height: 85px;
      } 
      .animalsBackground--filler {
          content: "";
          width: 100%;
          height: 185px;
          background-color: white;
          bottom: 0;
      }
  }
  }

.animalsBackground {

background: linear-gradient(
      132deg,
      var(--mySideColor) 59%,
      var(--partnerColor) 50%
    ) !important;
  }


@media screen and (max-width: 1024px) {
  .animalsForground--container {
    
    .animalsForground{
      img {
        // margin-top: 80px;
        width: 100%;
        max-width: 100px;
        height: auto;  
      }
      .animalsForground__name {
        font-size: 18px;
      }
      .animalsForground__name--relation {
        font-size: 16px;
      }
      .side {
        bottom: 24px;
      }
    }
    
    .animalsBackground {
      background: linear-gradient(
        132deg,
        var(--mySideColor) 59%,
        var(--partnerColor) 50%
      ) !important;
      
      .animalsBackground--surface {
        bottom: 65px;
        // background-size: contain;
      }  
      .animalsBackground--filler {
          content: "";
          width: 100%;
          height: 95px;
          background-color: white;
          bottom: 0;
          
      }

    }
  }


}