.ButtonIconText {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    align-items: center;
    .ButtonIconText__icon {
        transition: border 100ms ease-in-out, filter 100ms ease-in-out;
        width: 60px;
        height: 40px;
        background-color: white;
        border-radius: 8px;
        border: 2px solid rgba(51, 51, 51, 0.303);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 30px;
            height: 30px;
            margin: 0 auto;
        }
    }
    .ButtonIconText__text {
        transition: color 100ms ease-in-out;
        text-align: center;
        font-size: 12px;
        color: rgb(97, 97, 97);
        padding-top: 4px;
    }
    &:hover{
        .ButtonIconText__icon {
            border-color: black;
        }
        .ButtonIconText__text {
            color: black;
        }
    }
    
}

.cta {
    .ButtonIconText__icon {
    background-color: var(--mySideColor);
    
    }
    &:hover {
        .ButtonIconText__icon {
            filter: brightness(108%);
            border: 2px solid rgba(51, 51, 51, 0.303);
        }
        .ButtonIconText__text {
            color: rgb(97, 97, 97);
        }
        
    }
}