:root {
    --title-FontSize : 42px;
}

.TitleBlock-container {
    padding: 20px 12px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    .TitleBlock-title  {
        font-size: var(--title-FontSize);
        font-weight: 600;
        letter-spacing: 2px;
        text-align: center;
        width: 100%;
        padding-top: 10px;
        margin:0;
        font-size: 60px;
        color: white;
        text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.313);
    }
    .TitleBlock-subtitle {
        color: white;
        font-size: 30px;
        text-align: center;
        width: 100%;
        padding-bottom: 20px;
        font-weight: 400;
        margin:0;
    }
}