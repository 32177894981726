.MuiDialog-container {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7)!important;
    
}
.MuiDialog-paperWidthSm {
    
    background-color: transparent!important;
    overflow-y: hidden !important;
    padding: 0!important;
    margin: 0 auto!important;
    width: 100%!important;
    height: 100vh !important;
    max-height: 100vh !important;
    box-shadow: none !important;
    z-index: 9999 !important;
    
    .card {
        min-width: 100px;
        min-height: 136px;
        margin: 0 auto !important;
        margin-bottom: 50px !important;
        // margin-top: 40px;
        transform: scale(1.2);
        cursor: default;
    }
    .card-editor {
        margin: 0 auto;
        width: 100%;
        padding: 0 20px;
        max-width: 400px;
        .card-editor__card-modifier {
        }
        .button__switch {
            margin-top: 20px !important;
            margin-bottom: 20px;
            
            button {
                margin-top: 0px !important;
            }
            
        }
        .main-actions {
            // max-width: 260px;
            
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100%;
            height: 88px;
            margin: 0 auto;
            margin-top: 20px;
            .modal-button {
                margin-top: 10px;
                margin-bottom: 10px;
                width: 88px;
                height: 88px;
                background: transparent;
                border: 1px solid rgba(255,255,255,0.75);
                border-radius: 88px;
                padding: 0 20px;
                position: relative;
                transition: background-color 90ms ease-in-out;
                &:hover {
                    background-color: rgba(255,255,255,0.25);
                }
            }
            .modal-button__delete {
                
                    // border-color: #ff3939b3 !important;
                    // background-color: #c506068a !important; 
                    
                    
                    background-image: url(ic40_trash@2x.png);
                    
                    background-size: 40px 40px;
                    background-repeat: no-repeat;
                    background-position: center;     
                
                &:hover{
                    border-color: #ff3939b3 !important;
                    background-color: #c506068a !important;      
                }
                } 
                .modal-button__success {
                    background-image: url(ic40-v@2x.png);
                    background-size: 40px 40px;
                    background-repeat: no-repeat;
                    background-position: center;     
                &:hover{
                    border-color: #4afaa5b3 !important;
                    background-color: #00944cc2;
                } 
            }
            div {
                width: 100px;
                button{
                    margin: 0;
                    height: 90px;
                    width:90px;
                    border-radius: 100px;
                    font-size: 27px;
                }
            }
        }
        .button__switch {
            button:before {
                position: absolute;
                left: 10px;
                top: 7px;
                height: 100%;
                width: 50px;
                content: "⇄";
                font-size: 30px;
            }
        }
    }
}

