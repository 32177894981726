:root {
    --title-before-content: "Let's build a theatre";
  }

body {
    &[lang="he"] {
            --title-before-content: "בונים תאטרון בובות";

        .HeroPoster__container--list-item {
        direction: rtl;
            .HeroPoster__container {
                direction: rtl;
                .HeroPoster__container--text {
                    .HeroPoster__topLine {
                        text-align: right;
                    }
                    .HeroPoster__title {
                        text-align: right;
                        &::before {
                            text-align: right;

                        }
                    }
                    .HeroPoster__description {
                        text-align: right;
                    }
                }
            }
            .instructor {
                right:auto;
                left: 52%;
                transform: scaleX(1);
            }
      }
    }
  }

.HeroPoster__container--list-item {
    display: flex;
    position: relative;
    align-items: flex-start;
    // background-color: var(--color-skyblue);
    // background-color: rgb(0, 52, 86);
    width: 100%;
    height: 100%;   
    .HeroPoster__container {        
        width: 100%;
        height: 100%;
        min-height: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        transition: background-color 100ms ease-in-out; 
        // padding-bottom: 20px; 
        
        
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        &:hover {
            &:before {
                opacity: 0.2;
            }
            .HeroPoster__container--right .HeroPoster__button {
                filter: brightness(108%);
            }
            
        }
        .HeroPoster__container--a {
            // min-width: 400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 950px;
            height: auto;
            // height: 450px;
            display: flex;
            padding: 30px;
            padding-top: 0;
            position: relative;
            padding-top: 20px;
            max-height: 400px;
            // &:hover {
            //     .HeroPoster__container--image {
            //     // padding: 8px;
            //     // margin-bottom: -8px;
            //     cursor:pointer;
            //     filter: brightness(1.1);
            //     transform: scale(1);
            //     }
            //     &:before {
            //         transform: translate(-50%, -50%) scale(1.4);
            //         cursor:pointer;
            //     }
            // }
            // &:before {
            //     background-image: url("/assets/icons/play-icon.png");
            //     background-size: contain;
            //     width: 60px;
            //     height: 60px;
            //     position: absolute;
            //     z-index: 2;
            //     content: "";
            //     top:45%;
            //     left:50%;
            //     transition: transform 200ms ease-in-out;
            //     transform: translate(-50%, -50%) scale(1);
            // }
            .HeroPoster__container--image {
                transition: transform 200ms ease-in-out, filter 200ms ease-in-out;
                width: 100%;
                height: 100%;
                transform: scale(0.99);
                border-radius: 30px ;
                padding: 0px;
                object-fit: cover;
                border: 2px solid var(--color-darkblue);
                box-shadow: 0 14px 16px 1px rgba(1, 1, 104, 0.16);
                
            }
        }

        .HeroPoster__container--b {
            width: 100%;
            display: flex;
            height: auto;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 34px 30px 30px 36px;
            padding-top: 0px;
            gap: 20px;
            max-width: 650px;
            padding-top: 80px;
            .HeroPoster__container--text {
                margin: 0 auto;
                width: 100%;
                .HeroPoster__topLine {
                    font-size: 32px;
                    // color: white;
                    color: var(--color-darkblue);
                    color: black;
                    color: yellow;
                    font-family: 'Gaegu','Rubik', sans-serif;
                    font-weight: 600;
                    text-align: left;
                    margin: 0;
                    margin-bottom: 20px;
                }
                .HeroPoster__title {
                    font-size: 84px;
                    font-family: 'Rubik', sans-serif;
                    font-weight: 600;
                    text-align: left;
                    color: white;
                    // word-spacing: -6px;
                    // letter-spacing: 6px;
                    line-height: 70px;
                    font-family: 'Rubik', system-ui;
                    margin-bottom: 10px;
                    position: relative;
                    text-shadow: -7px 8px 0 rgba(0, 0, 0, 0.153);
                    &::before {
                        top: 0;
                        position: absolute;
                        content: "בונים תאטרון בובות";
                        content: var(--title-before-content);
                        font-size: 84px;
                        font-family: 'Rubik', sans-serif;
                        font-weight: 600;
                        text-align: left;
                        // color: var(--color-darkblue);
                        color: black;
                        letter-spacing: -3px;
                        word-spacing: 11px;
                        font-family: 'Rubik Doodle Shadow', sans-serif;
                        text-shadow: none;
                        margin-bottom: 10px;
                        z-index: 0;
                    }
                }
                .HeroPoster__description {                    
                    font-size: 22px;
                    padding-top: 8px;
                    padding-bottom: 15px;
                    transition: color 100ms ease-in-out;
                    // font-family: 'Rubik', sans-serif;
                    font-weight: 400;
                    text-align: left;
                    color: white;
                    }

            }
            .HeroPoster__button {
                width: 400px;
                max-width: 400px;
                height: 90px;
                text-align: center;
                // color: white;
                color: white;
                // color: var(--color-darkblue);
                font-weight: 600;
                // line-height: 30px;
                font-size: 34px;
                border-radius: 20px;
                padding-top: 2px;
                background-color: var(--color-primary);
                background-color: #fb1c65;
                border: 2px solid black;
                margin:0 !important;
                // font-family: 'Rubik', sans-serif;
                letter-spacing: 3px;
                box-shadow: inset 0 -6px 0px 0px rgba(1, 1, 104, 0.271);
                text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
                transition: transform 120ms ease-in-out, background-color 120ms ease-in-out;
                &:hover {
                    transform: scale(1.02);
                }
            }
            
        }
        .instructor {
            position: absolute;
            bottom: 0;
            right: 52%;
            width: 200px;
            height: auto;
            z-index: 1;
            transform: scaleX(-1);
        }
    }
}



