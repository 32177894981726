.StoryTime__book{
    padding: 0;
    margin:0;
    position: relative;
    width: 100%;
    height: 100vh;
    .StoryTime__kfula{
        position: absolute;
        width: 100%;
        height: 100vh;
        display: flex;
        .StoryTime__page {
            width: 100%;
            height: 100vh;
            padding: 0;
            margin: 0;
            transition: transform 600ms ease-in-out, filter 600ms ease-in-out;
            transform-origin: left center;
            .page__text {
                padding: 80px;
                h1{
                    font-family: 'Mali', cursive;
                    font-size: 70px;
                    font-weight: 200;
                }
            }
            .page__img {
                // position: relative;
                width: 100%;
                height: 100vh;
                display: flex;
                img {
                    // position: absolute;
                    // bottom: 0;
                    object-fit: cover;
                    width: 100%;
                }
                
            }
        }
        .StoryTime__page--1 {
            border-left: 1px solid #e2e2e2;
            background: white;
        }
        .StoryTime__page--2 {
            background: white;
            background: green;
            transition: transform 600ms ease-in-out, filter 600ms ease-in-out;
            transform-origin: left center;  
        }
        .StoryTime__page--3 {
            background: white;
            h1, img{
                transform: scaleX(-1);
                
            }
        }
        .StoryTime__page--4 {
            background: blue;

        }
        .StoryTime__page--5 {
            background: purple;

        }
        .flip {
            transform: rotateY(-180deg);
            filter: brightness(90%);
    
        }
    }
    .StoryTime__kfula--top{ 
        z-index: 20;
        color: red;
        background-color: rgba(255,0,0,0.6) ;
    }
    .StoryTime__kfula--bottom{
        z-index: 10;
    }
}