body {
    &[lang="he"] {
      .scrollBar-container {
        direction: rtl;
      }
    }
}

.scrollBar-container {
    position: fixed;
    bottom: 20px;
    inset-inline-start: 100px;
    z-index: 2;
    max-width: 400px;
    width: 100%;
    padding: 20px;
    inset-inline-start: 100px;
    .scrollBar-knob {
      width: 160px;
      height: 30px;
      border-radius: 60px;
      background-color: white;
      position: absolute;
      bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.195);
    }
    .scrollBar {
      border-radius: 100px;
      width: 100%;
      height: 20px;
      background-color: rgba(0, 0, 0, 0.134);
    }
  }

.scrollBar-hide-mobile {
    @media (max-width: 768px) {
        display: none;
    }
}