:root {
    --FooterStickyBlock-background-color : rgba(0, 0, 0, 0);
}

body {
    &[lang="he"] {
        .FooterStickyBlock-container {
            flex-direction: row-reverse;
            button {
                flex-direction: row-reverse;
            }
      }
    }
}


.FooterStickyBlock-container {
    // min-height: var(--LobbyFooterHeight);
    width: 100%;
    background-color: var(--color-skyblue);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom:0;
    gap: 20px;
    padding: 10px 12px;
    z-index: 2;
    z-index: 9999999999;
    border-top: 1px solid black;
    @media screen and (max-width: 768px) {
        flex-direction: row;
        align-items: space-around;
        flex-wrap: wrap;
        gap: 10px;
    }
    &::before{
        background-color: rgba(255, 255, 255, 0.125);
        content: "";
        // height: var(--LobbyFooterHeight);
        // min-height: var(--LobbyFooterHeight);
        height: 100%;
        width: 100%;
        position: absolute;
        top:0;
        left: 0;
        z-index: 0;
    }
        .Onboarding-button {
            max-height: 90px;
            height: auto;
            width: 100%;
            min-width: fit-content;
            padding: 20px 40px;
            border-radius: 16px;
            font-size: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            z-index: 99999999999999;
            @media screen and (max-width: 768px) {
                min-width: 100%;
                padding: 0 20px;
                font-size: 22px;
            }
        }
        .Onboarding-button-primary{
        }
        .Onboarding-button-secondary{

            @media screen and (max-width: 450px) {
            }
        }
}

.FooterStickyBlock-backgroundBright {
    &::before{
        background-color: rgba(255, 255, 255, 0.125);
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top:0;
        left: 0;
        z-index: 0;
    }
}