:root {
    --Dot-width: 16px;
    --Dot-height: 16px;
    --Dot-color: white;
    --Dot-selected-bleed: 6px;
}

.ProgressDot {
    border: 2px solid rgba(0,0,0,0.2);
    width: var(--Dot-width);
    min-width: var(--Dot-width);
    height: var(--Dot-height);
    min-height: var(--Dot-height);
    border-radius: 100%;
    background-color: var(--Dot-color);
    transition: background-color 200ms ease-in-out;
    position: relative;
    margin: 0 6px;
    margin-bottom: 10px; 
  }
  .ProgressDot_completed {
    background-color: var(--color-primary);
    border: 2px solid rgba(0,0,0,0.12);
    border: none
}

.ProgressDot_selected {
    box-shadow: 0 0 0 3px white, 0 0 0 4px rgba(0,0,0,0.35);

    &:before {
        background-color: rgba(0, 0, 0, 0.4);
        width: calc(var(--Dot-width) + var(--Dot-selected-bleed));
        min-width: calc(var(--Dot-width) + var(--Dot-selected-bleed));
        height: calc(var(--Dot-height) + var(--Dot-selected-bleed));
        min-height: calc(var(--Dot-height) + var(--Dot-selected-bleed));
        content: "";
        z-index: -1;
        position: absolute;
        transform: translate(-50%, -50%) scale(1.2);
        left: 50%;
        top: 50%;
        border-radius: 100%; 
        animation: ripple 7s infinite;
    }    
}

@keyframes ripple {
    0% {
      transform: translate(-50%, -50%) scale(0.8);
      opacity: 0.33;
    }
    40% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
      }
    100% {
      transform: translate(-50%, -50%) scale(2);
      opacity: 0;
    }
  }

  .ripple-Animation {
    animation-name: ripple;
    animation-duration: infinite;
  }
  