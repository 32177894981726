body {
  &[lang="he"] {
    .form-label {
      direction: rtl;
      text-align: right;
    }
  }
}

:root {
    --stageHeight: 250px;
    --panelNavHeight: 70px;
    --stageMargin: 0px;
    // --stageMargin: 20px;
    --stageBorderRadius: 0px;
    // --stageBorderRadius: 20px;
  }
  

  
  .MINI-STEP {
      display: flex;
      flex-direction: row;
      min-height: calc(var(--vh, 1vh) * 100); 
             
      .MINI-STEP__PANEL {
          
          z-index: 10;
          width: 100%;
          min-width: 530px;
          height: 100%;
          min-height: calc(var(--vh, 1vh) * 100);      
          overflow-y : scroll;
          overflow-x : hidden;
          display: flex;
          flex-direction: column;
          justify-content: start;
          border: none;
          flex-grow: 1;
          transition: height 200ms ease-in-out, min-height 200ms ease-in-out,max-height 200ms ease-in-out;
          
          .MINI-STEP__PANEL--CONTENT {
            padding: 0 20px;
            display: flex;
            min-height: calc(var(--vh, 1vh) * 100 ); 
            max-height: calc(var(--vh, 1vh) * 100 );     
            flex-direction: column;
            overflow-y: scroll;
            overflow-x : hidden;
            z-index: 100;
            position: relative; 
            form {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                min-width: 100%;
                align-self: auto;
                .stepItem {
                  width: 100%;
                }
                .form__mileStone {
                    width: 100%;
                }
                .form-label {
                    font-size: 20px;
                    line-height: 27px;
                    @media screen and (max-width: 768px) {
                      // font-size: 24px;
                    }
                }
                .form-label_notMandatory {
                  // font-size: 25px;
                  // color: #a2a2a2;
                  // color: #9e9d99;
                }
                .form-label_center {
                  text-align: center;
                }
                .stepItem_intro-container {
                  max-width: 470px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-content: space-between;
                  text-align: center;
                  margin: 0 auto;
                  h1{
                    margin: 0;
                    margin-bottom: -6px;
                  }
                  h2{
                    // margin-bottom: 40px;
                    font-size: 20px;
                    opacity: .5;
                  }
                  p{
                    font-size: 20px;
                    line-height: 27px;
                  }
                  
                  .stepItem_intro-img {
                    position: relative;             
                    width: 100%;
                    height: 170px;
                    margin-bottom: 30px;
                    img {
                      margin: 0 auto;
                      width: 140px;
                      height: 140px;
                      position: absolute;
                      transform: translate(-50%,-50%);
                      left: 50%;
                      top: 50%;
                      z-index: 10;
                    }
                    .img_circle {
                      width: 170px;
                      height: 170px;
                      background-color: #e2e2e2;
                      border-radius: 100%;
                      position: absolute;
                      transform: translate(-50%,-50%);
                      left: 50%;
                      top: 50%;
                    }
                    .img_circle_mySideColor {
                      background-color: var(--mySideColor);
                    }
                    .img_circle_partnerColor {
                      background-color: var(--partnerColor);
                    }

                     

                  }
                  
                }
            }
            .cardGallery__container {
                // margin-left: -15px;
                // margin-right: -15px;
                justify-self: flex-start;
            }
            .CONTENT__container {
              min-height: calc(var(--vh, 1vh) * 100 - var(--sideBarPaneNavHeight)); 
              .CONTENT__list--container {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 20px;
                padding-bottom: 60px;
                min-height: calc(var(--vh, 1vh) * 100 - var(--sideBarPaneNavHeight)); 
                .ButtonsFooter {
                  margin-top:auto;
                }
              }
            }
            
        
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            
          }
          .MINI-STEP__PANEL--CONTENT::-webkit-scrollbar {
            display: none;
        }
      }
      .MINI-STEP__STAGE {
          display: block;
          // display: none;
          transition: margin-top 200ms ease-in-out;
          width: calc(100vw - (var(--stageMargin)*2)) !important;
          overflow: hidden;
          z-index: 1;
          background: var(--mySideColor);
          position: relative;
          margin: var(--stageMargin);
          border-radius: var(--stageBorderRadius);
          // box-shadow: 0 4px 14px 2px rgba(0,0,0,0.14);
          transition: transform 500ms ease-in-out;
          .stage {
            
            // width: 100%;
            // min-width: 100%;
            // position: relative;
            // height: 100%;
            // bottom:0;
            // overflow: hidden;
           }
            // .stageBackground-container {
            //   overflow: hidden;
            //   content:"";
            //   min-width: 200%;
            //   margin: 0 auto;
            //   height: 300px;
            //   //   min-height: calc(var(--vh, 1vh) * 20);
            //   position: absolute;
            //   bottom: 0;
            //   transform: translate(-50%, 0%);
            //   left: 50%;
            // }
            // .stageBackground-container-3d {
            //   perspective: 150px;
            // }
            // .stageBackground-3d {
            //   border-radius: 40px;
            //   border-radius: 100%;
            //   transform: rotateX(25deg);
            // }
            // .stageBackground {
            //   content:"";
            //   width: calc(70%);
            //   margin: 0 auto;
            //   box-shadow: 0 6px 14px 0 rgba(0,0,0,0.1), inset 0 -5px 0 0 rgba(0,0,0,0.1);
            //   margin-bottom: 30px;
            //   height: 100%;
            //   min-height: calc(var(--vh, 1vh) * 20);      
            // }
        
      }
      .MINI-STEP__STAGE_scaleDown  {
        // margin: 100px;
        transform: scale(0.7);
      }
  }
  
  @media screen and (max-width: 1024px) {
      .MINI-STEP {
        //   flex-direction: column-reverse;
          flex-direction: column;
          min-height: auto;
          .MINI-STEP__PANEL {
              max-width: 100%;
              min-width: 100%;
              height: 100%;
              justify-content: space-between;
              // position: relative;
              // padding: 0 14px;
              min-height: auto;
              // box-shadow: 0 4px 10px 1px rgba(0,0,0,0.2);
              .MINI-STEP__PANEL--CONTENT{
              // .MINI-STEP__PANEL--content{
                //   padding-top: calc(30px + var(--panelNavHeight));
                  max-height: 100%;
                  min-height: 0;
                  padding: 0 12px;
                  form {
                    .cardGallery__container {
                        margin: 0;
                    }
                  }
                //   padding-bottom: 30px;
                  // position: absolute;
                  // height: var(--panelContentHeight)
                  // height: calc(100vh - 100px - var(--stageHeight)); 
                  // height: calc(100vh - var(--buttonsFooterHeight) - var(--stageHeight)); 
                  // height: calc(100vh - var(--buttonsFooterHeight)); 
                  
                  .MINI-STEP__PANEL--CardWrap {
                      margin-left: 0;
                      margin-right: 0;
                  }
                  .ButtonsFooter{
                    padding: 40px 12px ;
                      
                  }
              }
              
          }
          .MINI-STEP__STAGE {
            //   display: none;
            //   height: var(--stageHeight);
            //   min-height: var(--stageHeight);
            width: 100%;
            height: 500px;
          }
      }
  }

  @media screen and (max-width: 768px) {
  .ingame-background {
    top: -380px;
  }
}


  // .fade-in-animation {
  //     // animation-name: fade-in-animation !important;
  //     // animation-duration: 200ms !important;
  //     // animation-iteration-count: 1;
  //     // // animation-timing-function: ease-in-out;
  //     // animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);

  //     animation: fade-in-animation 2s infinite;

  //   }
    
    
  //   @keyframes fade-in-animation {
  //     0% {
  //         opacity: 0 !important;
  //         // transform: scale(1.03);
  //         // box-shadow: 0 3px 10px rgba(0,0,0,0);
  //       }
  //     10% {
  //       opacity: 0 !important;
  //     //   transform: scale(1.03);
  //       // box-shadow: 0 3px 10px rgba(0,0,0,0);
  //     }
  //     100% {
  //       opacity: 1 !important;
  //       background: red;
  //     //   transform: scale(1);
  //       // box-shadow: 0 3px 10px rgba(0,0,0,0.2);
  //     }
  //   }