body {
  &[lang="he"] {
    .cardGallery__container {
      direction: rtl;
    }
    .card-container {
      direction: rtl;
      margin-right: 0;
      // margin-left: 10px;
    }
    .cardWrap {
      direction: rtl !important;
      @media screen and (max-width: 768px) {
        justify-content: right;
      }
    }
    .cardGallery {
      // direction: ltr;
      direction: rtl;
    }
    .card__target-and-perfix {
      direction: rtl;
    }
  }
}
.cardGallery__container {
  display: flex;
  flex-direction: row;
  direction: ltr;
}

.card-container {
  // margin-right: 10px;
}

.cardWrap {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-direction: row;
  overflow-x : auto;
  overflow-y: hidden;
  // padding: 0 15px;
  // justify-content: left;
  direction: ltr;
  // margin-bottom: 30px;
  width: 535px; 
  margin-top: 10px;
  padding-top: 10px;
  // background-color: red;
  width: 100%;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    justify-content: left;
    width: 100vw;
    padding: 0;
  }
  .card {
    margin-bottom: 30px;
    &:last-child {
      margin-right:0px;
      position: relative; 
      &:after {
        content: "a";
        font-size: 1px;
        color: rgba(0,0,0,0);
        width: 100px;
        margin-right: -60px;
      }
    }
  }
}
.card_cardWrap{
  &:hover {
    transform: scale(1) ;
    // border-color: #44433d;
    background-color: #fff;
    // box-shadow: inset 0px -4px 0px #8568042e;
  }
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  max-width: 100px;
  width: 100px;
  height: 136px;
  max-height: 136px;
  min-height: 136px;
  font-weight: 700;
  // text-transform: uppercase;
  background-color: white;
  
  // margin-right: 0px;
  color: black;
  border-radius: 12px;
  font-size: 12px;
  // box-shadow: 0px 1px 1px rgba(0,0,0,0.25);
  box-shadow: inset 0px -4px 0px rgba(0,0,0,0.09);
  border: 1px solid #6C6C6C;
  cursor: pointer;
  &__emoji {
    font-size: 40px;
  }
  .card__not , .card__a-little{ 
    position: absolute;
    font-size: 27px;
    font-weight: 100;
    right: 10px;
    bottom: 50px;
    transform: rotate(8deg);
    font-weight: 900;
    // font-family: david;
    color:white;
  }
  .card__not--stroke , .card__a-little--stroke{ 
    position: absolute;
    font-size: 27px;
    font-weight: 100;
    right: 10px;
    bottom: 50px;
    transform: rotate(8deg);
    font-weight: 900;
    // font-family: david;
    color: white;
    text-shadow: 0px 2px 3px rgba(0,0,0,0.25);
    // -webkit-text-fill-color: rgb(205, 19, 19); /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgb(0, 0, 0);
  }

  .card__a-little, .card__a-little--stroke{
    font-size: 20px;
    bottom: 55px;
  }
  .card__a-little--stroke {
    -webkit-text-stroke-width: 3px;
  }

  .card__target-and-perfix {
    display: flex;
    justify-content: space-between;
  }

  transition: transform 90ms ease-in-out, box-shadow 90ms ease-in-out;
  @media screen and (min-width: 768px) {
    &:hover {    
      // transform: scale(1.08);
      box-shadow: inset 0px -4px 0px rgba(0,0,0,0.09);
    }
  }
}

.card--feel {
  background-color: #f5e3ff;
  box-shadow: inset 0px -4px 0px #ebc2ff;
  border-color: #e0a9ff;
}

.card--happened {
  background-color: #e4ffdd;
  box-shadow: inset #b8ffa6;
  border-color: #25bb2c;
}

.card--need {
  background-color: #fffde2;
  box-shadow: inset 0px -4px 0px #f9e69d;
  border-color: #e5b002;
}

.card.selected {
  background-color: #fffde2;
  box-shadow: inset 0px -4px 0px #f9e69d;
  border-color: #e5b002;
}

.card--selected {
  outline: 3px solid var(--color-primary);

  &:after {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: var(--color-primary);
    content: "";
    position: absolute;
    top: -14px;
    right: -14px;
    background-image: url("/assets/icons/icon-v-circle_40.png");
    background-size: cover;

    border: 3px solid var(--color-primary);
  }
}

.hoverScale {
  transition: transform 120ms ease-in-out !important;
  &:hover {
    transform: scale(1.1)!important;
  }
}

.card-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: var(--color-light-gray);
  background-color: black;
  box-shadow: inset 0 4px 0 0 rgba(0,0,0,0.1);
  // color: rgba(255, 255, 255, 0.18);
  color: white;
  // box-shadow: none;
  font-size: 60px;
  font-weight: 100;
  border: none;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  &:hover {
    opacity: 0.8;
    // box-shadow: inset 0 4px 0 0 rgba(0,0,0,0.13) !important;
    // color: rgba(255,255,255,0.95);
    // box-shadow: 0px 1px 1px rgba(0,0,0,0);
  }

  img {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
  }
}
.card__source-name {
  font-size: 12px;
  font-weight: 100;
  padding-bottom: 5px;
  color: #888;
}
.card__icon {
  background-size: contain;
  width: 14px;
  height: 14px;
  position:absolute;
  top: 5px;
  left: 5px;
}
.card__icon--feel {
  background-image: url(/assets/icons/icon_feel.png);
}
.card__icon--happened {
  background-image: url(/assets/icons/icon_happened.png);
}
.card__icon--need {
  background-image: url(/assets/icons/icon_need.png);
}
.card__emoji {
  font-size: 54px;
  // margin-top:-5px;
}
.card__title  {
  font-size: 13px;
  font-weight: 600;
  color: #111;
  margin-bottom: 2px;
  text-align: center;
  max-width: 86px;
}
.card__target-name, .card__prefix {
  font-size: 12px;
  font-weight: 100;
  padding-top: 0px;
  color: #888;
}

.swiper-button-next , .swiper-button-prev {
  position: absolute;
  transform: scale(0.7);
  background-color: #ffffff;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin: 0 35%;
  
  box-shadow: 0 4px 10px 2px rgba(0,0,0,0.2);
  opacity:1;
  
  top: 45%;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
}
