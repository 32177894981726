// .authScreen-container {
//   // overflow-x: hidden;
 
body {
  &[lang="he"] {
    .authScreen-container {
      .authScreen-main {
        .authScreen_hero {
      .authScreen_hero__txt {
        h1{
          direction: ltr;
        }
        
      }
    }
      }
      
    }
    .authScreen_hero__animals {
      .authScreen_hero__animals--side2--img{
        transform: scaleX(1) !important;
      }
      .authScreen_hero__animals--side1--img{
        transform: scaleX(-1);

      }
    }
}
}

// }
.Room {
}
.authScreen-container {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: var(--color-skyblue);
  padding-top:50px;
  
  .authScreen-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .authScreen_hero {
      width: 100%;
      height: 100%;
      display: flex;
      max-width: 450px;
      flex-direction: column;
      padding: 0 20px;
      z-index: 999999;
      .authScreen_hero__txt {
        margin-top: 50px;
        h1{
          color: white;
          font-size: 30px;
        }
        h3{
          margin-top: 0;
          padding-top:0;
          color: white;
          font-weight: 100;
          font-size: 24px;
        }
      }
      .authScreen_hero__animals {
        display: flex;
        margin-top: 100px;
        
        p{
          color: white;
          text-align: center;
          padding: 0;
          margin:0;
        }
        .authScreen_hero__animals--side1{
  
        }
        
        .authScreen_hero__animals--side2--img {
          transform: scaleX(-1);
        }
      }
      
    }
    .authScreen_form {
      padding: 0 20px;
      z-index: 999999;
    }
  }
  

  .modal {
    z-index: 999999;
    height: auto;
    // border: 11px orange solid;
    background-color: white;
    max-width: 360px;
    min-width: 360px;
    margin: 0 auto;
    // margin-top: -140px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    margin-top: 30px;
    margin-bottom: 30px;
    
    @media screen and (max-width: 768px) {
      
      height: 100%;
      margin: 0 auto;
      // padding: 12px;
      margin-top: 10px;
      margin-bottom: 230px;

      // top:0;
      // left: 0;
      // border-radius: 0px;
    }
    .modal__title {
      text-align: center;
      margin:0;
      padding:0;
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 25px;
    }
    .modal__subtitle {
      text-align: center;
      color: #999;
      font-weight: 100;
      margin:0;
      padding:0;
      margin-top: 15px;
      @media screen and (max-width: 768px) {margin-top: 3px;}
    }
    .modal__form {
      -webkit-font-smoothing: antialiased;
      .form-label {
        font-size: 15px;
        padding: 0;
      }
      .input-authMenu {
        max-height: 40px ;
        height: 40px;
        color: red !important;
      }
      
      .modal__form--error-msg {
        text-align: center;
        color: #f24462 !important;
        margin-bottom: 20px;
      }
      .modal__form--sumbit-button {
        width: 100%;
        max-width: 100% !important;
        border-radius: 8px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .modal__form--sumbit-button__create-account {
        background-color: #f98235;
        color: white;
      }
      .preloaderGif {
        width: 100%;
        height: 100%;
        pointer-events: none;
        &:after {
          width: 30px;
          height: 30px;
          // position: absolute;
          content: '';
          z-index: 99999;
          background-image: url(/assets/preloader-w.gif);
          background-repeat: no-repeat;
          background-size: contain;
          pointer-events: none;
        }
      }
    }
    // .modal__below-submit {
    //   border-top: 1px solid #e2e2e2;
    //   margin-top: 20px;
    //   text-align: center;
    //   margin-bottom: 20px;
    //   -webkit-font-smoothing: antialiased;
    //   .modal__below-submit__btn {
    //     // border: 1px solid red;
    //     text-decoration: none !important;
    //     background-color: #159ff427;
    //     padding: 6px 10px;
    //     border-radius: 8px;
    //     color: var(--color-skyblue);
    //     margin-left: 6px;
    //     transition: background-color 90ms ease-in-out, color 90ms ease-in-out;
    //     &:hover {
    //       background-color: var(--color-skyblue);;
    //       color: white;
    //     }
    //   }
    //   .modal__below-submit__btn--create-account {
    //     color: #e45e15;
    //     background-color: rgba(253, 173, 61, 0.289);
    //     &:hover {
    //       background-color: #f98235;
    //       color: white;
    //     }
    //   }
    //   .createAccount-container {
    //     display: flex;
    //     flex-direction : column;
    //     border-bottom: 1px solid #e2e2e2;
    //     padding-bottom: 20px;
    //     padding-top: 5px;
    //     margin-bottom: 20px;
    //     p {
    //       margin-bottom: 10px;
    //     }
        
    //     a {
    //       cursor: pointer;
          
    //       .modal__form--sumbit-button {
    //         background: none;
    //         border: 2px solid var(--color-skyblue) !important;
    //         color: var(--color-skyblue) !important;
    //         width: 100%;
    //         border-radius: 8px;
    //         justify-content: center;
    //         align-items: center;
    //         border: none;
    //         transition: background-color 100ms ease-in-out;
              
    //         &:hover {
    //           background-color: #e9f8ff;
    //           filter: none;
    //         }
            
    //       }
          
    //     }
        
    //   }
    //   a{
    //     text-decoration: underline !important;
    //     cursor: pointer;
    //     transition: background-color 100ms ease-in-out;
    //     &:hover {
    //       color: var(--color-skyblue)
    //     }
    //   }
    //   .modal__below-submit--micro-text {
    //     font-size: 13px;
    //     margin-top: 20px;
    //     opacity: 0.6;
    //     line-height: 1.45;
    //     letter-spacing: 0.01em;
    //   }
    // }
  }
  .authScreen-footer {
    text-align: center;
    color: rgba(255, 255, 255, 0.694);
    padding: 40px 20px;
    // margin: 20px 0;
  }
}


@media screen and (max-width: 768px) {
  // .Room {
  //   margin-top: 300px;
  // }
  .authScreen-container {
    margin: 0;
    padding: 0;
    .modal {
      max-width: 100%;
      min-width: 100%;
      width:100%;
      margin: 0;
      border-radius: 0;
      
    }
    .authScreen-main {
      flex-direction: column-reverse;
      margin: 0;
      padding: 0;
      .authScreen_form {
        padding: 0;
      }
    }
    
  }
}

.preloader--img img {
  width: 140px;
}

.preloader-modal {
  // width: 200px;
  // padding-top: 20px;
}