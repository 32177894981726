.lessonFeedback-footer {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lessonFeedback_subtitle {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    margin:0;
    padding:0;
    margin-top: 40px;
    padding-bottom: 5px;
}