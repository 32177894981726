body {
  &[lang="he"] {
      .journeys__container {
      direction: rtl;
      // flex-direction: row-reverse;
    }
  }
}

.Journeys--thumbnail {
  bottom: 0;
  width: 100%;
  margin: 30px auto;
  max-width: 800px;
  border-radius: 20px;
  img {
      width: 100%;
      height: auto;
      max-height: 220px;
    }
}

.journeys__list {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  // max-width: 420px;
  justify-content: center;
  margin-bottom: 100px;
  a {
    width: 100%
  }
}
.Journeys--thumbnail-names {
  margin: 0 auto;
  text-align: center;
}