
$postitWrapperPadding:20px;
$postitWidth : 340px;
.postit-note-container {
    height: fit-content;
    min-height: var(--teamworkCardWidth);
    max-width: var(--teamworkCardWidth);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .postit-note {
        height: fit-content;
        min-height: $postitWidth;
        width: 100%;
        max-width: $postitWidth;
        font-size: 22px;
        font-weight: 400;
        padding: 30px 40px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        background-color: aliceblue;
        color: black;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.184);
        h1, h2,p ,ol,li{margin:0; padding:0; color: black;};
        h1 {font-size: 40px;font-weight: 600;}
        transform: rotate(1.1deg);
    }
    .postit-note_color_1 { background-color: #ffc584}
    .postit-note_color_2 { background-color: #ffef00}
    .postit-note_color_3 { background-color: #ff97f7}
    .postit-note_color_4 { background-color: #82ff48}
    .postit-note_color_5 { background-color: #ff8ec0}
    .postit-note_color_6 { background-color: #61fffa}
    .postit-note_color_7 { background-color: aliceblue}

    .postit-note_angle_1 {transform: rotate(-1.3deg)}
    .postit-note_angle_2 {transform: rotate(-1deg)}
    .postit-note_angle_3 {transform: rotate(-0.5deg)}
    .postit-note_angle_4 {transform: rotate(2deg)}
    .postit-note_angle_5 {transform: rotate(-0.5deg)}
    .postit-note_angle_6 {transform: rotate(1deg)}
    .postit-note_angle_7 {transform: rotate(1.3deg)}
}


