:root {
    --intructor-bottom: -75px;
    --instructor-size: 180px;
    --instructor-nameSize:18px;
    --instructor-nameColor: black;
    --instructor-nameMargin: -8px;
    --instructor-inset: -80px;
}

body {
    &[lang="he"] {
        .Lesson_Instructor--component {
            .Lesson_Instructor--animal {
                transform: scaleX(-1);
            }

      }
    }
  }

    .Lesson_Instructor--component {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: fit-content;
        max-width: fit-content;
        bottom: var(--intructor-bottom);
        inset-inline-end: var(--instructor-inset);
        z-index: 1;
        .Lesson_Instructor--animal {
            // width: 120px;
            // transform: scaleX(-1);
            // height: 200px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            img {
                width: var(--instructor-size);
            }
        }
        .Lesson_Instructor--name{
            margin-top: var(--instructor-nameMargin);
            font-size: var(--instructor-nameSize);
            color: var(--instructor-nameColor);
            width: 100%;
            text-align: center;
        }
    }

    .Lesson_Instructor__InlineStart {
        inset-inline-end: auto;
        inset-inline-start: var(--instructor-inset);
    }