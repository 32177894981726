.login-page-container {
    padding-top: 160px;
    @media screen and (max-width: 768px) {
        padding-top: 90px;
    }
}

.loginPage-error {
    color: red;
    background-color: black;
    width: fit-content;
    padding: 10px;
}