.textarea-container{
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    textarea {
        transition: background-color 200ms ease-in-out, border 200ms ease-in-out;
        background-color: var(--color-light-gray);
        background-color: white;
        border: 2px solid var(--color-light-gray);
        font-size: 30px;
        max-width: 100%;
        min-width: 100%;
        font-family: 'Gaegu', 'Varela Round', sans-serif;
        letter-spacing: -1.7px;

    }
    textarea:focus {
        background-color: var(--color-paper);
        border: 2px solid var(--color-paper-border);
        // border: 2px solid var(--color-light-gray);
    }
}

