.Popup__content--todo {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto; 
    .todoPopup__activityPage {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e2e2e2;
        padding: 20px;
        gap: 12px;
        .todoPopup__activityPage--top {
            display: flex;
            gap: 20px;
            width: 100%;
            .activityPage-img {
                border: 1px solid #939393;
                width: 120px;
                height: 170px;
                @media (max-width: 768px) {
                    width: 70px;
                    height: 101px;
                }
            }
            .activityPage-txt {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 10px;
                .todoPopupBtn {
                    margin-top: 10px;
                }
                .todoPopupBtn-desktop {
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
        .todoPopup__activityPage--bottom {
            display: flex;
            flex-direction: column;
            width: 100%;

            .todoPopupBtn-mobile {
                display: none;

                @media (max-width: 768px) {
                    display: flex;
                }
            }
        }
    }
    .todoPopup__requirements {
        overflow-y: auto;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        border-bottom: 1px solid #e2e2e2;
        max-height: 40vh; // Limits requirement list to fit within popup
    }
}
.Popup__footer--todo {
    .input-form {
        gap:12px;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 20px;
        justify-content: center;
        align-items: center;
        height: 100%;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .input-container {
            width: 100%;
            position: relative;
            .todoPopupInput-icon {
                position: absolute;
                inset-inline-start: 10px;
                top: 10px;
            }
            .todoPopupInput {
                width: 100%;
                height: 54px;
                margin:0!important;
                background-color: #fffdeb;
                border: 2px solid #ffcf0f !important; /* Ensure the border is always applied */
                outline: none;
                border-radius: 10px !important;
                font-size: 16px; /* Set font size */
                box-sizing: border-box; /* Include padding/border in width/height */
                padding-inline-start: 50px!important;
            }
        }
        .todoPopupInput:focus {
            outline: none;
            border: 3px solid black; /* Ensure no style changes on focus */
        }
    }
}
