body {
  &[lang="he"] {
    .Modal-container .Modal , .modal-payment-problem,.modal-payment-problem--title {
      direction: rtl;
      .Modal-form {
        
      }
      .SubscriptionModal_li__p, .modal-payment-problem--title, .modal-payment-problem--description{
        text-align: right;
      }
      .SubscriptionModal_no-bullet li div {
        margin:0;
        margin-right:8px;
        padding-top: 1px;
      }
    }
    
}

h2 {
    font-size: 36px;
}
.SubscriptionModal_main-title {
    max-width: 360px;
    margin:0 auto;
    text-align: center;
    font-weight: 800;
}

.SubscriptionModal_main {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 360px;
    margin: 0 auto;
    position: relative;
  }
 
  .Modal__form--img {
    // height:222px;
    // background-color: red;
     
  }

  .SubscriptionModal_container {
    padding: 0;
    margin: 0;
  }
  .SubscriptionModal_li__p {
    margin:0;
    padding: 0;
    font-size: 15px;
  }
  .SubscriptionModal_check-bullet {
    // background-image: url(/assets/icons/pause-icon-dark.png);
    background-image: url(/assets/icons/icon-v_20.png);
    background-size: cover ;
    display: inline-block;
    content: "";
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
  
  .SubscriptionModal_no-bullet {
    list-style: none;
    padding-left: 0;
  }
  
  .SubscriptionModal_no-bullet li {
    margin-bottom: 2px;
  }
  
  .SubscriptionModal_no-bullet li div {
    margin-left: 8px;
  }
  ul {
    list-style-type: disc;
    padding: 0;
    margin: 0;
    padding-top:20px;
  }
  li {
    // display: flex;
    // align-items: top;
    padding-bottom: 10px;
  }
  
  .SubscriptionModal_header {
    font-weight: bold;
    font-size: 24px;
    margin: 0; /* Remove default margin for better spacing */
    padding-bottom: 10px; /* Add spacing between headers and content */
  }
  
  .SubscriptionModal_image {
    text-align: center;
    margin-top: 20px; /* Add margin between elements */
  }
  
  .subscribe-button_main-text {
    font-size: 18px;
    padding-top:5px;
  }
  .subscribe-button_price-text {
    font-size: 18px;
    padding-top: 6px;
    padding-bottom: 5px;
  }

  .subscribe-button_below-text {
    text-align: center;
    padding-bottom: 20px;
    padding-top:0;
    margin-top:-10px;
    font-size: 13px;
    color: #666666;
}

.deal-container{
  background-color: #B9E1E1;
  height: 64px;
  width: 100%;
  font-size: 14px;
  padding: 13px 15px;
  text-align: left;
  border-radius: 10px;
  margin-top: 20px;
  .deal-title{
    padding:0;margin:0;
    padding-bottom:5px;
    font-weight: 800;
  }
  .deal-description{
    padding:0;margin:0;
    color: #287878;
    font-size: 13px;

  }
}
.ButtonsFooter {
  padding-left:20px;
  padding-right:20px;
}

@media screen and (max-width: 768px) {
  .Modal-container {
    --Modal-translateY: 0;
    --Modal_top: 0;
    
  }

  .Modal {
    min-width: auto;
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin:0;
    display:flex;
    flex-direction: column;
    border-radius:0;
    .Modal__form {
      border-radius: 0;
      padding-bottom: 40px;
    }
  }
  .Modal__form--img {
    border-radius:0;
    max-height: 350px;
    object-fit: cover;
    object-position: bottom;
  }
  .ButtonsFooter {
    border-top: 1px solid #e2e2e2;
  }
}
.modal-payment-problem{
  border-radius: 20px;
}
.modal-payment-problem--title {
  padding-top:2px;
  padding-bottom: 20px;
  font-size: 18px;
  color: #8f8f93;
  text-align: left;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 20px;
}
.modal-payment-problem--notification {
  width: 100%;
  color: #58B241;
  background-color:  #58b24138;
  border-radius:  10px;
  margin: 14px 0;
  padding: 20px 12px;
}
.modal-payment-problem--description {
  text-align: left;
}
}
// @media (max-width: 1023px) {

  
//     .SubscriptionModal_left {
//       order: 2; /* Position left element at the bottom */
//       margin-top: 20px; /* Add margin between elements */
//     }
  
//     .SubscriptionModal_right {
//       order: 1; /* Position right element at the top */
//     }

//     h2 {
//         font-size: 22px;
//     }
//     .SubscriptionModal_li__title {
//         font-size: 18px;
//     }
//     .SubscriptionModal_li__p {
//         font-size: 16px;
//     }
//     .SubscriptionModal_image img{
        
//         width: 200px;
//       }
//       .subscribe-button_main-text {
//         font-size: 18px;
//         padding-top:2px;
//       }
//       .subscribe-button_price-text {
//         font-size: 14px;
//         padding-top: 2px;
//         padding-bottom: 5px;
//       }
    
//       .subscribe-button_below-text {
//         text-align: center;
//         padding-bottom: 40px;
//         padding-top:0;
//         font-size: 14px;
//         margin-top:-10px;
//         color: #666666;
//     }
//     .SubscriptionModal_no-bullet li {
//         margin-bottom: 0px;
//       }
//   }