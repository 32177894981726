:root {
    --conflictSimulatorPage-background-color: blue; /* Default value */
}


.ConflictSimulatorNeeds-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: var(--page-background-color);
    gap: 20px;
    padding: 0px;
    justify-content: space-between;
    z-index: 999;
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
    .ConflictSimulatorNeeds__side-a{
        height: 100%;
        font-size: 35px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 500px;
        @media screen and (max-width: 768px) {
            min-width: 0;
        }
        
        .ConflictSimulatorNeed-content {
            padding-inline-end: 20px;
            padding-top: 20px;
            .ConflictSimulatorNeeds__title {
                font-family: var(--mainFont);
                font-weight: 500;
                font-size: 30px;
                margin: 0;
                padding: 0;
                color: white;
            }
            .ConflictSimulatorNeeds__p {
                font-family: var(--mainFont);
                font-weight: 100;
                font-size: 24px;
                margin: 0;
                padding: 0;
                color: white;
            }
            .ConflictSimulatorNeeds__cards {
                display: flex;
                flex-direction: row;
                gap: 22px;
                flex-wrap: wrap;
            }
        }
    }
    .ConflictSimulatorNeeds__side-b{
        height: 100%;
        display: flex;
        @media screen and (max-width: 768px) {
            max-height: 200px;
        }
        .ConflictSimulatorNeeds-img-container {
            height: 100%;
            width: 100%;
            padding: 20px;
            .ConflictSimulatorNeeds-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 20px;
                // border-inline-start: 1px solid rgba(0, 0, 0, 0.2);
                @media screen and (max-width: 768px) {
                    max-height: 200px;
                    border-inline-start: none;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

.assignBackgroundColor {
    // background-color: var(--conflictSimulatorPage-background-color);
    background-color: var(--page-background-color);

}


.ConflictSimulatorNeeds-below {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 170px;
    width: 100%;
    padding: 20px;
    
    .ConflictSimulatorNeeds-buttons {
        align-self: end;
        
    }
}


.ConflictSimulatorNeeds_stepName {
    // margin: 20px 0;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 768px) {
        margin:0;
        padding-bottom: 22px!important;
    }
}