.CatbearsFeedbackBtn-container {
    display: flex;
    // position: fixed;
    bottom: 30px;
    inset-inline-end: 30px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    z-index: 9999;
    transition: transform 100ms ease-in-out;
    // background-color: rgba(255, 255, 255, 0.3);
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    // @media (max-width: 768px) {
    //     bottom: auto;
    //     top: -5px;
    //     inset-inline-end: 70px;
    // }
    img {
        width: 60px;
    }
    .CatbearsFeedbackBtn {
    text-align: center;
    font-weight: 500;
    color: white;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.6);
    transform: rotate(4deg);
    }
    &:hover {
        transform: scale(1.04);
    }
}