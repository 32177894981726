:root {
    --PageWrapperBlock-backgroundColor : white;
 
}

body {
    &[lang="he"] {
        .PageWrapperBlock-container .PageWrapperBlock-content {
        direction: rtl;
      }
    }
  }



.PageWrapperBlock-container {
    width:100%;
    height: 100vh; 
    // min-height: calc(var(--vh, 1vh) * 100); 
    background-color: var(--PageWrapperBlock-backgroundColor);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    z-index: 0;
}

