body {
    &[lang="he"] {
        .CONTENT__list--container--journey {
            .circleProgress-container:nth-child(even){
                margin-right: auto;
                margin-left: 0;
            }
        }
    }
  }

.CONTENT__list--container--journey {
    align-items: flex-start;
    max-width: 450px;
    margin: 0 auto;

    margin-bottom: 100px;
    padding-bottom: 100px;
    .circleProgress-container {
        @media screen and (max-width: 500px){
            margin-bottom: 50px;
        }
    }
    .circleProgress-container:nth-child(even){
        margin-left: auto;
    }
    .circleProgress-container:nth-child(n+2) {
        
        margin-top: -80px;
        // background-color: red;
    }
}

.inviteToFelt-section {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-top: 120px;
    margin-bottom: 50px;
    height: 80px;
    border-radius: 10px;
    background-color: var(--partnerColor);
    transition: transform 200ms ease-in-out;
    &:hover{
        transform: scale(1.02);
        cursor: pointer;
    }
    .inviteToFelt__left {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px;
        height: 80px;
        .inviteToFelt-section__img {
            width: 30px;
            min-width: 30px;
            height: 30px;
            min-height: 30px;
            content: "";
            margin: 0 12px;
            background-image: url(/assets/icons/icon_plus.png);
            background-repeat: no-repeat;
            background-size: contain;
        }
        .inviteToFelt-section__txt {
            font-size: 24px;
            // margin-top: -10px;
            // padding: 0 15px;
            color: white;
            @media screen and (max-width: 500px){
                font-size: 18px;
            }
        }
    }
    .inviteToFelt_right {
        display: flex;
        flex-direction: column;
        // width: 30%;
        min-width: 100px;
        padding : 10px;
        .inviteToFelt_animal__img {
            transform: scaleX(-1);
            margin:0 auto;
            img {
                height: 120px;
              }
        }
        .inviteToFelt_animal__name {
            color: white;
            text-align: center;
        }
    }
}