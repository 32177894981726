.JourneyListNewButton__container {
    width: 100%;
    height: auto;
    background-color: white;
    border: 2px solid var(--mySideColor);
    color: var(--mySideColor);
    margin-bottom: 20px;
    margin-top: 10px;
    border-radius: 10px;
    border-style: dashed;
    padding: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: color 150ms ease-in-out, box-shadow 150ms ease-in-out, background-color 150ms ease-in-out;
    display: flex;
    flex-direction: row;
    
    a { width: 100% }
    .JourneyListNewButton__plusIcon{
        font-size: 46px;
        margin: 0;
        padding: 0 8px;
    }
    .JourneyListNewButton__text{
        font-size: 18px;
        font-weight: 800;
        padding-bottom: 4px
    }
    &:hover {
        background-color: var(--mySideColor);
        color: white;
        cursor: pointer;
        // box-shadow: inset 0 -6px 0 0 rgba(0,0,0,0.05);
        // filter: brightness(1.15);
    }
}