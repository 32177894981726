.fullscreenTakeover-container {
    width: 100%;
    height: 100vh;
    z-index: 99999999999999;
    background-color: rgba(0, 0, 0, 0.789);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 40px;
    position: fixed;
    flex-direction: column;
    position: fixed;
    & > * {
        position: relative;
        z-index: 1;
    }

    &::after {
        content: " ";
        background-color: var(--page-background-color);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0.4;
    }
}